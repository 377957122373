import { observable, toJS } from "mobx";
import { QuickMenuControlScenarioStore } from "../../domain/quickmenu/QuickMenuControlScenarioStore";
import { getAllCookie, getCookie } from "../../../utils/cookie";
import { v4 as uuidv4 } from "uuid";
import { QuickMenuControlUiStore } from "./QuickMenuControlUiStore";
import { QuickMenuControlStore } from "../../domain/quickmenu/QuickMenuControlStore";
import { ServerStore } from "../../domain/ServerStore";
import axios from "axios";
import ScenarioRepository from "../../../data/repository/ScenarioRespository";
import MotionRepository from "../../../data/repository/MotionRespository";
import { QuickMenuControlMotionStore } from "../../domain/quickmenu/QuickMenuControlMotionStore";

const QuickMenuControlScenarioUiStore = observable({
  scenarios: [],
  connectUuid: null,
  scenarioModal: null,

  init() {
    QuickMenuControlScenarioStore.getAllScenario();
  },

  initScenarios(scenarios) {
    this.scenarios = [];

    scenarios.forEach((scenario) => this.addScenario(scenario, true));
    QuickMenuControlScenarioStore.connectScenarioMotion();
    this.scenarios.sort((a, b) => a.order - b.order);
  },

  async addScenario(scenario) {
    const role = await getCookie("userRole");
    const newScenario = {
      id: scenario.id,
      name: scenario.name,
      uuid: scenario.uuid,
      json: scenario.json,
      order: scenario.scenarioOrder,
    };

    if (role === scenario.member.role) {
      // this.scenarios[scenario.scenarioOrder - 1] = newScenario;
      this.scenarios.push(newScenario);
    }
  },

  async newScenario(_scenarioName) {
    await QuickMenuControlUiStore.scenarioMappingToExecution();
    const findNull = QuickMenuControlUiStore.executionList.findIndex(
      (value) => value === null
    );
    console.log("findNull", findNull);
    if (findNull !== 0) {
      const execution =
        findNull !== -1
          ? QuickMenuControlUiStore.executionList.slice(0, findNull)
          : QuickMenuControlUiStore.executionList;
      let jsonText = JSON.stringify(execution);
      // const jsonFile = new Blob([jsonText], { type: "application/json" });

      let uuid = uuidv4();
      let scenariosOrder = this.scenarios.length + 1;

      const executionList = execution.map((item, index) => {
        const curItem = toJS(item);

        return {
          scenario: {
            name: _scenarioName || curItem?.name,
            scenarioOrder: scenariosOrder,
          },
          uuid: curItem.uuid,
          motionOrder: index + 1,
        };
      });
      ScenarioRepository.uploadScenarioWithMotion([...executionList])
        .then((r) => {
          console.log(r);
          QuickMenuControlUiStore.initExecutionList();
          this.connectUuid = uuid;
          this.init();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },

  async setScenario(scenario, newName) {
    const nameCheck = newName.replace(/\s/g, "");
    const authTokenStr = `Bearer ${getCookie("token")}`;
    const { data: scenarioData } = await axios.get(
      `${ServerStore.BASE_URL}/contents/scenario/${scenario.id}`,
      {
        headers: {
          Authorization: authTokenStr,
        },
      }
    );

    if (nameCheck !== "") {
      let uuid = uuidv4();

      const deleteResult = await this.deleteScenario(scenario);
      const executionList = scenarioData?.motions.map((item, index) => {
        const curUuid = QuickMenuControlMotionStore.motions.filter((motion) => {
          return item.id == motion.id;
        })[0]?.uuid;
        return {
          scenario: {
            name: newName,
            scenarioOrder: this.scenarios.length,
          },
          uuid: curUuid,
          motionOrder: item.motionOrder,
        };
      });
      await ScenarioRepository.uploadScenarioWithMotion([...executionList])
        .then(async (r) => {
          QuickMenuControlUiStore.initExecutionList();
          this.connectUuid = uuid;
          this.init();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },

  inItConnectScenarioMotion() {
    QuickMenuControlUiStore.initExecutionList();
    this.connectUuid = null;
  },

  async deleteScenario(scenario) {
    // ScenarioRepository.removeMotionInScenario()
    const { data } = await axios.get(
      `${ServerStore.BASE_URL}/contents/scenario/${scenario.id}`,
      {
        headers: {
          Authorization: `Bearer ${getCookie("token")}`,
        },
      }
    );
    try {
      await Promise.all(
        data.motions.map(async (item) => {
          const result =
            await QuickMenuControlScenarioStore.removeMotionInScenario(
              scenario.id,
              item.id,
              item.motionOrder
            );
        })
      ).then(async (r) => {
        await QuickMenuControlScenarioStore.deleteScenario(scenario.id);
      });
    } catch (error) {
      console.log(error);
    }
  },
});

export { QuickMenuControlScenarioUiStore };
