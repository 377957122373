import { observable } from "mobx";
import { QuickMenuControlMyExpressionStore } from "../../domain/quickmenu/QuickMenuControlMyExpressionStore";
import { QuickMenuControlStore } from "../../domain/quickmenu/QuickMenuControlStore";
import { QuickMenuControlMotionStore } from "../../domain/quickmenu/QuickMenuControlMotionStore";
import { v4 as uuidv4 } from "uuid";
import { getCookie } from "../../../utils/cookie";

const QuickMenuControlMotionUiStore = observable({
    defaultMotionData: null,
    actions: null,
    displays: null,

    defaultFunctionList: [],
    entertainmentList: [],
    walkingList: [],
    customList: [],

    uuid: null,
    name: "",
    tts: "",
    action: null,
    display: null,
    etcActionInput: "",
    etcDisplayInput: "",

    modalVisible: false,

    init() {
        QuickMenuControlMotionStore.getAllMotion();
    },

    setExpressionIdx(index) {
        this.expressionIdx = index;
    },

    initMotion() {
        this.uuid = null;
        this.name = "";
        this.setTTS("");
        this.setAction(Object.keys(QuickMenuControlMotionStore.actions)[0]);
        this.setDisplay(Object.keys(QuickMenuControlMotionStore.displays)[0]);
        this.setEtcActionInput("");
        this.setEtcDisplayInput("");
    },

    initMotions(motions) {
        this.defaultFunctionList = [];
        this.entertainmentList = [];
        this.walkingList = [];
        this.customList = [];

        motions.forEach((motion) => this.addMotion(motion, true));
    },

    addMotionOnCustomList(Motion) {
        this.customList?.push(Motion);
    },

    async addMotion(motion, isNew) {
        const role = await getCookie("userRole");
        const email = await getCookie("useEmail");
        const newMotion = {
            id: motion.id,
            name: motion.name,
            uuid: motion.uuid,
            info: motion.info,
            warning: motion.warning,
            type: motion.type,
            json: motion.json,
        };

        // this.customList.push(newMotion);

        if (motion.type !== "CUSTOM") {
            if (role === "ADMIN" && motion.member.role === "ADMIN") {
                if (isNew) this.addMotionOnCustomList(newMotion);
                else {
                    const index = this.customList.findIndex(
                        (value) => value.uuid === motion.uuid
                    );
                    this.customList[index] = newMotion;
                }
            }
        }
        switch (motion.type) {
            case "BASIC":
                if (isNew) this.defaultFunctionList.push(newMotion);
                else {
                    const index = this.defaultFunctionList.findIndex(
                        (value) => value.uuid === motion.uuid
                    );
                    this.defaultFunctionList[index] = newMotion;
                }
                break;
            case "ENTERTAINING":
                if (isNew) this.entertainmentList.push(newMotion);
                else {
                    const index = this.entertainmentList.findIndex(
                        (value) => value.uuid === motion.uuid
                    );
                    this.entertainmentList[index] = newMotion;
                }
                break;
            case "WALKING":
                if (isNew) this.walkingList.push(newMotion);
                else {
                    const index = this.walkingList.findIndex(
                        (value) => value.uuid === motion.uuid
                    );
                    this.walkingList[index] = newMotion;
                }
                break;
            case "CUSTOM":
                if (email === motion.member?.email) {
                    if (isNew) this.addMotionOnCustomList(newMotion);
                    else {
                        const index = this.customList.findIndex(
                            (value) => value.uuid === motion.uuid
                        );
                        this.customList[index] = newMotion;
                    }
                }
                break;
            default:
                return;
        }
    },

    setName(name) {
        this.name = name;
    },

    setTTS(tts) {
        this.tts = tts;
    },

    setAction(action) {
        const isUserInput =
            Object.keys(this.actions).find((r) => r === action) === undefined;
        if (isUserInput) {
            this.action = "user_input";
            this.etcActionInput = action;
        } else {
            this.action = action;
        }

        const orderList = this.setOrderExpressionList(
            isUserInput ? "user_input" : action,
            this.actions
        );

        this.setActions(orderList);
    },

    setDisplay(display) {
        const isUserInput =
            Object.keys(this.displays).find((r) => r === display) === undefined;
        if (
            Object.keys(this.displays).find((r) => r !== display) === undefined
        ) {
            this.display = "user_input";
            this.etcDisplayInput = display;
        } else {
            this.display = display;
        }
        const orderList = this.setOrderExpressionList(
            isUserInput ? "user_input" : display,
            this.displays
        );
        this.setDisplays(orderList);
    },

    setEtcActionInput(input) {
        this.etcActionInput = input;
    },
    setEtcDisplayInput(input) {
        this.etcDisplayInput = input;
    },

    setOrderExpressionList(expression, list) {
        let orderList = {};
        orderList[expression] = list[expression];

        for (const key of Object.keys(list)) {
            if (expression !== key) orderList[key] = list[key];
        }

        Object.assign(orderList, list);

        return orderList;
    },

    setModalVisible(value) {
        this.modalVisible = value;
    },

    setActions(list) {
        this.actions = list;
    },

    setDisplays(list) {
        this.displays = list;
    },

    setMotion(index) {
        if (index !== undefined) {
            const role = getCookie("userRole");
            // if (role !== 'ADMIN') {
            this.uuid = this.customList?.[index]?.uuid;
            this.name = this.customList?.[index]?.name;
            this.setTTS(this.customList?.[index]?.json?.speech?.TTS);
            this.setAction(this.customList?.[index]?.json.action?.action_name);
            this.setDisplay(
                this.customList?.[index]?.json.display?.display_name
            );
            // } else {
            //     this.uuid = this.defaultMotionData?.[index]?.uuid;
            //     this.name = this.defaultMotionData?.[index]?.name;
            //     this.setTTS(this.defaultMotionData?.[index]?.json?.speech?.TTS);
            //     this.setAction(this.defaultMotionData?.[index]?.json.action?.action_name);
            //     this.setDisplay(this.defaultMotionData?.[index]?.json.display?.display_name);
            // }
            this.setModalVisible(true);
        } else {
            this.initMotion();
        }
    },

    async saveMotion(index) {
        const newJson = await this.createJson();

        let jsonText = JSON.stringify(newJson);
        const jsonFile = new Blob([jsonText], { type: "application/json" });

        let uuid = uuidv4();
        let info = "";
        let warning = "";
        let type = "CUSTOM";
        const role = getCookie("userRole");
        if (this.uuid) uuid = this.uuid;
        if (role === "ADMIN") {
            switch (index) {
                case 0:
                    break;
                case 1:
                    type = "BASIC";
                    info = prompt("설명문구를 입력하세요", "");
                    warning = prompt("경고문구를 입력하세요", "");
                    break;
                case 2:
                    type = "ENTERTAINING";
                    info = prompt("설명문구를 입력하세요", "");
                    warning = prompt("경고문구를 입력하세요", "");
                    break;
                case 3:
                    type = "WALKING";
                    info = prompt("설명문구를 입력하세요", "");
                    warning = prompt("경고문구를 입력하세요", "");
                    break;
                default:
                    break;
            }
            // const result = window.confirm("기본기능?");
            // if (result) {
            // type = "BASIC";
            // info = prompt("설명문구를 입력하세요", "");
            // warning = prompt("경고문구를 입력하세요", "");
            // } else {
            //   const result = window.confirm("엔터테인?");
            //   if (result) {
            //     type = "ENTERTAINING";
            //     info = prompt("설명문구를 입력하세요", "");
            //     warning = prompt("경고문구를 입력하세요", "");
            //   } else {
            //     const result = window.confirm("걷기?");
            //     if (result) {
            //       type = "WALKING";
            //       info = prompt("설명문구를 입력하세요", "");
            //       warning = prompt("경고문구를 입력하세요", "");
            //     }
            //   }
            // }
        }

        const formData = new FormData();

        formData.append("name", this.name);
        formData.append("uuid", uuid);
        formData.append("info", info);
        formData.append("warning", warning);
        formData.append("file", jsonFile);
        formData.append("type", type);

        this.addMotion(
            {
                name: this.name,
                uuid: uuid,
                info: info,
                warning: warning,
                type: type,
                json: newJson,
                member: role,
            },
            false
        );
        QuickMenuControlMotionStore.setMotions(formData);

        this.setModalVisible(false);
        this.setMotion();
    },

    createJson() {
        let defaultJson = JSON.parse(
            JSON.stringify(QuickMenuControlStore.defaultMotionData)
        );

        if (this?.action === "user_input")
            defaultJson.action.action_name = this.etcActionInput;
        else defaultJson.action.action_name = this.action;

        if (this?.display === "user_input")
            defaultJson.display.display_name = this.etcDisplayInput;
        else defaultJson.display.display_name = this.display;

        defaultJson.speech.TTS = this.tts;

        return defaultJson;
    },

    deleteExpression(index) {
        // let uuid;
        // const role = getCookie('userRole');
        // if (role !== 'ADMIN') uuid = this.customList[index].uuid;
        // else uuid = this.defaultFunctionList[index].uuid;
        const uuid = this.customList[index].uuid;

        QuickMenuControlMotionStore.deleteMotion(uuid);
    },
});

export { QuickMenuControlMotionUiStore };
