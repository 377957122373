import { observable } from "mobx";
import { MqttStore } from "../../domain/MqttStore";
import { LikuStore } from "../LikuStore";
import { PlayAndEduMusicStore } from "../../domain/PlayAndEduMusicStore";
import { MetaverseContentsUiStore } from "../metaverse/MetaverseContentsUiStore";
import { PlayerUiStore } from "../player/PlayerUiStore";
import { PlayerLikuUiStore } from "../player/PlayerLikuUiStore";
import { QuickMenuControlUiStore } from "../quickmenu/QuickMenuControlUiStore";
import { PlayerStore } from "../../domain/PlayerStore";

const PlayAndEduMusicUiStore = observable({
    list: null,
    index: 0,
    music: null,
    status: false,
    isShuffle: false,
    type: "ALL",
    // progress: false,
    isMusicPlay: false,
    isSleepModalVisibleMusic: false,

    init() {
        console.log("init");
        MqttStore.connect().then(() => {
            const topic = `liku/${LikuStore.thisLiku?.uuid}/json/result`;
            MqttStore.subscribe(topic);
            // MqttStore.reSubscribeEdu();
        });
    },

    connectCheckAndRetry() {
        if (LikuStore.thisLiku !== null) {
            if (
                MqttStore.heartbeatInterval === null ||
                MqttStore.heartbeatInterval === undefined ||
                !MqttStore.isHeartbeat ||
                MqttStore.heartbeat === 15
            ) {
                this.init();
            }
        }
    },

    clear() {
        MqttStore.close();
    },

    setMetaverseIndex(index) {
        this.index = index;
        // console.log("setMetaverseIndex", this.index);
    },

    getMetaverseIndex() {
        return this.index;
    },

    setList(list) {
        this.list = list;
    },

    setMusic(music) {
        this.music = music;
        if (this.music !== null) {
            console.log("setMusic : ", this.list.indexOf(this.music));
        }
    },

    getIndex() {
        /*
        증상 및 원인: 찾고자 하는 문자열이 null일 때
        리스트의 indexOf 함수에서 값을 읽을 수 없다는 에러가 발생한다.
        해결방법: 찾고자 하는 문자열이 null이 아닐 때 찾고자 하는 문자열이
        처음으로 나타내는 위치를 반환할 수 있도록 조건문을 준다.
         */
        if (this.music !== null) {
            return this.list.indexOf(this.music);
        }
    },

    setStatus(value) {
        console.log("setStatus", value);
        this.status = value;
    },

    // setShuffle(value) {
    //     this.isShuffle = value;
    // },

    setNextMusic(value) {
        let music;

        if (this.isShuffle) music = this.getShuffleMusic();
        else music = this.getNextMusic(value);

        this.startMusic(music);
    },

    getNextMusic(value) {
        let idx = 0;
        //console.log("**********");
        if (this.music === undefined) {
            idx = 0;
        } else {
            idx = this.list.indexOf(this.music);

            if (value === undefined || value) idx++;
            else idx--;

            if (idx > this.list.length - 1) idx = 0;
            else if (idx < 0) idx = this.list.length - 1;
        }

        return this.list[idx];
    },

    getShuffleMusic() {
        let idx = Math.floor(Math.random() * this.list.length);
        return this.list[idx];
    },

    async stopMusic() {
        console.log(
            "====================================stopMusic",
            this.status
        );
        if (this.status) {
            this.setStatus(false);
            this.setIsMusicPlay(false);

            const topic = `liku/${LikuStore.thisLiku?.uuid}/user/stop`;
            const message = "stop";

            console.log("stopMusic topic", topic);
            console.log("stopMusic message", message);

            await MqttStore.publish(topic, message);
        }
    },

    setIsMusicPlay(value) {
        this.isMusicPlay = value;
    },

    async startMusic(music) {
        //this.isMusicPlay = true;
        console.log("startmusic music", music);
        console.log("isMusicPlay", this.isMusicPlay);

        if (QuickMenuControlUiStore.status) {
            alert("리쿠가 동작을 하고 있어요. 멈춘 후 다시 시도해주세요.");
        } else if (
            music !== undefined &&
            music !== null &&
            LikuStore.state === "active"
        ) {
            this.setMusic(music);
            this.stopMusic().then(() => {
                this.setStatus(true);
                console.log(this.music);
                const music = this.music?.data;

                const topic = `liku/${LikuStore.thisLiku.uuid}/json/list`;
                const message = { data: [music] };

                setTimeout(() => {
                    //MqttStore.publish(topic, JSON.stringify(message));
                    MqttStore.publish(topic, message);
                    // this.isMusicPlay = true;
                    this.setIsMusicPlay(true);
                }, 1000);
            });
        } else if (LikuStore.state !== "active") {
            //this.isSleepModalVisibleMusic = true ;
            alert("리쿠가 잠들어 있어요. 리쿠를 깨운 후 다시 시도해주세요.");
        } else {
            alert("노래를 선택해주세요.");
        }
    },

    async shuffleMusic(value) {
        this.isShuffle ^= true;
        console.log("PlayAndEduMusicUiStore.isShuffle", this.isShuffle);

        if (this.isShuffle) {
            let music = this.getShuffleMusic();
            let idx = this.list.indexOf(music);
            console.log("shuffleMusic", idx);
            await this.startMusic(music);
        }
    },

    async setType(type) {
        this.type = type;
        console.log('asdfasdfasdf', type);
        const filterList = await PlayAndEduMusicStore.getList(type);
        this.setList(filterList);
    },

    // setProgress(value) {
    //     this.progress = value;
    // }

    musicDone() {
        console.log("musicJsonResultDone");
        PlayAndEduMusicUiStore.stopMusic().then(() => {
            if (MetaverseContentsUiStore.isMusicPlay === true) {
                MetaverseContentsUiStore.isMusicPlay = false;
            }
            if (PlayerStore.contents !== null) {
                setTimeout(() => {
                    if (PlayAndEduMusicUiStore.getMetaverseIndex() !== 0) {
                        // 메타버스인데 노래를 하고 안하고있으면 0
                        PlayerUiStore.init(
                            PlayAndEduMusicUiStore.getMetaverseIndex()
                        ).then(() => {
                            if (PlayerUiStore.isMetaverse()) {
                                PlayerLikuUiStore.metaverseInit();
                                MetaverseContentsUiStore.requestLikuMemory();
                                MetaverseContentsUiStore.init();
                                PlayAndEduMusicUiStore.setMetaverseIndex(0);
                            }
                        });
                    } else {
                        PlayerLikuUiStore.getLikuUuid();
                    }
                }, 1000);
            }
        });
    },
});

export { PlayAndEduMusicUiStore };
