import { observable, runInAction } from "mobx";

const FilterUiStore = observable({
  searchStore: '',
  searchTerm: '',
  filterBox : '',

  filterTotdalData: [],

  setFilterTotalData(data) {
    this.filterTotdalData = data;
  },

  interval: false,

  sideClick: false,
  kidManage: false,
  
  setKidManage() {
    this.kidManage = !this.kidManage
  },

  setSideTrueClick() {
    this.sideClick = true
  },

  setSideFalseClick() {
    this.sideClick = false;
  },

  list : [
    {
      type: "출석",
      color: "#40ccc2",
    },
    {
      type: "결석",
      color: "#ffc400",
    },
    {
      type: "병가",
      color: "#fe834b",
    },
    {
      type: "사고",
      color: "#6040cc",
    },
    {
      type: "입소",
      color: "#65B144",
    },
    {
      type: "퇴소",
      color: "#f500ff",
    },
  ],


  setSearchTerm(data) {
    this.searchTerm = data;
    this.filterBox = data;
    // console.log('searchTerm', this.searchTerm);
  },

  async setFilterBox(data) {
    if (
      this.filterBox !== null &&
      this.filterBox !== undefined 
    ) {
      this.interval = false;
      runInAction(() => {
        this.filterBox = null;
        this.searchTerm = '';
      })
    } else {
      // this.filterBox = data;
      this.setSearchTerm(data);


      // this.setSearchStore(data);
    }
    // console.log('FilterBox', this.filterBox)
  },

  setSearchStore(data) {
    if(
      data === '출석' ||
      data === '결석' || 
      data === '병가' ||
      data === '사고' ||
      data === '입소' ||
      data === '퇴소'
      ) {
      // this.searchStore = '';
    } else {
      this.searchStore = data;

    }
  }

});

export {FilterUiStore};
