import { PlayerUiStore } from "../store/ui/player/PlayerUiStore";

import { PlayerBackgroundUiStore } from "../store/ui/player/PlayerBackgroundUiStore";

import PlayImg from "../resource/image/player/control/play.png";
// import StopImg from "../resource/image/player/control/stop.png";
import PauseImg from "../resource/image/player/control/pause.png";
import ResetImg from "../resource/image/player/control/reset.png";
import ModeImg from "../resource/image/player/control/mode.png";
import rewindImg from "../resource/image/player/control_old/rewind.png";
import fastplayImg from "../resource/image/player/control_old/fastplay.png";

import PlayModeImg from "../resource/image/player/control/mode/play-mode.svg";
import StopModeImg from "../resource/image/player/control/mode/stop-mode.svg";
import ResetModeImg from "../resource/image/player/control/mode/refresh-mode.svg";
import ModeModeImg from "../resource/image/player/control/mode/mode-mode.svg";

import DefaultVideo from "../resource/video/Default_Video.mp4";
// import DefaultAudio from '../resource/audio/empty_p1.mp3';

import square from "../resource/image/player/interactive/square.svg";
import triangle from "../resource/image/player/interactive/triangle.svg";
import star from "../resource/image/player/interactive/star.svg";
import circle from "../resource/image/player/interactive/circle.svg";

const PlayerConfig = () => {
  PlayerUiStore.controlButtons = [
    {
      image: rewindImg,
      // image: PlayImg,
      id: "rewind",
    },
    {
      image: PlayImg,
      mode_image: PlayModeImg,
      id: "play",
    },
    {
      image: PauseImg,
      mode_image: StopModeImg,
      id: "stop",
    },
    {
      image: fastplayImg,
      // image: PlayImg,
      id: "fastplay",
    },
    {
      image: ResetImg,
      mode_image: ResetModeImg,
      id: "reset",
    },
    {
      image: ModeImg,
      mode_image: ModeModeImg,
      id: "mode",
    },
    // // {
    //     // image: reset,
    //     image: PlayImg,
    //     id: 'reset',
    // },
    // {
    //     image: fullscreen,
    //     id: 'fullscreen'
    // }
  ];

  PlayerBackgroundUiStore.defaultBackground = {
    image: {
      data: null,
      url: DefaultVideo,
    },
    music: {
      data: null,
      url: null,
    },
  };
};

export const basicShape = [
  { name: "Square", src: square },
  { name: "Circle", src: circle },
  { name: "Star", src: star },
  { name: "Triangle", src: triangle },
];

export default PlayerConfig;
