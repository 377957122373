import { PlayAndEduMusicStore } from '../store/domain/PlayAndEduMusicStore';

import SongLikuJson from '../resource/json/kids/song_songLiku.json';
import SongSharkJson from '../resource/json/kids/song_shark.json';
import SongCrocodileJson from '../resource/json/kids/song_crocodile.json';
import SongGogoJson from '../resource/json/kids/song_gogo.json';
import SongGilJson from '../resource/json/kids/song_gil.json';
import SongThreeBearJson from '../resource/json/kids/song_threebear.json';
import SongJustleaveJson from '../resource/json/kids/song_justleave.json';
import SongStopmomentJson from '../resource/json/kids/song_stopmoment.json';
import SongStopitJson from '../resource/json/kids/song_stopit.json';
import SongMimicJson from '../resource/json/kids/song_mimic.json';
import SongHeadShoulderJson from '../resource/json/kids/song_headshoulder.json';
import SongTomatoJson from '../resource/json/kids/song_tomato.json';
import SongCandyflossJson from '../resource/json/kids/song_candyfloss.json';
import SongBabyBearJson from '../resource/json/kids/song_babybear.json';
import SongFrogJson from '../resource/json/kids/song_frog.json';
import SongMilkJson from '../resource/json/kids/song_milksong.json';
import SongIngingJson from '../resource/json/kids/song_inging.json';
import SongZooJson from '../resource/json/kids/song_zoo.json';
import SongFunnyJson from '../resource/json/kids/song_funny.json';
import SongTongtongJson from '../resource/json/kids/song_tongtong.json';
import SongGreatmanJson from '../resource/json/kids/song_greatman.json';
import SongScarecrowJson from '../resource/json/kids/song_scarecrow.json';
import SongBdayJson from '../resource/json/kids/song_bday.json';

import SongAbcJson from '../resource/json/eng/song_abc.json';
import SongFiremanJson from '../resource/json/eng/song_fireman.json';
import SongHushJson from '../resource/json/eng/song_hush.json';
import SongYoungstarJson from '../resource/json/eng/song_youngstar.json';
import SongGoodmorningJson from '../resource/json/eng/song_goodmorning.json';
import SongEngTeaPotJson from '../resource/json/eng/song_engTeaPot.json';
import SongEngLouJson from '../resource/json/eng/song_engLou.json';
import SongEngComePlayJson from '../resource/json/eng/song_engComePlay.json';
import SongEngAppleTreeJson from '../resource/json/eng/song_engAppleTree.json';
import SongEngBaloonJson from '../resource/json/eng/song_engBaloon.json';
import SongEngLetsStopJson from '../resource/json/eng/song_engLetsStop.json';
import SongEngCompagnieJson from '../resource/json/eng/song_engCompagnie.json';
import SongEngLollyJson from '../resource/json/eng/song_engLolly.json';
import SongEngClapJson from '../resource/json/eng/song_engClap.json';
import SongEngBostonJson from '../resource/json/eng/song_engBoston.json';
import SongEngRollBallJson from '../resource/json/eng/song_engRollBall.json';
import SongEngSailorJson from '../resource/json/eng/song_engSailor.json';
import SongEngHandkerchiefJson from '../resource/json/eng/song_engHandkerchief.json';
import SongEngLondonJson from '../resource/json/eng/song_engLondon.json';
import SongEngHumptyJson from '../resource/json/eng/song_engHumpty.json';
import SongEngWhatsThisJson from '../resource/json/eng/song_engWhatsThis.json';
import SongEngWhatTimeJson from '../resource/json/eng/song_engWhatTime.json';
import SongEngBigLittleJson from '../resource/json/eng/song_engBigLittle.json';
import SongEngWeatherJson from '../resource/json/eng/song_engWeather.json';
import SongEngBabyClothesJson from '../resource/json/eng/song_engBabyClothes.json';
import SongEngPeasBeansJson from '../resource/json/eng/song_engPeasBeans.json';
import SongEngDaysofWeekJson from '../resource/json/eng/song_engDaysofWeek.json';
import SongEngMonthsJson from '../resource/json/eng/song_engMonths.json';
import SongEngSallySunJson from '../resource/json/eng/song_engSallySun.json';
import SongEngNulberryBushJson from '../resource/json/eng/song_engNulberryBush.json';
import SongEngFlowerBirdJson from '../resource/json/eng/song_engFlowerBird.json';
import SongEngRoadJson from '../resource/json/eng/song_engRoad.json';
import SongEngApplesPearsJson from '../resource/json/eng/song_engApplesPears.json';
import SongEngLittleStarJson from '../resource/json/eng/song_engLittleStar.json';
import SongEngStrawberryJamJson from '../resource/json/eng/song_engStrawberryJam.json';
import SongEngMaryJson from '../resource/json/eng/song_engMary.json';
import SongEngSkyJson from '../resource/json/eng/song_engSky.json';
import SongEngWinterJson from '../resource/json/eng/song_engWinter.json';
import SongEngLambJson from '../resource/json/eng/song_engLamb.json';
import SongEngHappyJson from '../resource/json/eng/song_engHappy.json';
import SongEngAngryJson from '../resource/json/eng/song_engAngry.json';
import SongEngVillageJson from '../resource/json/eng/song_engVillage.json';
import SongEngTenIndiansJson from '../resource/json/eng/song_engTenIndians.json';
import SongEngHandJson from '../resource/json/eng/song_engHand.json';
import SongEngWearingJson from '../resource/json/eng/song_engWearing.json';
import SongEngBoatJson from '../resource/json/eng/song_engBoat.json';
import SongEngWherJson from '../resource/json/eng/song_engWhere.json';
import SongEngFingerBandJson from '../resource/json/eng/song_engFingerBand.json';
import SongEngHeadSouldersJson from '../resource/json/eng/song_engHeadSoulders.json';
import SongEngElephantJson from '../resource/json/eng/song_engElephant.json';
import SongEngTogetherJson from '../resource/json/eng/song_engTogether.json';
import SongEngBingoJson from '../resource/json/eng/song_engBingo.json';
import SongEngBusJson from '../resource/json/eng/song_engBus.json';
import SongEngRainJson from '../resource/json/eng/song_engRain.json';
import SongEngYellowJson from '../resource/json/eng/song_engYellow.json';

// import SongJejuDolHarbangJson from "../resource/json/kids/song_jejuDolHarbang.json";
// import SongJejuLaughJson from "../resource/json/kids/song_jejuLaugh.json";
// import SongJejuDonmooJson from "../resource/json/kids/song_jejuDonmoo.json";
// import SongJejuDialecJson from "../resource/json/kids/song_jejuDialect.json";
import SongAutumnRoadJson from '../resource/json/kids/song_autumnroad.json';
import SongBabySnowmanJson from '../resource/json/kids/song_babysnowman.json';
import SongLeafboatJson from '../resource/json/kids/song_leafboat.json';
import SongFasterslowerJson from '../resource/json/kids/song_fasterslower.json';
import SongDoggaebeeJson from '../resource/json/kids/song_doggaebee.json';
import SongSneakJson from '../resource/json/kids/song_sneak.json';
import SongIntotheForestJson from '../resource/json/kids/song_intotheforest.json';
import SongWalkinginForestJson from '../resource/json/kids/song_walkinginforest.json';
import SongMorninginForestJson from '../resource/json/kids/song_morninginforest.json';
import SongFunCampJson from '../resource/json/kids/song_funcamp.json';
import SongBabySquirrelJson from '../resource/json/kids/song_babysquirrel.json';
import SongBabyGoatJson from '../resource/json/kids/song_babygoat.json';
import SongAltogetherJson from '../resource/json/kids/song_altogether.json';
import SongColdandHungryJson from '../resource/json/kids/song_coldandhungry.json';
import SongIoveyouJson from '../resource/json/kids/song_loveyou.json';
import SongGlobalFamilyJson from '../resource/json/kids/song_globalfamily.json';
import SongFoodFestivalJson from '../resource/json/kids/song_foodfestival.json';
import SongIamOkayJson from '../resource/json/kids/song_iamokay.json';
import SongSantaJson from '../resource/json/kids/song_santa.json';
import SongAutumnJson from '../resource/json/kids/song_1autumn.json';
import SongAntJson from '../resource/json/kids/song_1ant.json';
import SongSpiderJson from '../resource/json/kids/song_1spider.json';
import SongRainJson from '../resource/json/kids/song_1rain.json';
import SongHouseJson from '../resource/json/kids/song_1house.json';
import SongFireCarJson from '../resource/json/kids/song_1firecar.json';
import SongBeanJson from '../resource/json/kids/song_1bean.json';
import SongLeavesJson from '../resource/json/kids/song_1leaves.json';
import SongBeetleJson from '../resource/json/kids/song_1beetle.json';
import SongWaterandStoneJson from '../resource/json/kids/song_1waterandstone.json';
import SongSnowmanJson from '../resource/json/kids/song_1snowman.json';
import SongOrganizeJson from '../resource/json/kids/song_1organize.json';
import SongHospitalJson from '../resource/json/kids/song_1hospital.json';
import SongGlobeJson from '../resource/json/kids/song_1globe.json';
import SongLovingJson from '../resource/json/kids/song_1loving.json';
import SongGoblinJson from '../resource/json/kids/song_1goblin.json';
import SongBuildingJson from '../resource/json/kids/song_1building.json';
import SongMarketPlayJson from '../resource/json/kids/song_1marketplay.json';
import SongMarketPartyJson from '../resource/json/kids/song_1marketparty.json';
import SongIceJson from '../resource/json/kids/song_1ice.json';
import SongCosmosJson from '../resource/json/kids/song_1cosmos.json';
import SongJalJalJalJson from '../resource/json/kids/song_1jaljaljal.json';
import SongClassJson from '../resource/json/kids/song_1class.json';

import SongAnDongYukEhSeoJson from '../resource/json/trot/song_andongyukehseo.json';
import SongGalMuRiJson from '../resource/json/trot/song_galmuri.json';
import SongMakguliHanJanJson from '../resource/json/trot/song_makgulihanjan.json';
import SongSarangAhJson from '../resource/json/trot/song_sarangah.json';
import SongTesHyungJson from '../resource/json/trot/song_teshyung.json';
import SongBulHyoJaneonOopNiDaJson from '../resource/json/trot/song_bulhyojaneonoopnida.json';
import SongDongBanJaJson from '../resource/json/trot/song_dongbanja.json';
import SongEbyeoluiBusanJeonggeojangJson from '../resource/json/trot/song_ebyeoluibusanjeonggeojang.json';
import SongGalDaeWaSoonJeongJson from '../resource/json/trot/song_galdaewasoonjeong.json';
import SongTteugeounAnnyeongJson from '../resource/json/trot/song_tteugeounannyeong.json';
import SongBoritGoGaeJson from '../resource/json/trot/song_boritgogae.json';
import SongEngDooJson from '../resource/json/trot/song_engdoo.json';
import SongHongSeaJson from '../resource/json/trot/song_hongsea.json';
import SonglpyeonDanshimJson from '../resource/json/trot/song_ilpyeondanshim.json';
import SongNeaNaigaEottaeseoJson from '../resource/json/trot/song_neanaigaeottaeseo.json';
import SongOhDonglipJson from '../resource/json/trot/song_ohdonglip.json';
import SongSalangEIleongeonGayoJson from '../resource/json/trot/song_salangeileongeongayo.json';
import SongYoungDongBruceJson from '../resource/json/trot/song_youngdongbruce.json';
import SongApartJson from '../resource/json/trot/song_apart.json';
import SongMyeotMeterApedaDugoJson from '../resource/json/trot/song_myeotmeterapedadugo.json';

import SongSeollalJson from '../resource/json/kor/song_tradSeollal.json';
import SongGanggangsullaeJson from '../resource/json/kor/song_tradGanggangsullae.json';
import SongKwaejinaJson from '../resource/json/kor/song_tradKwaejina.json';
import SongArirang2Json from '../resource/json/kor/song_tradArirang2.json';
import SongGunbam2Json from '../resource/json/kor/song_tradGunbam2.json';
import SongDaemunJson from '../resource/json/kor/song_tradDaemun.json';
import SongYeouyaJson from '../resource/json/kor/song_tradYeouya.json';
import SongIgeoriJson from '../resource/json/kor/song_tradIgeori.json';
import SongNilliliyaJson from '../resource/json/kor/song_tradNilliliya.json';
import SongPungnyeongaJson from '../resource/json/kor/song_tradPungnyeonga.json';

import SongBrownJson from '../resource/json/trot/song_brown.json';
import SongTeahouseJson from '../resource/json/trot/song_teahouse.json';
import SongFlowerwindJson from '../resource/json/trot/song_flowerwind.json';
import SongManlifeJson from '../resource/json/trot/song_manlife.json';
import SongSouthtrainJson from '../resource/json/trot/song_southtrain.json';
import SongNestJson from '../resource/json/trot/song_nest.json';
import SongLoveropeJson from '../resource/json/trot/song_loverope.json';
import SongKissJson from '../resource/json/trot/song_kiss.json';
import SongTackleJson from '../resource/json/trot/song_tackle.json';
import SongHaeundaeJson from '../resource/json/trot/song_haeundae.json';

import SongSooniJson from '../resource/json/trot/song_sooni.json';
import SongGandaeyoJson from '../resource/json/trot/song_gandaeyo.json';
import SongGunbaeJson from '../resource/json/trot/song_gunbae.json';
import SongThankyouJson from '../resource/json/trot/song_thankyou.json';
import SongGohyangJson from '../resource/json/trot/song_gohyang.json';
import SongLikestarJson from '../resource/json/trot/song_likestar.json';
import SongMujungJson from '../resource/json/trot/song_mujung.json';
import SongMiscoJson from '../resource/json/trot/song_misco.json';
import SongGabchoJson from '../resource/json/trot/song_gabcho.json';
import SongReallygoodJson from '../resource/json/trot/song_reallygood.json';


import SongAienJson from '../resource/json/aien/song_Aien.json';
import SongLikuHBDJson from '../resource/json/kids/song_LikuHBD.json';

import songLikuImg from '../resource/image/music/song_liku2.png';

import { runInAction } from 'mobx';

import songCrocodileImg from '../resource/image/music/song_crocodile.png';
import songJustleaveImg from '../resource/image/music/song_justleave.png';
import songStopmomentImg from '../resource/image/music/song_stopmoment.png';
import songFrogImg from '../resource/image/music/song_frog.png';
import songZooImg from '../resource/image/music/song_zoo.png';
import songBdayImg from '../resource/image/music/song_bday.png';

const MusicConfig = () => {
    const songLiku = {
        name: "리쿠 송",
        type: 'KIDS_SONG',
        data: SongLikuJson,
        image: songLikuImg,
    };
    const songLikuHBD ={
        name: "리쿠 생일 축하 노래",
        type: "KIDS_SONG",
        data: SongLikuHBDJson,
    }
    const songShark = {
        name: "상어 가족",
        type: 'KIDS_SONG',
        data: SongSharkJson,
    };
    const songCrocodile = {
        name: "악어 떼",
        type: 'KIDS_SONG',
        data: SongCrocodileJson,
        image: songCrocodileImg,
    };
    const songGogo = {
        name: "간다간다",
        type: 'KIDS_SONG',
        data: SongGogoJson,
    };
    const songGil = {
        name: "건너가는 길",
        type: 'KIDS_SONG',
        data: SongGilJson,
    };
    const songThreeBear = {
        name: "곰 세 마리",
        type: 'KIDS_SONG',
        data: SongThreeBearJson,
    };
    const songJustleave = {
        name: "그냥 두고 나갔더니",
        type: 'KIDS_SONG',
        data: SongJustleaveJson,
        image: songJustleaveImg,
    };
    const songStopmoment = {
        name: "그대로 멈춰라",
        type: 'KIDS_SONG',
        data: SongStopmomentJson,
        image: songStopmomentImg
    };
    const songStopit = {
        name: "그러면 안돼",
        type: 'KIDS_SONG',
        data: SongStopitJson,
    };
    const songMimic = {
        name: "나처럼 해봐요",
        type: 'KIDS_SONG',
        data: SongMimicJson,
    };
    const songHeadShoulder = {
        name: "머리 어깨 무릎 발",
        type: 'KIDS_SONG',
        data: SongHeadShoulderJson,
    };
    const songTomato = {
        name: "멋쟁이 토마토",
        type: 'KIDS_SONG',
        data: SongTomatoJson,
    };
    const songCandyfloss = {
        name: "솜사탕",
        type: 'KIDS_SONG',
        data: SongCandyflossJson,
    };
    const songBabyBear = {
        name: "예쁜 아기 곰",
        type: 'KIDS_SONG',
        data: SongBabyBearJson,
    };
    const songFrog = {
        name: "올챙이와 개구리",
        type: 'KIDS_SONG',
        data: SongFrogJson,
        image: songFrogImg,
    };
    const songMilk = {
        name: "우유송",
        type: 'KIDS_SONG',
        data: SongMilkJson,
    };
    const songInging = {
        name: "잉잉잉",
        type: 'KIDS_SONG',
        data: SongIngingJson,
    };
    const songZoo = {
        name: "작은 동물원",
        type: 'KIDS_SONG',
        data: SongZooJson,
        image: songZooImg,
    };
    const songFunny = {
        name: "참 재미있었지",
        type: 'KIDS_SONG',
        data: SongFunnyJson,
    };
    const songTongtong = {
        name: "통통통",
        type: 'KIDS_SONG',
        data: SongTongtongJson,
    };
    const songGreatman = {
        name: "한국을 빛낸 100명의 위인들",
        type: 'KIDS_SONG',
        data: SongGreatmanJson,
    };
    const songScarecrow = {
        name: "허수아비 아저씨",
        type: 'KIDS_SONG',
        data: SongScarecrowJson,
    };
    const songBday = {
        name: "생일 축하 노래",
        type: 'KIDS_SONG',
        data: SongBdayJson,
        image: songBdayImg,
    };
    const SongAutumnRoad = {
        name: "가을 길",
        type: 'KIDS_SONG',
        data: SongAutumnRoadJson,
    };
    const SongBabySnowman = {
        name: "꼬마 눈 사람",
        type: 'KIDS_SONG',
        data: SongBabySnowmanJson,
    };
    const SongLeafboat = {
        name: "나뭇잎 배",
        type: 'KIDS_SONG',
        data: SongLeafboatJson,
    };
    const SongFasterslower = {
        name: "더 빠른 것 더 느린 것",
        type: 'KIDS_SONG',
        data: SongFasterslowerJson,
    };
    const SongDoggaebee = {
        name: "도깨비 나라",
        type: 'KIDS_SONG',
        data: SongDoggaebeeJson,
    };
    const SongSneak = {
        name: "몰래 들어와서는",
        type: 'KIDS_SONG',
        data: SongSneakJson,
    };
    const SongIntotheForest = {
        name: "밀림으로",
        type: 'KIDS_SONG',
        data: SongIntotheForestJson,
    };
    const SongWalkinginForest = {
        name: "숲 속을 걸어요",
        type: 'KIDS_SONG',
        data: SongWalkinginForestJson,
    };
    const SongMorninginForest = {
        name: "숲 속의 아침",
        type: 'KIDS_SONG',
        data: SongMorninginForestJson,
    };
    const SongFunCamp = {
        name: "신나는 캠프",
        type: 'KIDS_SONG',
        data: SongFunCampJson,
    };
    const SongBabySquirrel = {
        name: "아기 다람쥐 또미",
        type: 'KIDS_SONG',
        data: SongBabySquirrelJson,
    };
    const SongBabyGoat = {
        name: "아기 염소",
        type: 'KIDS_SONG',
        data: SongBabyGoatJson,
    };
    const SongAltogether = {
        name: "우리 모두 다 같이",
        type: 'KIDS_SONG',
        data: SongAltogetherJson,
    };
    const SongColdandHungry = {
        name: "춥지 않을까 배고프지 않을까",
        type: 'KIDS_SONG',
        data: SongColdandHungryJson,
    };
    const SongIoveyou = {
        name: "사랑해요",
        type: 'KIDS_SONG',
        data: SongIoveyouJson,
    };
    const SongGlobalFamily = {
        name: "지구촌 한 가족(누리과정4세)",
        type: 'KIDS_SONG',
        data: SongGlobalFamilyJson,
    };
    const SongFoodFestival = {
        name: "세계 음식 잔치(누리과정4세)",
        type: 'KIDS_SONG',
        data: SongFoodFestivalJson,
    };
    const SongIamOkay = {
        name: "괜찮아요 (`뽀로로와 노래해요` 삽입곡)",
        type: 'KIDS_SONG',
        data: SongIamOkayJson,
    };
    const SongSanta = {
        name: "산타할아버지",
        type: 'KIDS_SONG',
        data: SongSantaJson,
    };
    const SongAutumn = {
        name: "가을은",
        type: 'KIDS_SONG',
        data: SongAutumnJson,
    };
    const SongAnt = {
        name: "개미심부름",
        type: 'KIDS_SONG',
        data: SongAntJson,
    };
    const SongSpider = {
        name: "거미가 줄을 타고 올라갑니다",
        type: 'KIDS_SONG',
        data: SongSpiderJson,
    };
    const SongRain = {
        name: "구슬비",
        type: 'KIDS_SONG',
        data: SongRainJson,
    };
    const SongHouse = {
        name: "그런 집 보았니",
        type: 'KIDS_SONG',
        data: SongHouseJson,
    };
    const SongFireCar = {
        name: "꼬마 불 자동차",
        type: 'KIDS_SONG',
        data: SongFireCarJson,
    };
    const SongBean = {
        name: "나는 콩",
        type: 'KIDS_SONG',
        data: SongBeanJson,
    };
    const SongLeaves = {
        name: "낙엽을 밟으며",
        type: 'KIDS_SONG',
        data: SongLeavesJson,
    };
    const SongBeetle = {
        name: "데굴데굴 쇠똥구리",
        type: 'KIDS_SONG',
        data: SongBeetleJson,
    };
    const SongWaterandStone = {
        name: "돌과 물",
        type: 'KIDS_SONG',
        data: SongWaterandStoneJson,
    };
    const SongSnowman = {
        name: "멋진 눈사람",
        type: 'KIDS_SONG',
        data: SongSnowmanJson,
    };
    const SongOrganize = {
        name: "모두 제자리",
        type: 'KIDS_SONG',
        data: SongOrganizeJson,
    };
    const SongHospital = {
        name: "병원차와 소방차",
        type: 'KIDS_SONG',
        data: SongHospitalJson,
    };
    const SongGlobe = {
        name: "빙글빙글 지구본",
        type: 'KIDS_SONG',
        data: SongGlobeJson,
    };
    const SongLoving = {
        name: "사랑해요",
        type: 'KIDS_SONG',
        data: SongLovingJson,
    };
    const SongGoblin = {
        name: "산도깨비",
        type: 'KIDS_SONG',
        data: SongGoblinJson,
    };
    const SongBuilding = {
        name: "세계의 여러 가지 집",
        type: 'KIDS_SONG',
        data: SongBuildingJson,
    };
    const SongMarketPlay = {
        name: "시장 놀이",
        type: 'KIDS_SONG',
        data: SongMarketPlayJson,
    };
    const SongMarketParty = {
        name: "시장 잔치",
        type: 'KIDS_SONG',
        data: SongMarketPartyJson,
    };
    const SongIce = {
        name: "얼음 과자",
        type: 'KIDS_SONG',
        data: SongIceJson,
    };
    const SongCosmos = {
        name: "코스모스",
        type: 'KIDS_SONG',
        data: SongCosmosJson,
    };
    const SongJalJalJal = {
        name: "잘잘잘",
        type: 'KIDS_SONG',
        data: SongJalJalJalJson,
    };
    const SongClass = {
        name: "형님반에 간다네",
        type: 'KIDS_SONG',
        data: SongClassJson,
    };
    const songAbc = {
        name: "ABC_song",
        type: 'ENG_KIDS_SONG',
        data: SongAbcJson,
    };
    const songFireman = {
        name: "소방관",
        type: 'ENG_KIDS_SONG',
        data: SongFiremanJson,
    };
    const songHush = {
        name: "자장자장",
        type: 'ENG_KIDS_SONG',
        data: SongHushJson,
    };
    const songYoungstar = {
        name: "여보게",
        type: 'ENG_KIDS_SONG',
        data: SongYoungstarJson,
    };
    const songGoodmorning = {
        name: "아침인사",
        type: 'ENG_KIDS_SONG',
        data: SongGoodmorningJson,
    };
    const SongEngTeaPot = {
        name: "I am little tea pot",
        type: 'ENG_KIDS_SONG',
        data: SongEngTeaPotJson,
    };
    const SongEngLou = {
        name: "Skip to my Lou",
        type: 'ENG_KIDS_SONG',
        data: SongEngLouJson,
    };
    const SongEngComePlay = {
        name: "Come and play with me",
        type: 'ENG_KIDS_SONG',
        data: SongEngComePlayJson,
    };
    const SongEngAppleTree = {
        name: "Can you climb and apple tree",
        type: 'ENG_KIDS_SONG',
        data: SongEngAppleTreeJson,
    };
    const SongEngBaloon = {
        name: "My baloon",
        type: 'ENG_KIDS_SONG',
        data: SongEngBaloonJson,
    };
    const SongEngLetsStop = {
        name: "Now let's stop",
        type: 'ENG_KIDS_SONG',
        data: SongEngLetsStopJson,
    };
    const SongEngCompagnie = {
        name: "Viva la compagnie",
        type: 'ENG_KIDS_SONG',
        data: SongEngCompagnieJson,
    };
    const SongEngLolly = {
        name: "Hey Lolly Lolly",
        type: 'ENG_KIDS_SONG',
        data: SongEngLollyJson,
    };
    const SongEngClap = {
        name: "Clap along with me",
        type: 'ENG_KIDS_SONG',
        data: SongEngClapJson,
    };
    const SongEngBoston = {
        name: "Tort to Boston",
        type: 'ENG_KIDS_SONG',
        data: SongEngBostonJson,
    };
    const SongEngRollBall = {
        name: "Roll that ball",
        type: 'ENG_KIDS_SONG',
        data: SongEngRollBallJson,
    };
    const SongEngSailor = {
        name: "A sailor went to sea",
        type: 'ENG_KIDS_SONG',
        data: SongEngSailorJson,
    };
    const SongEngHandkerchief = {
        name: "Drop the handkerchief",
        type: 'ENG_KIDS_SONG',
        data: SongEngHandkerchiefJson,
    };
    const SongEngLondon = {
        name: "London Bridge",
        type: 'ENG_KIDS_SONG',
        data: SongEngLondonJson,
    };
    const SongEngHumpty = {
        name: "Humpty Dumpty",
        type: 'ENG_KIDS_SONG',
        data: SongEngHumptyJson,
    };
    const SongEngWhatsThis = {
        name: "What_s this",
        type: 'ENG_KIDS_SONG',
        data: SongEngWhatsThisJson,
    };
    const SongEngWhatTime = {
        name: "What time",
        type: 'ENG_KIDS_SONG',
        data: SongEngWhatTimeJson,
    };
    const SongEngBigLittle = {
        name: "Big little",
        type: 'ENG_KIDS_SONG',
        data: SongEngBigLittleJson,
    };
    const SongEngWeather = {
        name: "How_s the weather",
        type: 'ENG_KIDS_SONG',
        data: SongEngWeatherJson,
    };
    const SongEngBabyClothes = {
        name: "Baby_s clothes",
        type: 'ENG_KIDS_SONG',
        data: SongEngBabyClothesJson,
    };
    const SongEngPeasBeans = {
        name: "Oats Peas Beans",
        type: 'ENG_KIDS_SONG',
        data: SongEngPeasBeansJson,
    };
    const SongEngDaysofWeek = {
        name: "Days of the week",
        type: 'ENG_KIDS_SONG',
        data: SongEngDaysofWeekJson,
    };
    const SongEngMonths = {
        name: "Twelve months",
        type: 'ENG_KIDS_SONG',
        data: SongEngMonthsJson,
    };
    const SongEngSallySun = {
        name: "Sally go round the Sun",
        type: 'ENG_KIDS_SONG',
        data: SongEngSallySunJson,
    };
    const SongEngNulberryBush = {
        name: "Here we go round the nulberry bush",
        type: 'ENG_KIDS_SONG',
        data: SongEngNulberryBushJson,
    };
    const SongEngFlowerBird = {
        name: "Snow flower bird",
        type: 'ENG_KIDS_SONG',
        data: SongEngFlowerBirdJson,
    };
    const SongEngRoad = {
        name: "Be careful on the road",
        type: 'ENG_KIDS_SONG',
        data: SongEngRoadJson,
    };
    const SongEngApplesPears = {
        name: "Peaches Apples and Pears",
        type: 'ENG_KIDS_SONG',
        data: SongEngApplesPearsJson,
    };
    const SongEngLittleStar = {
        name: "Twinkle, Twinkle, Little Star",
        type: 'ENG_KIDS_SONG',
        data: SongEngLittleStarJson,
    };
    const SongEngStrawberryJam = {
        name: "Strawberry Jam",
        type: 'ENG_KIDS_SONG',
        data: SongEngStrawberryJamJson,
    };
    const SongEngMary = {
        name: "Lazy Mary",
        type: 'ENG_KIDS_SONG',
        data: SongEngMaryJson,
    };
    const SongEngSky = {
        name: "Reach For The Sky",
        type: 'ENG_KIDS_SONG',
        data: SongEngSkyJson,
    };
    const SongEngWinter = {
        name: "Winter Time",
        type: 'ENG_KIDS_SONG',
        data: SongEngWinterJson,
    };
    const SongEngLamb = {
        name: "Marry Had A Little Lamb",
        type: 'ENG_KIDS_SONG',
        data: SongEngLambJson,
    };
    const SongEngHappy = {
        name: "If You_re Happy",
        type: 'ENG_KIDS_SONG',
        data: SongEngHappyJson,
    };
    const SongEngAngry = {
        name: "If You_re Angry",
        type: 'ENG_KIDS_SONG',
        data: SongEngAngryJson,
    };
    const SongEngVillage = {
        name: "Round The Village",
        type: 'ENG_KIDS_SONG',
        data: SongEngVillageJson,
    };
    const SongEngTenIndians = {
        name: "Ten Little Indians",
        type: 'ENG_KIDS_SONG',
        data: SongEngTenIndiansJson,
    };
    const SongEngHand = {
        name: "Shake My Hand",
        type: 'ENG_KIDS_SONG',
        data: SongEngHandJson,
    };
    const SongEngWearing = {
        name: "What Are You Wearing",
        type: 'ENG_KIDS_SONG',
        data: SongEngWearingJson,
    };
    const SongEngBoat = {
        name: "You_re Rocking The Boat",
        type: 'ENG_KIDS_SONG',
        data: SongEngBoatJson,
    };
    const SongEngWher = {
        name: "Oh Where Oh Where",
        type: 'ENG_KIDS_SONG',
        data: SongEngWherJson,
    };
    const SongEngFingerBand = {
        name: "The Finger Band",
        type: 'ENG_KIDS_SONG',
        data: SongEngFingerBandJson,
    };
    const SongEngHeadSoulders = {
        name: "Head Soulders Knees And Toes",
        type: 'ENG_KIDS_SONG',
        data: SongEngHeadSouldersJson,
    };
    const SongEngElephant = {
        name: "One Elephant Went Out To Play",
        type: 'ENG_KIDS_SONG',
        data: SongEngElephantJson,
    };
    const SongEngTogether = {
        name: "The More We Get Together",
        type: 'ENG_KIDS_SONG',
        data: SongEngTogetherJson,
    };
    const SongEngBingo = {
        name: "Bingo",
        type: 'ENG_KIDS_SONG',
        data: SongEngBingoJson,
    };
    const SongEngBus = {
        name: "People On The Bus",
        type: 'ENG_KIDS_SONG',
        data: SongEngBusJson,
    };
    const SongEngRain = {
        name: "Rain Rain Go Away",
        type: 'ENG_KIDS_SONG',
        data: SongEngRainJson,
    };
    const SongEngYellow = {
        name: "Who Is Wearing Yellow Today",
        type: 'ENG_KIDS_SONG',
        data: SongEngYellowJson,
    };

//제주노래
//     const songjejuDolHarbang = {
//         name: "돌하르방",
//         type: 'KIDS_SONG',
//         data: SongJejuDolHarbangJson,
//     };
//     const songjejuLaugh = {
//         name: "제주어 배워보카",
//         type: 'KIDS_SONG',
//         data: SongJejuDialecJson,
//     };
//     const songjejuDonmoo = {
//         name: "동무",
//         type: 'KIDS_SONG',
//         data: SongJejuDonmooJson,
//     };
//     const songjejuDialect = {
//         name: "웃당보민",
//         type: 'KIDS_SONG',
//         data: SongJejuLaughJson ,
//     };

//트로트
    const songSooni = {
        name: "18세 순이",
        type: 'TROT_SONG',
        data: SongSooniJson ,
    };
    const songGandaeyo = {
        name: "간대요 글쎄",
        type: 'TROT_SONG',
        data: SongGandaeyoJson ,
    };
    const songGunbae = {
        name: "건배",
        type: 'TROT_SONG',
        data: SongGunbaeJson ,
    };
    const songThankyou = {
        name: "고맙소",
        type: 'TROT_SONG',
        data: SongThankyouJson ,
    };
    const songGohyang = {
        name: "고향역",
        type: 'TROT_SONG',
        data: SongGohyangJson ,
    };
    const songLikestar = {
        name: "내 마음 별과 같이",
        type: 'TROT_SONG',
        data: SongLikestarJson ,
    };
    const songMujung = {
        name: "무정 부르스",
        type: 'TROT_SONG',
        data: SongMujungJson ,
    };
    const songMisco = {
        name: "미스고",
        type: 'TROT_SONG',
        data: SongMiscoJson ,
    };
    const songGabcho = {
        name: "잡초",
        type: 'TROT_SONG',
        data: SongGabchoJson ,
    };
    const songReallygood = {
        name: "정말 좋았네",
        type: 'TROT_SONG',
        data: SongReallygoodJson ,
    };
    const songAnDongYukEhSeo = {
        name: "안동역에서",
        type: 'TROT_SONG',
        data: SongAnDongYukEhSeoJson ,
    };
    const songGalMuRi = {
        name: "갈무리",
        type: 'TROT_SONG',
        data: SongGalMuRiJson ,
    };
    const songMakguliHanJan = {
        name: "막걸리 한잔",
        type: 'TROT_SONG',
        data: SongMakguliHanJanJson ,
    };
    const songSarangAh = {
        name: "사랑아",
        type: 'TROT_SONG',
        data: SongSarangAhJson ,
    };
    const songTesHyung = {
        name: "테스형",
        type: 'TROT_SONG',
        data: SongTesHyungJson ,
    };
    const songBulHyoJaneonOopNiDa = {
        name: "불효자는 웁니다",
        type: 'TROT_SONG',
        data: SongBulHyoJaneonOopNiDaJson ,
    };
    const songDongBanJa = {
        name: "동반자",
        type: 'TROT_SONG',
        data: SongDongBanJaJson ,
    };
    const songEbyeoluiBusanJeonggeojang = {
        name: "이별의 부산정거장",
        type: 'TROT_SONG',
        data: SongEbyeoluiBusanJeonggeojangJson ,
    };
    const songGalDaeWaSoonJeong = {
        name: "갈대의 순정",
        type: 'TROT_SONG',
        data: SongGalDaeWaSoonJeongJson ,
    };
    const songTteugeounAnnyeong = {
        name: "뜨거운 안녕",
        type: 'TROT_SONG',
        data: SongTteugeounAnnyeongJson ,
    };
    const songBoritGoGae = {
        name: "보릿고개",
        type: 'TROT_SONG',
        data: SongBoritGoGaeJson ,
    };
    const songEngDoo = {
        name: "앵두",
        type: 'TROT_SONG',
        data: SongEngDooJson ,
    };
    const songHongSea = {
        name: "홍시",
        type: 'TROT_SONG',
        data: SongHongSeaJson ,
    };
    const songlpyeonDanshim = {
        name: "일편단심 민들레야",
        type: 'TROT_SONG',
        data: SonglpyeonDanshimJson ,
    };
    const songNeaNaigaEottaeseo = {
        name: "내 나이가 어때서",
        type: 'TROT_SONG',
        data: SongNeaNaigaEottaeseoJson ,
    };
    const songOhDonglip = {
        name: "오동잎",
        type: 'TROT_SONG',
        data: SongOhDonglipJson ,
    };
    const songSalangEIleongeonGayo = {
        name: "사랑이 이런건가요",
        type: 'TROT_SONG',
        data: SongSalangEIleongeonGayoJson ,
    };
    const songYoungDongBruce = {
        name: "영동 부르스",
        type: 'TROT_SONG',
        data: SongYoungDongBruceJson ,
    };
    const songApart = {
        name: "아파트",
        type: 'TROT_SONG',
        data: SongApartJson ,
    };
    const songMyeotMeterApedaDugo = {
        name: "몇 미터 앞에다 두고",
        type: 'TROT_SONG',
        data: SongMyeotMeterApedaDugoJson ,
    };
    const songBrown = {
        name: "갈색 추억",
        type: 'TROT_SONG',
        data: SongBrownJson ,
    };
    const songTeahouse = {
        name: "그 겨울의 찻집",
        type: 'TROT_SONG',
        data: SongTeahouseJson ,
    };
    const songFlowerwind = {
        name: "꽃바람 여인",
        type: 'TROT_SONG',
        data: SongFlowerwindJson ,
    };
    const songManlife = {
        name: "남자의 인생",
        type: 'TROT_SONG',
        data: SongManlifeJson ,
    };
    const songSouthtrain = {
        name: "남행열차",
        type: 'TROT_SONG',
        data: SongSouthtrainJson ,
    };
    const songNest = {
        name: "둥지",
        type: 'TROT_SONG',
        data: SongNestJson ,
    };
    const songLoverope = {
        name: "사랑의 밧줄",
        type: 'TROT_SONG',
        data: SongLoveropeJson ,
    };
    const songKiss = {
        name: "카스바의 여인",
        type: 'TROT_SONG',
        data: SongKissJson ,
    };
    const songTackle = {
        name: "태클을 걸지마",
        type: 'TROT_SONG',
        data: SongTackleJson ,
    };
    const songHaeundae = {
        name: "해운대 연가",
        type: 'TROT_SONG',
        data: SongHaeundaeJson ,
    };
    // const songTEST = {
    //     name: "TEST",
    //     type: 'TROT_SONG',
    //     data: SongTestJson ,
    // };

// 국악 동요
    const songSeollal = {
        name: "설날",
        type: 'KOR_TRAD_KIDS_SONG',
        data: SongSeollalJson ,
    };
    const songGanggangsullae = {
        name: "강강술래",
        type: 'KOR_TRAD_KIDS_SONG',
        data: SongGanggangsullaeJson ,
    };
    const songKwaejina = {
        name: "쾌지나 칭칭나네",
        type: 'KOR_TRAD_KIDS_SONG',
        data: SongKwaejinaJson ,
    };
    const songArirang2 = {
        name: "아리랑",
        type: 'KOR_TRAD_KIDS_SONG',
        data: SongArirang2Json ,
    };
    const songGunbam2 = {
        name: "군밤타령",
        type: 'KOR_TRAD_KIDS_SONG',
        data: SongGunbam2Json ,
    };
    const songDaemun = {
        name: "대문놀이",
        type: 'KOR_TRAD_KIDS_SONG',
        data: SongDaemunJson ,
    };
    const songYeouya = {
        name: "여우야 여우야",
        type: 'KOR_TRAD_KIDS_SONG',
        data: SongYeouyaJson ,
    };
    const songIgeori = {
        name: "이거리 저거리 각거리",
        type: 'KOR_TRAD_KIDS_SONG',
        data: SongIgeoriJson ,
    };
    const songNilliliya = {
        name: "닐리리야",
        type: 'KOR_TRAD_KIDS_SONG',
        data: SongNilliliyaJson ,
    };
    const songPungnyeonga = {
        name: "풍년가",
        type: 'KOR_TRAD_KIDS_SONG',
        data: SongPungnyeongaJson ,
    };
    const songAIEN = {
        name: "아이엔 주제곡",
        type: "AIEN_SONG",
        data: SongAienJson,
    }

    runInAction(() => {
        // PlayAndEduMusicStore.setList([
        PlayAndEduMusicStore.listAll = [
            songLiku,
            songLikuHBD,
            songShark,
            songCrocodile,
            songGogo,
            songGil,
            songThreeBear,
            songJustleave,
            songStopmoment,
            songStopit,
            songMimic,
            songHeadShoulder,
            songTomato,
            songCandyfloss,
            songBabyBear,
            songFrog,
            songMilk,
            songInging,
            songZoo,
            songFunny,
            songTongtong,
            songGreatman,
            songScarecrow,
            songBday,
            SongAutumnRoad,
            SongBabySnowman,
            SongLeafboat,
            SongFasterslower,
            SongDoggaebee,
            SongSneak,
            SongIntotheForest,
            SongWalkinginForest,
            SongMorninginForest,
            SongFunCamp,
            SongBabySquirrel,
            SongBabyGoat,
            SongAltogether,
            SongColdandHungry,
            SongIoveyou,
            SongGlobalFamily,
            SongFoodFestival,
            SongIamOkay,
            SongSanta,
            SongAutumn,
            SongAnt,
            SongSpider,
            SongRain,
            SongHouse,
            SongFireCar,
            SongBean,
            SongLeaves,
            SongBeetle,
            SongWaterandStone,
            SongSnowman,
            SongOrganize,
            SongHospital,
            SongGlobe,
            SongLoving,
            SongGoblin,
            SongBuilding,
            SongMarketPlay,
            SongMarketParty,
            SongIce,
            SongCosmos,
            SongJalJalJal,
            SongClass,

            songAbc,
            songFireman,
            songHush,
            songYoungstar,
            songGoodmorning,
            SongEngTeaPot,
            SongEngLou,
            SongEngComePlay,
            SongEngAppleTree,
            SongEngBaloon,
            SongEngLetsStop,
            SongEngCompagnie,
            SongEngLolly,
            SongEngClap,
            SongEngBoston,
            SongEngRollBall,
            SongEngSailor,
            SongEngHandkerchief,
            SongEngLondon,
            SongEngHumpty,
            SongEngWhatsThis,
            SongEngWhatTime,
            SongEngBigLittle,
            SongEngWeather,
            SongEngBabyClothes,
            SongEngPeasBeans,
            SongEngDaysofWeek,
            SongEngMonths,
            SongEngSallySun,
            SongEngNulberryBush,
            SongEngFlowerBird,
            SongEngRoad,
            SongEngApplesPears,
            SongEngLittleStar,
            SongEngStrawberryJam,
            SongEngMary,
            SongEngSky,
            SongEngWinter,
            SongEngLamb,
            SongEngHappy,
            SongEngAngry,
            SongEngVillage,
            SongEngTenIndians,
            SongEngHand,
            SongEngWearing,
            SongEngBoat,
            SongEngWher,
            SongEngFingerBand,
            SongEngHeadSoulders,
            SongEngElephant,
            SongEngTogether,
            SongEngBingo,
            SongEngBus,
            SongEngRain,
            SongEngYellow,

            // songjejuDolHarbang,
            // songjejuLaugh,
            // songjejuDonmoo,
            // songjejuDialect,
            songSooni,
            songGandaeyo,
            songGunbae,
            songThankyou,
            songGohyang,
            songLikestar,
            songMujung,
            songMisco,
            songGabcho,
            songReallygood,
            songAnDongYukEhSeo,
            songGalMuRi,
            songMakguliHanJan,
            songSarangAh,
            songTesHyung,
            songBulHyoJaneonOopNiDa,
            songDongBanJa,
            songEbyeoluiBusanJeonggeojang,
            songGalDaeWaSoonJeong,
            songTteugeounAnnyeong,
            songBoritGoGae,
            songEngDoo,
            songHongSea,
            songlpyeonDanshim,
            songNeaNaigaEottaeseo,
            songOhDonglip,
            songSalangEIleongeonGayo,
            songYoungDongBruce,
            songApart,
            songMyeotMeterApedaDugo,

            songBrown,
            songTeahouse,
            songFlowerwind,
            songManlife,
            songSouthtrain,
            songNest,
            songLoverope,
            songKiss,
            songTackle,
            songHaeundae,

            songSeollal,
            songGanggangsullae,
            songKwaejina,
            songArirang2,
            songGunbam2,
            songDaemun,
            songYeouya,
            songIgeori,
            songNilliliya,
            songPungnyeonga,

            songAIEN,


        ];
        PlayAndEduMusicStore.init();
    });
}

export default MusicConfig;
