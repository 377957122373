const KorToEn = (text) => {
    const BASE_CODE = 44032;
    const CHOSUNG = 588;
    const JUNGSUNG = 28;
    const CHOSUNG_LIST = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];
    const JUNGSUNG_LIST = ['ㅏ', 'ㅐ', 'ㅑ', 'ㅒ', 'ㅓ', 'ㅔ', 'ㅕ', 'ㅖ', 'ㅗ', 'ㅘ', 'ㅙ', 'ㅚ', 'ㅛ', 'ㅜ', 'ㅝ', 'ㅞ', 'ㅟ', 'ㅠ', 'ㅡ', 'ㅢ', 'ㅣ'];
    const JONGSUNG_LIST = [' ', 'ㄱ', 'ㄲ', 'ㄳ', 'ㄴ', 'ㄵ', 'ㄶ', 'ㄷ', 'ㄹ', 'ㄺ', 'ㄻ', 'ㄼ', 'ㄽ', 'ㄾ', 'ㄿ', 'ㅀ', 'ㅁ', 'ㅂ', 'ㅄ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    const split_to_array = (sp_list) => {
        let result = [];
        // console.log(sp_list);
        for (let index in sp_list) {
            let char_code = sp_list.charCodeAt(index);
            let char1, char2, char3;
            if (char_code > 123) {
                char_code = sp_list.charCodeAt(index) - BASE_CODE;
                char1 = parseInt(char_code / CHOSUNG);
                if (char1 !== 11) {
                    result.push(CHOSUNG_LIST[char1])
                }

                char2 = parseInt((char_code - (CHOSUNG * char1)) / JUNGSUNG);
                result.push(JUNGSUNG_LIST[char2])

                char3 = parseInt((char_code - (CHOSUNG * char1) - (JUNGSUNG * char2)));
                if (char3 !== 0) {
                    result.push(JONGSUNG_LIST[char3]);
                }
            } else {
                result.push(sp_list[index]);
            }
        };

       return result;
    };

    const hangul_to_eng = (array) => {
        let result = [];
        for (let keyword of array) {
            if (keyword === "ㅏ") result.push("a");
            else if (keyword === "ㅓ") result.push("eo");
            else if (keyword === "ㅗ" ) result.push("o");
            else if (keyword === "ㅜ") result.push("u");
            else if (keyword === "ㅡ") result.push("eu");
            else if (keyword === "ㅣ") result.push("i");
            else if (keyword === "ㅐ") result.push("ae");
            else if (keyword === "ㅔ") result.push("e");
            else if (keyword === "ㅚ") result.push("oe");
            else if (keyword === "ㅟ") result.push("wi");
            else if (keyword === "ㅑ") result.push("ya");
            else if (keyword === "ㅕ") result.push("yeo");
            else if (keyword === "ㅛ") result.push("yo");
            else if (keyword === "ㅠ") result.push("yu");
            else if (keyword === "ㅒ") result.push("yae");
            else if (keyword === "ㅖ") result.push("ye");
            else if (keyword === "ㅘ") result.push("wa");
            else if (keyword === "ㅙ") result.push("wae");
            else if (keyword === "ㅝ") result.push("wo");
            else if (keyword === "ㅞ") result.push("we");
            else if (keyword === "ㅢ") result.push("ui");
            else if (keyword === "ㄱ") result.push("k");
            else if (keyword === "ㄴ") result.push("n");
            else if (keyword === "ㄷ") result.push("d");
            else if (keyword === "ㄹ") result.push("r");
            else if (keyword === "ㅁ") result.push("m");
            else if (keyword === "ㅂ") result.push("p");
            else if (keyword === "ㅅ") result.push("s");
            else if (keyword === "ㅇ") result.push("ng");
            else if (keyword === "ㅈ") result.push("j");
            else if (keyword === "ㅊ") result.push("ch");
            else if (keyword === "ㅋ") result.push("k");
            else if (keyword === "ㅌ") result.push("t");
            else if (keyword === "ㅍ") result.push("p");
            else if (keyword === "ㅎ") result.push("h");
            else if (keyword === "ㄲ") result.push("kk");
            else if (keyword === "ㄸ") result.push("tt");
            else if (keyword === "ㅃ") result.push("pp");
            else if (keyword === "ㅆ") result.push("ss");
            else if (keyword === "ㅉ") result.push("jj");
            else result.push(keyword);
        }
        return result;
    }

    const Init = (text) => {
        // console.log(text)
        let r = split_to_array(text);
        r = hangul_to_eng(r);
        r = r.join("");
        return r;
    }

    return Init(text);
}

export default KorToEn;
