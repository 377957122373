import { observable } from "mobx";

// import a from '../../resource/image/001.png';
// import b from '../../resource/image/main.png';
// import c from '../../resource/image/002.jpeg';

const ProfileUiStore = observable({
  ProfileList:[
    // {
    //   name:'토룩 유치원',
    //   img: b,
    // },
    // {
    //   name:'민트반',
    //   img: b,
    // },
    // {
    //   name:'바나나반',
    //   img: c,
    // },
    // {
    //   name:'토룩 유치원',
    //   img: a,
    // },
    // {
    //   name:'민트반',
    //   img: b,
    // },
    // {
    //   name:'바나나반',
    //   img: c,
    // },
    // {
    //   name:'토룩 유치원',
    //   img: a,
    // },
    // {
    //   name:'민트반',
    //   img: b,
    // },
    // {
    //   name:'바나나반',
    //   img: c,
    // },
  ],

});

export {ProfileUiStore};
