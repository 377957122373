import { observable } from 'mobx';
import { getCookie } from '../../utils/cookie';

const ModeUiStore = observable({
	isBigMode: false,

	initMode() {
		const bigMode = getCookie('bigMode');
		if (bigMode !== undefined) {
			this.isBigMode = JSON.parse(bigMode);
		}
	},

	setIsBigMode(value) {
		this.isBigMode = value;
		console.log('BigMode :', this.isBigMode);
	},
});

export { ModeUiStore };
