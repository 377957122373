import React, { useEffect, useState, useRef, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getCookie, removeAllCookie, setCookie } from '../../../utils/cookie';

import {
	Logo,
	Header,
	MainLogo,
	HeaderProfile,
	ProfileImg,
	ProfileName,
	LikuSerialWrap,
	ProfileWrap,
	LikuSerial,
	DownIcon,
	LoginOut,
	LikuSerialNum,
	LikuList,
	LikuItem,
	ProfileSetting,
	ProfileSetingImage,
	ProfileImg2,
	ProfileName2,
	Profile,
	ItemName,
	ItemBorder,
	HeaderStop,
	HeaderStopEmpty,
	Categoryempty,
	PlayImg,
	StopImg,
	UpdateButton,
	LikuSerialBox,
	UpdateBox,
	UpdateTitle,
	UpdateInfo,
	Right,
	ItemWrap,
	ItemVersion,
	LogoBox,
	BigModeBox,
} from '../../styled/header/HeaderStyled';

import ToroocPlayLogo from '../../../resource/image/header/torooc_play.svg';
import ToroocManageLogo from '../../../resource/image/header/torooc_manage.svg';
import ToroocEduLogo from '../../../resource/image/header/torooc_edu.svg';
import ToroocAppsLogo from '../../../resource/image/header/torooc_apps.svg';
import ToroocLogo from '../../../resource/image/header/torooc.svg';
import LogoImage from '../../../resource/image/refactoring/logo.svg';
import down from '../../../resource/image/header/down.png';
import profileImage from '../../../resource/image/profile.png';

// import IcMessage from '../../../resource/image/header/ic-message.svg';
// import guideImage from '../../../resource/image/header/guide_button.png';
// import guideCloseImage from '../../../resource/image/guide/guide_close_button.png';
import RootStore from '../../../store';
import { getCollectionLikuDB } from '../../../api/SignApi';
import stopIcon from '../../../resource/image/header/stop.png';
import soundIcon from '../../../resource/image/header/sound.gif';
import { PlayAndEduMusicUiStore } from '../../../store/ui/playandedu/PlayAndEduMusicUiStore';
import { PlayerLikuUiStore } from '../../../store/ui/player/PlayerLikuUiStore';
import { QuickMenuControlUiStore } from '../../../store/ui/quickmenu/QuickMenuControlUiStore';
import { QuickMenuControlMotionUiStore } from '../../../store/ui/quickmenu/QuickMenuControlMotionUiStore';
import { QuickMenuControlScenarioUiStore } from '../../../store/ui/quickmenu/QuickMenuControlScenarioUiStore';
import { ContentsControlStore } from '../../../store/domain/ContentsControlStore';
import { ModeUiStore } from '../../../store/ui/ModeUiStore';

const HeaderContainer = observer(() => {
	const {
		LikuStore,
		PlayerUiStore,
		MetaverseContentsUiStore,
		MqttStore,
		ModeUiStore,
	} = RootStore();

	const location = useLocation();
	const navigate = useNavigate();

	const profileRef = useRef(null);

	const [click, setClick] = useState(false);
	const [isUpdateVisible, setIsUpdateVisible] = useState(false);
	// const [modalOpen, setModalOpen] = useState(false);
	const [profileClick, setProfileClick] = useState(false);

	const handleCloseModal = useCallback(
		(e) => {
			if (
				profileClick &&
				(!profileRef.current || !profileRef.current.contains(e.target))
			) {
				setProfileClick(false);
			}
		},
		[profileClick]
	);

	useEffect(() => {
		window.addEventListener('click', handleCloseModal);
		return () => {
			window.removeEventListener('click', handleCloseModal);
		};
	}, [profileClick, handleCloseModal]);

	useEffect(() => {
		// LikuStore.connectCheckAndRetry(true);1
		if (!MqttStore.client) MqttStore.connect();
		else if (
			LikuStore.thisLiku &&
			!(MqttStore.isConnect && MqttStore.isHeartbeat)
		) {
			LikuStore.allUnSubscribe();
			LikuStore.likuStateSubscribe();
			// if (location.pathname === '/')
			LikuStore.likuStatePublish();
		}
		if (location.pathname.includes('watch')) ContentsControlStore.musicalStop();
	}, [MqttStore, location, LikuStore, LikuStore.thisLiku]);

	useEffect(() => {
		if (!location.pathname.includes('watch') && LikuStore.state === 'musical') {
			ContentsControlStore.musicalStop();
		}
	});

	// useEffect(async () => {
	//   if (LikuStore.thisLiku === undefined) {
	//     await LikuStore.setThisLiku(LikuStore?.likus[0]);
	//   }
	// }, [LikuStore.thisLiku]);

	// }, [LikuStore]);

	// useEffect(() => {
	//     console.log('useMemo MqttStore.isConnect', MqttStore.isConnect)
	//     // LikuStore.connectCheckAndRetry();
	//     if (!MqttStore.client) MqttStore.connect();
	// // }, [MqttStore.isConnect, MqttStore.isHeartbeat, location]);   // LikuStore.thisLiku,
	// }, [MqttStore, location]);   // LikuStore.thisLiku,
	// }, [MqttStore]);   // LikuStore.thisLiku,

	const isTorooc =
		location.pathname.includes('login') ||
		location.pathname.includes('signup') ||
		location.pathname.includes('password');
	const logoImage =
		location.pathname === '/'
			? ToroocAppsLogo
			: location.pathname.includes('play')
			? ToroocPlayLogo
			: location.pathname.includes('manage')
			? ToroocManageLogo
			: location.pathname.includes('edu')
			? ToroocEduLogo
			: ToroocLogo;

	const logo = isTorooc ? (
		<MainLogo onClick={() => navigate('/')} src={logoImage} />
	) : (
		<Logo
			onClick={() => {
				if (location.pathname.includes('watch')) navigate('/refactoring');
				else navigate('/');
			}}
			src={logoImage}
		/>
	);

	const playstop = !isTorooc &&
		PlayAndEduMusicUiStore.status &&
		location.pathname.length > 1 && (
			<HeaderStop>
				<Categoryempty></Categoryempty>
				<PlayImg src={soundIcon} />
				<StopImg
					src={stopIcon}
					status={!PlayAndEduMusicUiStore.status}
					onClick={() => {
						PlayAndEduMusicUiStore.musicDone();
					}}
				/>
				<Categoryempty></Categoryempty>
			</HeaderStop>
		);

	const playstopempty = !isTorooc && (
		<HeaderStopEmpty>{/*여백*/}</HeaderStopEmpty>
	);

	const toggleBigMode = !isTorooc && (
		<BigModeBox isBigMode={ModeUiStore.isBigMode}>
			<span>큰글씨 설정</span>
			<button onClick={() => ModeUiStore.setIsBigMode(!ModeUiStore.isBigMode)}>
				{ModeUiStore.isBigMode ? 'ON' : 'OFF'}
			</button>
		</BigModeBox>
	);

	useEffect(() => {
		if (getCookie('token') !== undefined) {
			getCollectionLikuDB().then((r) => {
				// console.log(
				//     "likus",
				//     LikuStore.likus,
				//     "likus length",
				//     LikuStore.likus.length
				// );
				// console.log("r", r, "r length", r.length);
				if (
					LikuStore.likus !== undefined &&
					LikuStore.likus !== null &&
					LikuStore.likus.length !== 0
				) {
					LikuStore.setLikus([]);
				}

				for (let i = 0; i < r?.length; i++) {
					// LikuStore.likus.push(r[i].liku);
					if (r[i].liku !== undefined && r[i].liku !== null) {
						LikuStore.addLikus(r[i].liku);
					}
					// LikuStore.likus.push();
				}

				if (LikuStore.thisLiku === undefined || LikuStore.thisLiku === null) {
					const likuCookie = getCookie('likuSerial');
					let liku = LikuStore.likus.find((liku) => {
						return liku.serial === likuCookie;
					});
					// console.log("liku length", Object.keys(liku).length);

					LikuStore.setThisLiku(liku);

					setCookie('likuSerial', liku?.serial, {
						path: '/',
						maxAge: 43200,
					});
					setCookie('likuUuid', liku?.uuid, {
						path: '/',
						maxAge: 43200,
						domain: '.likuwithme.com',
					});
					QuickMenuControlMotionUiStore.init();
					QuickMenuControlScenarioUiStore.init();
				}
			});
		}
	}, [LikuStore]);

	useEffect(() => {
		ModeUiStore.initMode();
	}, []);

	const LikuItemList = LikuStore.likus?.map((r, index) => {
		const version = JSON.parse(r.version);
		return (
			<LikuItem
				key={index}
				click={click}
				thisLiku={r.serial === LikuStore.thisLiku?.serial}
				onClick={() => {
					setClick(!click);
					LikuStore.setThisLiku(r);
					LikuStore.checkVersion();
					setCookie('likuSerial', r.serial, {
						path: '/',
						maxAge: 43200,
					});
					setCookie('likuUuid', r?.uuid, {
						path: '/',
						maxAge: 43200,
						domain: '.likuwithme.com',
					});
					MetaverseContentsUiStore.isFirst = true;
				}}>
				<ItemWrap>
					<ItemName>{r.serial}</ItemName>
					<ItemVersion>{version.likuVer}</ItemVersion>
				</ItemWrap>
				<ItemBorder />
			</LikuItem>
		);
	});

	const profile = !isTorooc && (
		<HeaderProfile>
			<Profile onClick={() => setProfileClick(!profileClick)} ref={profileRef}>
				<ProfileWrap>
					<ProfileImg src={profileImage} resizeMode='stretch' />
					<ProfileName>{getCookie('userName')}</ProfileName>
				</ProfileWrap>
				{profileClick && (
					<ProfileSetting>
						<ProfileSetingImage>
							<ProfileImg2 src={profileImage} resizeMode='stretch' />
							<ProfileName2>{getCookie('userName')}</ProfileName2>
						</ProfileSetingImage>
						<LoginOut
							onClick={() => {
								removeAllCookie();
								window.location.reload();
							}}>
							로그아웃
						</LoginOut>
					</ProfileSetting>
				)}
			</Profile>
			<LikuSerialWrap>
				<LikuSerialBox>
					{LikuStore.isUpdate && (
						<>
							<UpdateButton
								onClick={() => setIsUpdateVisible(!isUpdateVisible)}>
								N
							</UpdateButton>
							{isUpdateVisible && (
								<UpdateBox>
									<UpdateTitle>업데이트가 있어요!</UpdateTitle>
									<UpdateInfo>
										필수 업데이트가 있습니다.
										<br />
										안드로이드용 리쿠 앱으로 진행해주세요.
									</UpdateInfo>
								</UpdateBox>
							)}
						</>
					)}
					<LikuSerial
						isUpdate={LikuStore.isUpdate}
						onClick={() => setClick(!click)}>
						<LikuSerialNum>{LikuStore.thisLiku?.serial}</LikuSerialNum>
						<DownIcon src={down} click={click} />
					</LikuSerial>
				</LikuSerialBox>
				<LikuList click={click && LikuStore.likus.length > 0}>
					{LikuItemList}
				</LikuList>
			</LikuSerialWrap>
		</HeaderProfile>
	);

	// const openModal = () => {
	//     setModalOpen(true);
	// }
	// const closeModal = () => {
	//         setModalOpen(false);
	// }

	// const guide = !isTorooc && (
	//     <GuideWrapper>
	//         <GuideIcon src={guideImage} onClick={openModal}/>
	//         {location.pathname === '/refactoring' ?
	//             (<PlayGuidePage open={modalOpen} close={closeModal}/>)
	//             : ((location.pathname === '/manage' ?
	//                 (<ManageGuidePage open={modalOpen} close={closeModal}/>)
	//                 : ((location.pathname === '/' ?
	//                         (<GuidePage open={modalOpen} close={closeModal}/>)
	//                         : (null))
	//                 )))}
	//     </GuideWrapper>
	// );

	return (
		<Header
			mode={(
				location.pathname.includes('play') && PlayerUiStore.mode
			).toString()}>
			{!(location.pathname.includes('play') && PlayerUiStore.mode) && (
				<>
					<LogoBox>
						<MainLogo
							onClick={() =>
								location.pathname.includes('watch')
									? navigate(-1)
									: navigate('/')
							}
							src={LogoImage}
						/>
					</LogoBox>
					{/*{logo}*/}
					{/*{playstopempty}*/}
					{/*{playstopempty}*/}
					{/*{playstopempty}*/}
					<Right>
						{playstop}
						{toggleBigMode}
						{profile}
					</Right>
					{/*</Right>*/}
					{/*{guide}*/}
				</>
			)}
		</Header>
	);
});

export default HeaderContainer;
