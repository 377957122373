import { KidAddUiStore } from "../store/ui/KidAddUiStore";
import { getCookie } from "../utils/cookie";
import { ServerStore } from "../store/domain/ServerStore";
import axios from "axios";

export async function kidAddDB(name, id, profile, birth, memo) {
    let URL = `${ServerStore.BASE_URL}/portal/collection/faceUser`;
    let collectionName = getCookie("collectionName");

    let data = JSON.stringify({
        name: name,
        kidId: id,
        profile: profile,
        birth: birth,
        collectionId: collectionName,
        description: memo,
        gender: "FEMALE",
    });
    console.log(data);
    return await fetch(URL, {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
        },
        body: data,
    })
        .then((text) => text.json())
        .then((r) => {
            console.log("kidAddDB", r);
            return r;
        })
        .catch((error) => console.log("kidAddDB", error));
}
/**
 * 이미지 보내고 얼굴인식정보 받아오는 함수
 * @param {object} file - 파일 형태의 이미지
 * @returns {json} response - 객체 형태의 얼굴인식정보
 */
export async function faceDetection(file) {
    let URL = `http://api.likuwithme.com/liku/portal/face/detect`;

    // console.log("URL", URL, "file", file, "file type", typeof file);
    /* 서버로 파일(이미지 포함)을 전송하기 위해 FormData에 추가 */
    const formData = new FormData();
    /* 'file'은 서버에서 해당 파일을 식별하는 키이다. */
    formData.append("file", file);

    try {
        /**
         * post 방식으로 formData 파일에 관한 얼굴인식정보 비동기 요청
         */
        const response = await axios.post(URL, formData, {
            headers: { Authorization: `Bearer ${getCookie("token")}` },
        });
        // console.log(response);
        return response;
    } catch (error) {
        console.error(error);
        return;
    }
}

/**
 * 자른 얼굴 이미지 파일 업로드
 * @param {string} url
 * @param {string} serial
 * @returns
 */
export async function croppedFaceFileUpload(url, serial) {
    let stringSerial = serial.toString();
    // console.log("stringSerial", stringSerial);
    let topic = `http://api.likuwithme.com/liku/contents/metaverse/liku/${stringSerial}/photos`;
    const formData = new FormData();
    formData.append("file", url);
    // console.log("croppedFile", url, "croppedFile Type", typeof url);
    // let masterToken =
    //   "eyJhbGciOiJIUzUxMiJ9.eyJleHAiOjI0OTQyODc5MzksImlhdCI6MTYzMDM3NDMzOSwianRpIjoidG9yb29jQHRvcm9vYy5jb20ifQ.fXlSwVP_20WkZJC07MuemZ1h2gyPTmj1tUjfoZ6SF_MxvW1oWdYSrQOIBeQv1WaUp3rZD7lYTxV_Z_9_PStFsw";

    try {
        const response = await axios.post(topic, formData, {
            // headers: { Authorization: `Bearer ${masterToken}` },
            headers: { Authorization: `Bearer ${getCookie("token")}` },
        });
        // console.log("response", response);
        // const result = response.json;
        // console.log("uploadedFile", result);
        return;
    } catch (error) {
        console.error("uploadedFileError", error);
        return;
    }

    // return await fetch(URL, {
    //   method: "post",
    //   headers: {
    //     // Authorization: `Bearer ${getCookie("token")}`,
    //     Authorization: `Bearer ${masterToken}`,
    //   },
    //   files: file,
    // })
    //   .then((text) => text.json())
    //   .then((r) => {
    //     console.log("uploadedFile", r);
    //     return r;
    //   })
    //   .catch((error) => console.log("faceDetectionLocal", error));
}
/**
 * 얼굴 부분 자르기와 자른 얼굴 회전 처리
 * @param {string} tempURL - 리소스 가르키는 url
 * @param {Array} cropDataList - 얼굴 별 위치와 회전 각도 정보 리스트
 * @returns
 */
export function editImage(tempURL, cropDataList) {
    return new Promise(async (resolve, reject) => {
        try {
            // const tempTempUrl = await fetch(tempURL);
            // console.log("tempTempUrl", tempTempUrl);
            // const blob = await tempTempUrl.blob();
            // console.log("blob", blob);
            // const tempImageURL = URL.createObjectURL(blob);
            // console.log("imageURL parameter", tempImageURL);

            // let cropDataList = [];
            // Object.keys(cropData)?.map((value, index) => {
            //   // console.log("cropData value", cropData[value].boundingBox);
            //   cropDataListcropData[value]);
            // });
            /**
             * 이미지 객체화
             * 객체화된 이미지 중 얼굴 부분 crop
             * 자른 얼굴 부분 회전 후
             * 캔버스에 그리기
             */
            const image = new Image();
            image.src = tempURL;
            image.onload = async function () {
                // console.log("parameter cropData", cropDataList[bigIdx]);
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                // console.log("image", image);
                // console.log("image.width", image.width);
                // console.log("image.height", image.height);
                const ix = cropDataList.boundingBox.left * image.width;
                const iy = cropDataList.boundingBox.top * image.height;
                const iw = cropDataList.boundingBox.width * image.width;
                const ih = cropDataList.boundingBox.height * image.height;
                canvas.width = iw;
                canvas.height = ih;

                // context.drawImage(image, ix, iy, iw, ih, 0, 0, iw, ih);
                let beforeTempCropDataURL = canvas.toDataURL("image/png");
                // console.log("this.tempCropDataURL", beforeTempCropDataURL);

                let typeList = ["nose", "rightEyeLeft", "leftEyeRight"];

                let landmarkList = [];
                // get landmarks
                if (landmarkList.length === 0) {
                    await cropDataList.landmarks.map((value, index) => {
                        for (let i = 0; i < typeList.length; i++) {
                            if (value.type === typeList[i]) {
                                // console.log("value", value, "index", index);
                                let landmarksCopy = Object.assign({}, value);
                                // console.log("landmarksCopy Before", landmarksCopy);
                                landmarksCopy.x = landmarksCopy.x * iw;
                                landmarksCopy.y = landmarksCopy.y * ih;
                                landmarkList.push(landmarksCopy);
                                break;
                            }
                        }
                    });
                }
                // console.log("this.landmarkList.length", landmarkList.length);
                // console.log("cropData.pose.roll", cropDataList.pose.roll);

                // set shift and rotate
                for (let i = 0; i < landmarkList.length; i++) {
                    if (landmarkList[i].type === "nose") {
                        // console.log(
                        //     "nose coordinate",
                        //     landmarkList[i].x,
                        //     landmarkList[i].y
                        // );
                        // hair color
                        context.fillStyle = "#2B1C14";
                        context.fillRect(0, 0, iw, ih);
                        context.translate(iw / 2, ih / 2);
                        context.rotate(
                            (-cropDataList.pose.roll * Math.PI) / 180
                        );
                        // console.log("context rotated!!!");
                        context.translate(-iw / 2, -ih / 2);
                        context.globalAlpha = 0.9;
                        context.fill();
                        context.drawImage(image, ix, iy, iw, ih, 0, 0, iw, ih);
                        // console.log("context drawed!!!");
                    }
                }

                // let tempCropDataURL = canvas.toDataURL("image/png");
                /**
                 * 캔버스에 그린 자르고 회전한 얼굴 부분 이미지 blob화 한 후 url 해제
                 */
                const imageBlob = await new Promise((resolve) => {
                    canvas.toBlob(resolve, "image/png");
                    URL.revokeObjectURL(tempURL);
                });
                /* blob화된 이미지 file형태로 변환 */
                // console.log("imageBlob", imageBlob);
                const file = new File([imageBlob], "image.png", {
                    type: "image/png",
                });
                // console.log("imageFile", file);
                // const imageDataURL = URL.createObjectURL(file);
                // console.log("imageDataURL", imageDataURL);

                // const formData = new FormData();
                // formData.append("file", file);
                // formData.append("bucket", "torooc-rekognition");
                // formData.append("key", "");
                // resolve(imageDataURL);
                resolve(file);
                // resolve(formData);
            };
        } catch (error) {
            reject(error);
        }
    });
}

export async function getFaceUserDB() {
    let URL = `${
        ServerStore.BASE_URL
    }/portal/collection/faceUsers?collectionName=${getCookie(
        "collectionName"
    )}`;

    return await fetch(URL, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
        },
    })
        .then((text) => text.json())
        .then((r) => {
            // console.log('getFaceUserDB', r);
            return r;
        })
        .catch((error) => console.log("getFaceUserDB", error));
}

export async function UpdateFaceUserDB(idx, name, id, profile, birth, memo) {
    let URL = `${ServerStore.BASE_URL}/portal/collection/faceUser`;
    let collectionName = getCookie("collectionName");

    let data = JSON.stringify({
        id: idx, // data의 id
        name: name, // 이름
        kidId: id, //아동고유번호F
        profile: profile,
        birth: birth,
        collectionId: collectionName,
        description: memo,
        gender: "FEMALE",
    });

    console.log(data);
    return await fetch(URL, {
        method: "put",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
        },
        body: data,
    })
        .then((text) => text.text())
        .then((r) => {
            console.log("UpdateFaceUserDB", r);
            return r;
        })
        .catch((error) => console.log("UpdateFaceUserDB", error));
}

export async function DelFaceUserDB(idx, name, id, profile, birth, memo) {
    let URL = `${ServerStore.BASE_URL}/portal/collection/faceUser`;
    let collectionName = getCookie("collectionName");

    let data = JSON.stringify({
        id: idx,
        name: name,
        kidId: id,
        profile: profile,
        birth: birth,
        collectionId: collectionName,
        description: memo,
        gender: "FEMALE",
    });

    console.log(data);
    return await fetch(URL, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
        },
        body: data,
    })
        .then((text) => text.text())
        .then((r) => {
            console.log("DelFaceUserDB", r);
            return r;
        })
        .catch((error) => console.log("DelFaceUserDB", error));
}

// is this url definition right??
// i don't know why set the startTime and endTime value like that when URL define.
export async function getAttendanceDB() {
    let URL = `${ServerStore.BASE_URL}/portal/collection/attendance?startTime=${KidAddUiStore.getAttendanceDBStartDate},23,55,0&endTime=${KidAddUiStore.getAttendanceDBDate},23,50,0`;
    // let URL = `${ServerStore.BASE_URL}/portal/collection/attendance?startTime=2022,3,2,23,0,0&endTime=2022,3,3,20,1,0`;
    // console.log('asdfasdf', getCookie('token'))
    // console.log('URL', URL);
    return await fetch(URL, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
        },
    })
        .then((text) => text.json())
        .then((r) => {
            // console.log('getAttendanceDB', r);
            return r;
        })
        .catch((error) => console.log("getAttendanceDB", error));
}

// startTime
export async function updateStartTimeDB(
    attendanceId,
    id,
    state,
    time,
    endTime
) {
    let URL = `${ServerStore.BASE_URL}/portal/collection/attendance`;
    // {console.log("updateValue: ", attendanceId, id, state, time)}

    let data = JSON.stringify({
        id: attendanceId,
        faceId: id,
        state: state,
        // startTime : time,
        startTime: time.toString(),
        // endTime: endTime,
        // endTime: endTime.toString(),
    });
    return await fetch(URL, {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
        },
        body: data,
    })
        .then((text) => text.json())
        .then((r) => {
            // console.log('updateStartTimeDB', r);
            return r;
        })
        .catch((error) => console.log("updateStartTimeDB", error));
}
// endTime
export async function updateEndTimeDB(
    attendanceId,
    id,
    state,
    startTime,
    endTime
) {
    let URL = `${ServerStore.BASE_URL}/portal/collection/attendance`;

    let data = JSON.stringify({
        id: attendanceId,
        faceId: id,
        state: state,
        startTime: startTime,
        endTime: endTime.toString(),
    });

    console.log(data);
    return await fetch(URL, {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
        },
        body: data,
    })
        .then((text) => text.json())
        .then((r) => {
            console.log("updateStartTimeDB", r);
            return r;
        })
        .catch((error) => console.log("updateStartTimeDB", error));
}

export async function updateStateDB(
    attendanceId,
    id,
    state,
    startTime,
    endTime
) {
    // {console.log("updateStateValue: ", attendanceId, id, state, startTime, endTime)}
    let URL = `${ServerStore.BASE_URL}/portal/collection/attendance`;

    // console.log('updateDB', attendanceId, ", ", state);
    let data = JSON.stringify({
        id: attendanceId,
        faceId: id,
        state: state,
        startTime: startTime,
        endTime: endTime,
    });

    // console.log("updatedStateValue: ", data);
    return await fetch(URL, {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
        },
        body: data,
    })
        .then((text) => text.json())
        .then((r) => {
            // console.log('updateStateDB', r);
            return r;
        })
        .catch((error) => console.log("updateStateDB", error));
}

export async function uploadFileDB(faceUserId, file) {
    let URL = `${ServerStore.BASE_URL}/portal/collection/face`;
    let collectionName = getCookie("collectionName");

    const formData = new FormData();

    console.log("file", file);
    console.log("faceUserId", faceUserId); // 53

    formData.append("collectionName", collectionName);
    formData.append("file", file);
    formData.append("faceUserId", faceUserId);

    return await fetch(URL, {
        method: "Post",
        headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${getCookie("token")}`,
        },
        body: formData,
    })
        .then((text) => text.json())
        .then((r) => {
            console.log("updateStateDB", r);
            return r;
        })
        .catch((error) => console.log("updateStateDB", error));
}

export async function deleteFileDB(faceUserId) {
    let URL = `${ServerStore.BASE_URL}/portal/collection/face`;
    let collectionName = getCookie("collectionName");

    let data = JSON.stringify({
        faceId: faceUserId,
        collection: collectionName,
    });

    console.log(data); // "faceId":"5241049c-25b2-4e44-9f6b-3399fed08cbc"
    return await fetch(URL, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
        },
        body: data,
    })
        .then((text) => text.json())
        .then((r) => {
            console.log("deleteFileDB", r);
            return r;
        })
        .catch((error) => console.log("deleteFileDB", error));
}

export async function getKidUploadFileDB(faceUserId) {
    let collectionName = getCookie("collectionName");
    let URL = `${ServerStore.BASE_URL}/portal/collection/faces?faceUserId=${faceUserId}&collection=${collectionName}`;

    // console.log("============", faceUserId);
    // console.log("============", URL);

    return await fetch(URL, {
        method: "get",
        headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${getCookie("token")}`,
        },
    })
        .then((text) => text.json())
        .then((r) => {
            // console.log("getKidUploadFileDB", r);
            return r;
        })
        .catch((error) => console.log("getKidUploadFileDB", error));
}
