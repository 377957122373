import RootStore from "../store";

import DefaultRandomBlackImage from "../resource/image/quickmenu/contorl/make/action/shuffle_black.svg";
import DefaultRandomWhiteImage from "../resource/image/quickmenu/contorl/make/action/shuffle_white.svg";
import Hi1BlackImage from "../resource/image/quickmenu/contorl/make/action/hi1_black.svg";
import Hi1WhiteImage from "../resource/image/quickmenu/contorl/make/action/hi1_white.svg";
import Hi2BlackImage from "../resource/image/quickmenu/contorl/make/action/hi2_black.svg";
import Hi2WhiteImage from "../resource/image/quickmenu/contorl/make/action/hi2_white.svg";
import HighfiveBlackImage from "../resource/image/quickmenu/contorl/make/action/highfive_black.svg";
import HighfiveWhiteImage from "../resource/image/quickmenu/contorl/make/action/highfive_white.svg";

import ClapBlackImage from "../resource/image/quickmenu/contorl/make/action/clap_black.svg";
import ClapWhiteImage from "../resource/image/quickmenu/contorl/make/action/clap_white.svg";
import PosePicture1BlackImage from "../resource/image/quickmenu/contorl/make/action/pose_picture1_black.svg";
import PosePicture1WhiteImage from "../resource/image/quickmenu/contorl/make/action/pose_picture1_white.svg";
import PosePicture2BlackImage from "../resource/image/quickmenu/contorl/make/action/pose_picture2_black.svg";
import PosePicture2WhiteImage from "../resource/image/quickmenu/contorl/make/action/pose_picture2_white.svg";
import HandshakeBlackImage from "../resource/image/quickmenu/contorl/make/action/handshakeleft_black.svg";
import HandshakeWhiteImage from "../resource/image/quickmenu/contorl/make/action/handshakeleft_white.svg";
import FightingBlackImage from "../resource/image/quickmenu/contorl/make/action/fighting_black.svg";
import FightingWhiteImage from "../resource/image/quickmenu/contorl/make/action/fighting_white.svg";

import BlinkBlackImage from "../resource/image/quickmenu/contorl/make/display/blink_black.svg";
import BlinkWhiteImage from "../resource/image/quickmenu/contorl/make/display/blink_white.svg";
import JoyBlackImage from "../resource/image/quickmenu/contorl/make/display/joy_black.svg";
import JoyWhiteImage from "../resource/image/quickmenu/contorl/make/display/joy_white.svg";
import ShyBlackImage from "../resource/image/quickmenu/contorl/make/display/shy_black.svg";
import ShyWhiteImage from "../resource/image/quickmenu/contorl/make/display/shy_white.svg";
import DizzyBlackImage from "../resource/image/quickmenu/contorl/make/display/dizzy_black.svg";
import DizzyWhiteImage from "../resource/image/quickmenu/contorl/make/display/dizzy_white.svg";
import CalmBlackImage from "../resource/image/quickmenu/contorl/make/display/calm_black.svg";
import CalmWhiteImage from "../resource/image/quickmenu/contorl/make/display/calm_white.svg";
import CryBlackImage from "../resource/image/quickmenu/contorl/make/display/cry_black.svg";
import CryWhiteImage from "../resource/image/quickmenu/contorl/make/display/cry_white.svg";

import StandBlackImage from "../resource/image/quickmenu/contorl/make/action/stand_black.png";
import StandWhiteImage from "../resource/image/quickmenu/contorl/make/action/stand_white.png";
import SitdownBlackImage from "../resource/image/quickmenu/contorl/make/action/sitdown_black.png";
import SitdownWhiteImage from "../resource/image/quickmenu/contorl/make/action/sitdown_white.png";
import ILoveYouBlackImage from "../resource/image/quickmenu/contorl/make/action/i_love_you_black.png";
import ILovYouWhiteImage from "../resource/image/quickmenu/contorl/make/action/i_love_you_white.png";
import WakeUpBlackImage from "../resource/image/quickmenu/contorl/make/action/wakeup_black.png";
import WakeUpWhiteImage from "../resource/image/quickmenu/contorl/make/action/walkup_white.png";
import DanceBlackImage from "../resource/image/quickmenu/contorl/make/action/dance_black.png";
import DanceWhiteImage from "../resource/image/quickmenu/contorl/make/action/dance_white.png";
import WalkBlackImage from "../resource/image/quickmenu/contorl/make/action/walk_black.png";
import WalkWhiteImage from "../resource/image/quickmenu/contorl/make/action/walk_white.png";
import WalkBackBlackImage from "../resource/image/quickmenu/contorl/make/action/walk_back_black.png";
import WalkBackWhiteImage from "../resource/image/quickmenu/contorl/make/action/walk_back_white.png";
import ETCBlackImage from "../resource/image/quickmenu/contorl/make/action/etc_black.png";
import ETCWhiteImage from "../resource/image/quickmenu/contorl/make/action/etc_white.png";
import NeckStretching from "../resource/image/quickmenu/contorl/make/action/neck_stretching.png";

import { runInAction } from "mobx";

const ControlMotionConfig = async () => {
  const { QuickMenuControlMotionStore, QuickMenuControlMotionUiStore } =
    RootStore();

  runInAction(() => {
    QuickMenuControlMotionStore.actions = {
      defaultArray: {
        name: "기본 (랜덤)",
        action: [
          "point1",
          "scroll2",
          "general4",
          "general9",
          "touch",
          "pointing",
        ],
        img: [DefaultRandomBlackImage, DefaultRandomWhiteImage],
      },
      greet_arm: { name: "인사(오른손)", img: [Hi1BlackImage, Hi1WhiteImage] },
      greeting2: { name: "인사(양손)", img: [Hi2BlackImage, Hi2WhiteImage] },
      highfive: {
        name: "하이파이브",
        img: [HighfiveBlackImage, HighfiveWhiteImage],
      },
      applaud: { name: "박수", img: [ClapBlackImage, ClapWhiteImage] },
      pose_picture1: {
        name: "사진포즈(한손)",
        img: [PosePicture1BlackImage, PosePicture1WhiteImage],
      },
      pose_picture2: {
        name: "사진포즈(양손)",
        img: [PosePicture2BlackImage, PosePicture2WhiteImage],
      },
      handshakeleft: {
        name: "악수(오른손)",
        img: [HandshakeBlackImage, HandshakeWhiteImage],
      },
      fighting: {
        name: "화이팅(양손)",
        img: [FightingBlackImage, FightingWhiteImage],
      },
      SelfStandUp: {
        name: "일어나기",
        img: [StandBlackImage, StandWhiteImage],
      },
      SelfSitDown: {
        name: "앉기",
        img: [SitdownBlackImage, SitdownWhiteImage],
      },
      flutter: { name: "사랑해", img: [ILoveYouBlackImage, ILovYouWhiteImage] },
      stretching_arm: {
        name: "기지개",
        img: [WakeUpBlackImage, WakeUpWhiteImage],
      },
      head_test: { name: "목 스트레칭", img: [NeckStretching, NeckStretching] },
      dance_likusong: {
        name: "댄스1",
        img: [DanceBlackImage, DanceWhiteImage],
      },
      ApproachApp: { name: "걷기(앞)", img: [WalkBlackImage, WalkWhiteImage] },
      RecedeApp: {
        name: "걷기(뒤)",
        img: [WalkBackBlackImage, WalkBackWhiteImage],
      },
      user_input: { name: "기타", img: [ETCBlackImage, ETCWhiteImage] },
    };

    QuickMenuControlMotionStore.displays = {
      Blink: { name: "Blink", img: [BlinkBlackImage, BlinkWhiteImage] },
      Joy: { name: "Joy", img: [JoyBlackImage, JoyWhiteImage] },
      Shy: { name: "Shy", img: [ShyBlackImage, ShyWhiteImage] },
      Dizzy: { name: "Dizzy", img: [DizzyBlackImage, DizzyWhiteImage] },
      Calm: { name: "Calm", img: [CalmBlackImage, CalmWhiteImage] },
      Cry: { name: "Cry", img: [CryBlackImage, CryWhiteImage] },
      user_input: { name: "기타", img: [ETCBlackImage, ETCWhiteImage] },
    };
  });

  QuickMenuControlMotionUiStore.initMotion();

  // const expressions = await getCookie('expressions');
  // QuickMenuControlMyExpressionStore.initExpressions(expressions);
};

export default ControlMotionConfig;
