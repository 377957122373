import { getCookie } from "../utils/cookie";
import { ServerStore } from "../store/domain/ServerStore";

export async function collectionDB(name, token) {
  let URL = `${ServerStore.BASE_URL}/portal/collection`;
  let data = JSON.stringify({
    name: name,
  });
  console.log(data);
  return await fetch(URL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: data,
  })
    .then((text) => text.text())
    .then((r) => {
      console.log("collectionDB", r);
      return r;
    })
    .catch((error) => console.log("collectionDB", error));
}

export async function profileDB(email, token) {
  let URL = `${ServerStore.BASE_URL}/portal/collection`;
  let data = JSON.stringify({
    email: email,
    name: "",
    role: "USER",
  });
  console.log(data);
  return await fetch(URL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: data,
  })
    .then((text) => text.json())
    .then((r) => {
      console.log("profileDB", r);
      return r;
    })
    .catch((error) => console.log("profileDB", error));
}

export async function addCollectionMember(email, token) {
  let URL = `${ServerStore.BASE_URL}/portal/collection/profile`;
  let name = email.split("@");
  let data = JSON.stringify({
    profile: {
      email: email,
      name: "",
      role: "USER",
    },
    collection: {
      name: name[0],
    },
  });
  console.log(data);
  return await fetch(URL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: data,
  })
    .then((text) => text.json())
    .then((r) => {
      console.log("addCollectionMember", r);
      return r;
    })
    .catch((error) => console.log("addCollectionMember", error));
}

export async function getCollectionNameDB() {
  let URL = `${ServerStore.BASE_URL}/portal/collection/member`;

  return await fetch(URL, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie("token")}`,
    },
  })
    .then((text) => text.json())
    .then((r) => {
      console.log("getCollectionName", r);
      return r;
    })
    .catch((error) => console.log("getCollectionName", error));
}

export function getCollectionLikuDB() {
  return new Promise(async (resolve, reject) => {
    if (!ServerStore.BASE_URL) {
      const intervalId = setInterval(() => {
        if (ServerStore.BASE_URL) {
          clearInterval(intervalId); // BASE_URL이 설정되면 interval을 중단
          resolve(ServerStore.BASE_URL); // Promise를 resolve하여 fetch 작업 진행
        }
      }, 100); // 1초에 한 번씩 체크
    } else {
      resolve(ServerStore.BASE_URL);
    }
  })
    .then((BASE_URL) => {
      const URL = `${BASE_URL}/portal/collection/likus`;
      return fetch(URL, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("token")}`,
        },
      });
    })
    .then((response) => response.json())
    .catch((error) => console.log("getCollectionLiku", error));
}
