import { observable, reaction, runInAction, toJS } from "mobx";
import MotionRepository from "../../../data/repository/MotionRespository";
import { QuickMenuControlMotionUiStore } from "../../ui/quickmenu/QuickMenuControlMotionUiStore";

const QuickMenuControlMotionStore = observable({
    motions: null,
    actions: null,
    displays: null,

    async getAllMotion() {
        await MotionRepository.getAllMotion().then(async (value) => {
            await Promise.all(
                value.map(async (motion) => {
                    return await this.getMotion(motion.uuid).then((r) => {
                        motion.json = r;
                        return toJS(motion);
                    });
                })
            );
            runInAction(() => {
                this.motions = value;
            });
        });
        QuickMenuControlMotionUiStore.initMotions(this.motions);
    },

    async getMotion(uuid) {
        return await MotionRepository.getMotion(uuid).then((value) => {
            return value;
        });
    },

    setMotions(data) {
        MotionRepository.setMotion(data).then(() => this.getAllMotion());
    },

    deleteMotion(uuid) {
        MotionRepository.deleteMotion(uuid).then((r) => {
            if (r === 200) this.getAllMotion();
            else alert("시나리오에 포함된 표현이예요!");
        });
    },
});

reaction(
    () => QuickMenuControlMotionStore.motions,
    (value) => {
        QuickMenuControlMotionUiStore.initMotions(value);
    }
);
reaction(
    () => QuickMenuControlMotionStore.actions,
    (value) => {
        QuickMenuControlMotionUiStore.setActions(value);
    }
);
reaction(
    () => QuickMenuControlMotionStore.displays,
    (value) => {
        QuickMenuControlMotionUiStore.setDisplays(value);
    }
);

export { QuickMenuControlMotionStore };
