import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import {getCookie, removeAllCookie} from "../utils/cookie";

const PrivateRouter = () => {
    const isLogin = getCookie('token');
    console.log('isLogin', isLogin);
    if (isLogin === undefined) removeAllCookie();
    return isLogin === undefined ? <Navigate to="/login" /> : <Outlet />;
}

export default PrivateRouter;
