import {observable, reaction} from "mobx";

import {PlayAndEduMusicUiStore} from "../ui/playandedu/PlayAndEduMusicUiStore";
import { getCookie } from '../../utils/cookie';
import { KidMusicType, SeniorMusicType } from '../../data/model/music/MusicType';

const PlayAndEduMusicStore = observable({
    listAll: null,
    list: null,
    musicType: null,

    init(role) {
        console.log('init');
        this.setMusicType(role);
        const list = this.getList();
        this.setList(list);
    },

    setMusicType(_role) {
        const role = _role ? _role : getCookie('userRole');
        this.musicType = role === 'SENIOR' ? SeniorMusicType : KidMusicType;
    },

    setList(list) {
        this.list = list;
    },

    getList(type = 'ALL') {
        let filterList;
        if (type !== 'ALL') {
            filterList = this.listAll?.filter((contents) => contents.type === type);
        } else {
            // filterList = this.list.slice();
            filterList = this.listAll?.filter((contents) => {
                if (Object.keys(this.musicType).find((mt) => contents.type === mt) !== undefined) {
                    return contents;
                }
            });

        }
        console.log('getList', filterList);

        return filterList;
    },
});

reaction(
    () => PlayAndEduMusicStore.list,
    value => {
        PlayAndEduMusicUiStore.setList(value);
    }
);

export {PlayAndEduMusicStore};
