import {ContentsUiStore} from "../store/ui/ContentsUiStore";
import {MusicalType} from "../data/model/play/MusicalType.js";
import {runInAction} from "mobx";

const ContentsConfig = () => {
    runInAction(() => {
        ContentsUiStore.defaultContentsList = {
            liku: {
                name: '리쿠',
                color: '#3dd598',
                data: {}
            }
        };
    });

    for (const type of Object.keys(MusicalType)) {
        ContentsUiStore.defaultContentsList.liku.data[type] = [];
    }

    ContentsUiStore.libraries = {
        KIDS: {
            name: '유치원 / 어린이집',
            color: '#fedd27',
        },
        SCHOOL: {
            name: '초등학교',
            color: '#d1fd8f',
        },
        ENGLISH: {
            name: '영어교육',
            color: '#f88eb7',
        },
        // METAVERSE: {
        //     name: '메타버스',
        //     color: '#d1b8fc',
        // },
    };

    ContentsUiStore.recommendedCategoriesType = {
        default: {
            kids_new: '유치원 / 어린이집 신규 콘텐츠',
            school_new: '초등 신규 콘텐츠',
        },
        library: {
            top: '인기 TOP 10',
            new: 'NEW',
            recommend: '추천',
        },
    }
};

export default ContentsConfig;
