import { observable, reaction } from "mobx";
import ContentsRepository from "../../data/repository/ContentsRepository";
import { PlayerUiStore } from "../ui/player/PlayerUiStore";
import { PlayerRoleUiStore } from "../ui/player/PlayerRoleUiStore";
import { PlayerBackgroundUiStore } from "../ui/player/PlayerBackgroundUiStore";
import { InteractiveStore } from "./InteractiveStore";
import { InteractiveUiStore } from "../ui/interactive/InteractiveUiStore";

const PlayerStore = observable({
    contentsList: null,
    contents: null,
    roleColor: [],
    contentsScenarioList: [],
    backgroundImageList: [],
    backgroundMusicList: [],
    aruco: null,
    positionList: null,
    // category: null,
    uuid: null,
    isPosition: false,
    isLoadComplete: false,

    clear() {
        this.contents = null;
        this.setUuid(null);
        this.setRole([]);
        this.setContentsScenario([]);
        this.setBackgroundImage([]);
        this.setBackgroundMusic([]);
        this.setPositionList([]);
    },

    setContentsList(list) {
        this.contentsList.liku.data.musical.list = list;
    },

    setIsLoadComplete(isLoadComplete) {
        this.isLoadComplete = isLoadComplete;
    },

    setIsPosition(isPosition) {
        this.isPosition = isPosition;
    },

    setPositionList(list) {
        this.positionList = list;
    },

    async getContents(contents) {
        this.contents = contents;
        let contentsJson = this.contents?.path.split("/");
        console.log(
            "contentsJson",
            contentsJson,
            "contentsJson Length",
            contentsJson?.length
        );
        if (contentsJson !== undefined && contentsJson !== null) {
            contentsJson = contentsJson[contentsJson?.length - 1]?.replace(
                ".json",
                ""
            );

            console.log("contentsJson", contentsJson);
            await ContentsRepository.getContents(contentsJson).then((r) => {
                console.log("r", r, "r.roles", r.roles);
                const isInteractive = PlayerUiStore.isInteractive();
                this.setRole(r.roles);
                this.setContentsScenario(r.data);
                this.setBackgroundImage(r.background);
                this.setBackgroundMusic(r.bgm);
                this.setAruco(r.aruco);
                this.setPositionList(r.background);

                if (isInteractive) {
                    InteractiveStore.setNodes(r.nodes);
                    InteractiveStore.setEdges(r.edges);
                }

                if (PlayerUiStore.isMetaverse() || PlayerUiStore.isBirthday()) {
                    for (let i = 0; i < PlayerStore.positionList.length; i++) {
                        if (
                            PlayerStore.positionList[i].position !==
                                undefined &&
                            PlayerStore.positionList[i].position?.length !== 0
                        ) {
                            this.setIsPosition(true);
                            break;
                        }
                    }
                }
            });
        } else {
            alert("존재하지 않는 콘텐츠입니다!!");
        }
    },

    async contentsStart(title, roles) {
        let data = {
            title: title,
            serials: roles,
        };
        console.log("contentStart", data);
        let uuid = await ContentsRepository.contentsStart(data);
        this.setUuid(uuid);
    },

    async contentsStop(title, roles) {
        let data = {
            title: title,
            serials: roles,
            uuid: this.uuid,
        };
        // return this.contentsScenarioList;
        await ContentsRepository.contentsStop(data);
        // let allData = this.getAllContentsData();
        //
        // return allData;
    },

    setRole(data) {
        this.roleColor = data;
    },

    setContentsScenario(data) {
        this.contentsScenarioList = data;
        // console.log("ScenarioList", this.contentsScenarioList);
    },

    setBackgroundImage(data) {
        this.backgroundImageList = data;
    },

    setBackgroundMusic(data) {
        this.backgroundMusicList = data;
    },

    setAruco(data) {
        this.aruco = data;
    },

    setUuid(uuid) {
        this.uuid = uuid;
        console.log("uuid!!!", this.uuid);
    },

    getAllContentsData() {
        let roleColor = this.roleColor.slice();
        let contentsScenarioList = this.contentsScenarioList.slice();
        let backgroundImageList = this.backgroundImageList.slice();
        let backgroundMusicList = this.backgroundMusicList.slice();
        return {
            role: roleColor,
            scenario: contentsScenarioList,
            image: backgroundImageList,
            music: backgroundMusicList,
        };
    },
});

reaction(
    () => PlayerStore.uuid,
    (value) => {
        PlayerUiStore.setUuid(value);
    }
);

reaction(
    () => PlayerStore.contents,
    (value) => {
        PlayerUiStore.setContents(value);
    }
);

reaction(
    () => PlayerStore.roleColor,
    (value) => {
        PlayerRoleUiStore.initRole(value);
    }
);

reaction(
    () => PlayerStore.backgroundImageList,
    (value) => {
        PlayerBackgroundUiStore.initBackgroundList("image", value);
    }
);
reaction(
    () => PlayerStore.backgroundMusicList,
    (value) => {
        PlayerBackgroundUiStore.initBackgroundList("music", value);
    }
);

export { PlayerStore };
