import styled from 'styled-components';
import { defaultTheme } from '../Theme';

export const Header = styled.header`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0 1px 4px 0 rgba(21, 34, 50, 0.08);
	width: 100%;
	//padding: 10px 12px 8px 12px;
	//height: 6.7rem;
	height: 7%;
	min-height: 5rem;
	max-height: 6.7rem;
	box-sizing: border-box;
	padding: 0 1.5%;
	//z-index: 2;
	z-index: 9999;
	background-color: ${(props) =>
		props.mode === 'true' ? '#000000' : '#ffffff'};
`;

export const Right = styled.div`
	position: relative;
	//background-color: #61dafb;
	height: 100%;
	max-width: 100rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const Logo = styled.img`
	//width: 10%;
	//min-width: 12rem;
	////background-color: #61dafb;
	//height: 100%;
	////height: 2rem;
	//cursor: pointer;

	//margin-left: 1.5rem;
	width: 10%;
	min-width: 16rem;
	//background-color: #61dafb;
	height: 100%;
	//aspect-ratio: 5.5 / 1;
	//height: 2rem;
	//padding: 0 1rem 0 0;
	object-fit: contain;
	cursor: pointer;
`;

export const LogoBox = styled.div`
	height: 100%;
	aspect-ratio: 1 / 1;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	cursor: pointer;
`;

export const MainLogo = styled.img`
	//margin-left: 1.5rem;
	//width: 5%;
	//min-width: 12rem;
	//background-color: #61dafb;
	//height: 50%;
	height: 25%;
	//aspect-ratio: 5.5 / 1;
	//height: 2rem;
`;

export const LogoWrap = styled.div`
	width: 14%;
	height: 100%;
	// backgroun5d-color: palegoldenrod;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
`;

export const HeaderMenu = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 4.3%;
	height: 70%;
`;

export const HeaderMenuIcon = styled.img`
	width: 3.5rem;
	height: 2.5rem;
`;

export const GuideIcon = styled.img`
	cursor: pointer;
`;

export const GuideWrapper = styled.div`
	display: flex;
	height: 5vmin;
`;

export const Components = styled.div`
	display: flex;
	height: 8vmin;
	align-items: center;
	justify-content: space-between;
	// z-index: 3;
`;

export const HeaderProfile = styled.div`
	position: relative;
	//width: 100%;
	min-width: 27rem;
	//max-width: 100rem;
	//width: 25rem;
	height: 80%;
	//height: 5vmin;
	border-radius: 2.5rem;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16), inset 0 0 5px 0 rgba(0, 0, 0, 0.16);
	background-color: #fff;
	display: flex;
	padding: 0 0.5vmin;
	align-items: center;
	justify-content: space-between;
	// z-index: 100;
`;

export const HeaderStop = styled.div`
	position: relative;
	//width: 10%;
	min-width: 10rem;
	//width: 25rem;
	height: 80%;
	//height: 5vmin;
	margin: 0 1rem 0 0;
	border-radius: 2.5rem;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16), inset 0 0 5px 0 rgba(0, 0, 0, 0.16);
	background-color: #fff;
	display: flex;
	//padding: 0 0.5vmin;
	align-items: center;
	justify-content: space-between;
	// z-index: 100;
`;

export const HeaderStopEmpty = styled.div`
	position: relative;
	width: 16%;
	//min-width: 27rem;
	//width: 25rem;
	height: 80%;
	//height: 5vmin;
	border-radius: 2.5rem;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16), inset 0 0 5px 0 rgba(0, 0, 0, 0.16);
	background-color: #fff;
	display: flex;
	padding: 0 0.5vmin;
	align-items: center;
	justify-content: space-around;
	// z-index: 100;
	visibility: hidden;
`;

export const Categoryempty = styled.div`
	width: 5%;
	height: 100%;
`;

export const PlayImg = styled.img`
	//cursor: pointer;
	object-fit: scale-down;
	align-items: center;
	justify-content: center;
	width: 50%;
	height: 50%;
`;
export const StopImg = styled.img`
	cursor: pointer;
	object-fit: scale-down;
	align-items: center;
	justify-content: center;
	width: 50%;
	height: 100%;
`;

export const Space = styled.div`
	width: 1vmin;
	height: 6vmin;
	//background-color: #1d39c4;
`;

export const Space2 = styled.div`
	width: 2vmin;
	height: 6vmin;
`;

export const Profile = styled.div`
	min-width: 37%;
	//width: 100%;
	height: 100%;
	//margin: 0 2% 0 0;
`;

export const ProfileWrap = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	border-radius: 2rem;
	border: dotted 3px rgba(0, 0, 0, 0);
	//background-color: #1d39c4;
`;

export const ProfileSetting = styled.div`
	position: absolute;
	left: -37%;
	top: 95%;
	width: 45%;
	aspect-ratio: 1 / 0.8;
	//height: 18rem;
	border-radius: 0.2rem;
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.16);
	background-color: #fff;
	flex-direction: column;
	z-index: 2;
	//display: ${(props) => (props.click ? 'flex' : 'none')}
`;

export const ProfileSetingImage = styled.div`
	width: 100%;
	height: 80%;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	//background-color: red;
`;

export const LoginOut = styled.button`
	font-family: 'HelveticaNeue-Medium';
	font-size: 1.5rem;
	//font-weight: bold;
	text-align: center;
	color: #707070;
	//margin: 0 1rem;
	//border-radius: 50%;
	border: none;
	width: 100%;
	height: 20%;
`;

export const ProfileImg = styled.img`
	//width: 4vmin;
	height: 90%;
	aspect-ratio: 1 / 1;
	margin-right: 0.8vmin;
	border-radius: 50%;
	/* border: 1px solid pink; */
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16),
		inset 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;
export const ProfileImg2 = styled.img`
	//width: 6vmin;
	//height: 6vmin;
	height: 70%;
	aspect-ratio: 1 / 1;
	border-radius: 50%;
	// background-color: green;
	/* border: 1px solid pink; */
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16),
		inset 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;
export const ProfileName = styled.div`
	display: inline-block;
	font-family: HelveticaNeue;
	//font-size: 1.8rem;
	font-size: 2.2rem;
	font-weight: bold;
	color: #707070;
	//max-width: 22vmin;
	//max-height: 5vmin;
	height: 90%;
	overflow: auto;
	display: flex;
	align-items: center;
	//justify-content: center;
	//white-space: nowrap;
	word-break: break-all;
	//background-color: yellow;
`;
export const ProfileName2 = styled.div`
	font-family: HelveticaNeue;
	width: 100%;
	font-size: 2rem;
	font-weight: bold;
	text-align: center;
	color: #707070;
	//margin: 0 1rem;
	margin: -0.7rem 0;
	//background-color: yellow;
	line-height: 2;
`;
export const LikuSerialWrap = styled.div`
	//position: relative;
	//width: 55%;
	//height: 100%;
	//display: flex;
	//flex-direction: column;
	////align-items: center;
	//justify-content: center;
	////background-color: red;

	position: relative;
	display: inline-block;
	width: 30rem;
	//height: 5vmin;
	//width: 63%;
	height: 90%;
	//display: flex;
	// background-color: red;
	//align-items: center;
`;

export const LikuSerialBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 3;
	border: none;
	border-radius: 2.9rem;
	box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.16);
	background-color: #f4f4f4;
	//background-color: rebeccapurple;
	width: 100%;
	height: 100%;
`;

export const LikuSerial = styled.button`
	//box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.16);
	//background-color: #f4f4f4;
	//width: 100%;
	//height: 80%;
	////margin: 50% 0;
	//border-radius: 2.6rem;
	//display: flex;
	//align-items: center;
	//justify-content: space-between;
	//transition: all 1.5s ease;
	//z-index: 2;

	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 3;
	padding: 0;
	border: none;
	border-radius: 2.9rem;
	background-color: transparent;
	//box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.16);
	//background-color: #f4f4f4;
	height: 100%;
	width: ${(props) => (props.isUpdate ? '83%' : '100%')};
`;

export const UpdateWrap = styled.div``;

export const UpdateButton = styled.button`
	//width: 10rem;
	height: 75%;
	aspect-ratio: 1 / 1;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	border-radius: 100%;
	background-color: #db4545;
	//font-family: HelveticaNeue;
	margin: 0 0 0 2%;
	font-size: 1.9rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #fff;
`;

export const UpdateBox = styled.div`
	position: absolute;
	left: -26%;
	top: 95%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 70%;
	//aspect-ratio: 1 / 1;
	min-height: 15rem;
	//height: 18rem;
	border-radius: 0.2rem;
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.16);
	background-color: #fff;
	flex-direction: column;
	z-index: 2;
	padding: 5% 7%;
`;

export const UpdateTitle = styled.div`
	//font-family: HelveticaNeue;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.7rem;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #707070;
`;

export const UpdateInfo = styled.div`
	//font-family: HelveticaNeue;
	font-size: 1.3rem;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.4;
	letter-spacing: normal;
	text-align: left;
	color: #707070;
`;

export const LikuSerialNum = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 21rem;
	margin: 0 0 0 5%;
	//padding-left: 1.2rem;
	font-size: ${defaultTheme.fonts.size.lg};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: ${defaultTheme.color.default};
`;

export const LikuList = styled.ul`
	position: absolute;
	overflow-y: auto;
	top: 25%;
	//padding: 5rem 0 1rem 0;
	z-index: 0;
	width: 100%;
	//width: 30rem;
	//width: 40rem;
	//display: flex;
	//flex-direction: column;
	//align-items: center;
	//justify-content: center;
	//border: 1px;
	border-radius: 1.5rem;
	//min-height: 0rem;
	//max-height: 20rem;
	background-color: #ffffff;
	box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.16);
	transition: all 1.5s ease;
	${({ click }) => {
		return click
			? `max-height: 12rem;
               padding: 3rem 0 1rem`
			: `max-height: 0rem`;
	}}
`;

// ${({click}) => {
//     return click && 'box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.16);';
// }}

export const LikuItem = styled.li`
	width: 100%;
	padding: 0 7.5%;
	height: 4rem;
	background-color: ${(props) =>
		props.this ? 'rgb(180, 180, 180, 0.2)' : 'transparent'};

	:hover {
		background-color: rgb(180, 180, 180, 0.2);
	}
`;

export const DownIcon = styled.img`
	//width: 10rem;
	height: 100%;
	transition: all 1.5s ease;
	cursor: pointer;
	${({ click }) => {
		return (
			click &&
			`
        transform: rotate(180deg);
    `
		);
	}}
`;

export const ItemWrap = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const ItemName = styled.span`
	//width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	//background-color: transparent;
	//border-bottom: 0.5px dotted #b4b4b4;
	font-size: ${defaultTheme.fonts.size.lg};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: ${defaultTheme.color.default};
`;

export const ItemVersion = styled.span`
	//width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	//background-color: transparent;
	//border-bottom: 0.5px dotted #b4b4b4;
	//font-size: ${defaultTheme.fonts.size.minimini};
	font-size: 1.2rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: ${defaultTheme.color.subTitle};
`;

export const ItemBorder = styled.hr`
	width: 100%;
	margin: 0 auto;
	border-top: 0.5px dashed #b4b4b4;
`;

export const BigModeBox = styled.div`
	background-color: #f7f7f5;
	width: 180px;
	height: 70%;
  //height: 7%;
  //min-height: 5rem;
  //max-height: 6.7rem;
	display: flex;
	justify-content: space-around;
	align-items: center;
	border-radius: 12px;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
	margin-right: 1rem;
	span {
		font-size: 1.6rem;
		line-height: 1.5;
		color: #46423d;
		font-family: NotoSansKR-Regular;
	}
	button {
		font-family: NotoSansKR-Bold;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		width: 64px;
		height: 70%;
		//height: 32px;
		border-radius: 0.8rem;
		background-color: ${({ isBigMode }) => (isBigMode ? '#ffad26' : '#e2e2e2')};
		color: #f7f7f5;
		box-shadow: inset -2px -2px 6px 0 rgba(0, 0, 0, 0.08);
		font-size: 1.6rem;
		cursor: pointer;
	}
`;
