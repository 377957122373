import { ServerStore } from "../../store/domain/ServerStore";
import {
  deleteAxios,
  deleteServerFetchText,
  getFetchJson,
  getTokenFetchJson,
  postTokenFormDataFetch,
} from "../../api/ServerApi";

const MotionRepository = {
  async getAllMotion() {
    const url = `${ServerStore.BASE_URL}/contents/motion`;
    return await getTokenFetchJson(url).then((r) => {
      return r;
    });
  },

  async getMotion(uuid) {
    const url = `${ServerStore.BASE_URL}/contents/motion/${uuid}`;
    return await getTokenFetchJson(url).then(async (r) => {
      return await getFetchJson(r.url);
    });
  },

  async setMotion(data) {
    const url = `${ServerStore.BASE_URL}/contents/motion`;
    return await postTokenFormDataFetch(url, data).then((r) => {
      return JSON.parse(r);
    });
  },

  async deleteMotion(uuid) {
    const url = `${ServerStore.BASE_URL}/contents/motion?uuid=${uuid}`;
    try {
      const data = await deleteAxios(url);
      return JSON.parse(data);
    } catch (error) {
      console.error(error);
    }

    // return await deleteServerFetchText(url).then((r) => {
    //   return JSON.parse(r);
    // });
  },
};

export default MotionRepository;
