import { observable, reaction, runInAction } from "mobx";
import { QuickMenuControlMyExpressionUiStore } from "../../ui/quickmenu/QuickMenuControlMyExpressionUiStore";
import { setCookie } from "../../../utils/cookie";
import ScenarioRepository from "../../../data/repository/ScenarioRespository";
import { QuickMenuControlMotionStore } from "./QuickMenuControlMotionStore";
import { QuickMenuControlScenarioUiStore } from "../../ui/quickmenu/QuickMenuControlScenarioUiStore";
import { QuickMenuControlUiStore } from "../../ui/quickmenu/QuickMenuControlUiStore";
// import Expression from "../../data/model/Expression";

const QuickMenuControlScenarioStore = observable({
  scenarios: [],

  async getAllScenario() {
    await ScenarioRepository.getAllScenario().then(async (value) => {
      await Promise.all(
        value.map(async (scenario) => {
          return await ScenarioRepository.getScenario(scenario.id).then((r) => {
            scenario.json = r;
            return scenario;
          });
        })
      );
      runInAction(() => {
        this.scenarios = value;
      });
    });
  },

  async getScenarioById(_id) {
    try {
      return await ScenarioRepository.getScenarioById(_id);
    } catch (error) {
      return error;
    }
  },

  async setScenario(data) {
    await ScenarioRepository.setScenario(data).then(async (value) => {
      console.log("setScenario", value);
    });
  },

  deleteExpression(index) {
    this.expressions.splice(index, 1);
  },

  connectScenarioMotion() {
    if (!QuickMenuControlUiStore.executionList) {
      QuickMenuControlScenarioUiStore.inItConnectScenarioMotion();
      return;
    }
    const findNull = QuickMenuControlUiStore.executionList.findIndex(
      (value) => value === null
    );
    const execution = QuickMenuControlUiStore.executionList.slice(0, findNull);

    const findScenario = this.scenarios.findIndex((scenario) => {
      return scenario.id === QuickMenuControlScenarioUiStore.connectUuid;
    });

    if (findScenario) {
      execution.forEach((value) => {
        ScenarioRepository.connectScenarioMotion(
          this.scenarios[findScenario].id,
          value.id
        );
      });

      QuickMenuControlScenarioUiStore.inItConnectScenarioMotion();
    }
  },

  async deleteScenario(id) {
    await ScenarioRepository.deleteScenario(id).then((r) => {
      this.getAllScenario();
      QuickMenuControlUiStore.initExecutionList();
    });
  },

  async removeMotionInScenario(scenarioId, motionId, motionOrder) {
    const result = await ScenarioRepository.removeMotionInScenario(
      scenarioId,
      motionId,
      motionOrder
    );
    return result;
  },
});

reaction(
  () => QuickMenuControlScenarioStore.scenarios,
  (value) => {
    QuickMenuControlScenarioUiStore.initScenarios(value);
  }
);

export { QuickMenuControlScenarioStore };
