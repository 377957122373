import {ServerStore} from "../../store/domain/ServerStore";
import {postTokenFetchText} from "../../api/ServerApi";

const LikuRepository = {
    async getLikuInfo(data) {
        let url = `${ServerStore.BASE_URL}/liku/liku`;
        return await postTokenFetchText(url, data).then(r => {
            console.log('rrr', r);
            let value = r;
            try {
                value = JSON.parse(r.replaceAll('"{','{').replaceAll('}"','}'));
            } catch (error) {}
            return value;
        });
    },
};

export default LikuRepository;
