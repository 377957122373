import { getCookie } from "../utils/cookie";
import { ServerStore } from "../store/domain/ServerStore";

// 특정 liku에 저장되어 있는 얼굴 사진 정보 목록(URL) 조회(페이지 처리)
export async function getFaceFileDB() {
    let serial = getCookie("likuSerial");
    let URL = `${ServerStore.BASE_URL}/contents/metaverse/liku/${serial}/photos`;

    return await fetch(URL, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
        },
    })
        .then((text) => text.json())
        .then((r) => {
            // console.log('getFaceFileDB', r);
            return r;
        })
        .catch((error) => console.log("getFaceFileDB", error));
}

// 특정 liku에 저장되어 있는 특정 얼굴 사진 삭제
// api.likuwithme.com/liku/contents/metaverse/liku/2109L990/photos/133
export async function deleteFaceFileDB(faceId) {
    // let collectionName = getCookie('collectionName');
    // console.log("faceID", faceId);
    let serial = getCookie("likuSerial");
    let URL = `${ServerStore.BASE_URL}/contents/metaverse/liku/${serial}/photos/${faceId}`;

    let data = JSON.stringify({
        faceId: faceId,
        // collection: collectionName,
    });
    console.log(data);
    return await fetch(URL, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
        },
        body: data,
    })
        .then((text) => text.json())
        .then((r) => {
            console.log('deletedFaceFileDBResult', r);
            return r;
        })
        .catch((error) => console.log("deleteFaceFileDB", error));
}
