import { observable, reaction } from 'mobx';
import { PlayerStore } from './PlayerStore';
import { PlayerUiStore } from '../ui/player/PlayerUiStore';
import { PlayerRoleUiStore } from '../ui/player/PlayerRoleUiStore';
import { PlayerLikuUiStore } from '../ui/player/PlayerLikuUiStore';
import { MetaverseContentsUiStore } from '../ui/metaverse/MetaverseContentsUiStore';
import { PlayerBackgroundUiStore } from '../ui/player/PlayerBackgroundUiStore';
import { MqttStore } from './MqttStore';
import KorToEn from '../../utils/KorToEn';
import { LikuStore } from '../ui/LikuStore';
import { InteractiveStore } from './InteractiveStore';
import { KidUiStore } from '../ui/KidUiStore';
import { InteractiveUiStore } from '../ui/interactive/InteractiveUiStore';

let repeatCount = -1;
const ContentsControlStore = observable({
	start: false,
	progress: false,
	loading: false,
	thisScene: -1,
	pictureState: false,
	letterStartIdxList: [],
	letterEndIdxList: [],
	backgroundStartIdxList: [],
	backgroundEndIdxList: [],
	ttsList: [],
	backDataIndex: 0,
	randomNumber: 0,
	backgroundListCount: 0,

	// isLoadComplete: false,

	clear() {
		repeatCount = -1;
		if (this.start || LikuStore.state === 'musical') this.contentsStop();
		// if (LikuStore.state === 'musical') this.contentsStop();
		// this.contentsStop();
		// else {
		this.setThisScene(-1);
		this.setStart(false);
		this.setProgress(false);
		this.setLoading(false);
		if (
			PlayerUiStore.contents?.title.includes('(코딩)') ||
			InteractiveStore.arucoColor !== ''
		)
			ContentsControlStore.setAruco(false);
		// }
	},

	async setCheckCondition(nextNode) {
		console.log('setCheckCondition', nextNode);
		// const nextNode = await InteractiveStore.checkCondition();
		if (nextNode?.next) {
			InteractiveStore.goNextByNodeId(nextNode.next);
			const nextIndex = InteractiveStore.getNodeIndexById(nextNode.next);
			console.log('nextIndex', nextIndex);
			this.thisScene = nextIndex;
			InteractiveStore.setNextNode(null);
		} else {
			InteractiveStore.clear();
			if (this.progress) {
				this.thisScene += 99999;
			}
		}

		this.contentsIng2();
	},

	async setThisScene(num) {
		if (num !== undefined) this.thisScene = num;
		else {
			if (PlayerUiStore.isInteractive() && this.thisScene !== -1) {
				console.log('1111111');
				InteractiveStore.conditionStart();
				return;
			} else {
				if (PlayerUiStore.isInteractive()) {
					InteractiveStore.getCurrentNode();
					// await InteractiveStore.conditionStart();
				}
				if (
					PlayerUiStore.contents?.title.includes('참여 수업') &&
					this.pictureState
				) {
				} else {
					if (PlayerUiStore.isBirthday() && this.thisScene > 0) {
						// console.log("thisScene", this.thisScene);
						// console.log("this.randomNumber", this.randomNumber);
						// console.log(
						//     "this.selectedPeople",
						//     MetaverseContentsUiStore.selectedBirthdayPeople
						// );
						let birthPeople = MetaverseContentsUiStore.selectedBirthdayPeople;

						if (
							this.backgroundStartIdxList?.length !== 0 &&
							this.thisScene === this.backgroundStartIdxList[0] - 1 - 1
						) {
							if (
								this.thisScene + 1 !==
								this.backgroundStartIdxList[birthPeople] - 1
							) {
								this.thisScene +=
									this.backgroundStartIdxList[birthPeople] -
									this.backgroundStartIdxList[0] +
									1;
							} else {
								this.thisScene += 1;
							}
						} else if (
							this.backgroundEndIdxList?.length !== 0 &&
							this.thisScene === this.backgroundEndIdxList[birthPeople] - 1
						) {
							if (
								this.backgroundEndIdxList[birthPeople] !==
								this.backgroundEndIdxList[this.backgroundEndIdxList.length - 1]
							) {
								this.thisScene +=
									this.backgroundEndIdxList[
										this.backgroundEndIdxList.length - 1
									] -
									this.backgroundEndIdxList[birthPeople] +
									1;
							} else {
								this.thisScene += 1;
							}
						} else if (
							this.letterStartIdxList?.length !== 0 &&
							this.thisScene === this.letterStartIdxList[0] - 1 - 1
						) {
							if (
								this.thisScene + 1 !==
								this.letterStartIdxList[this.randomNumber] - 1
							) {
								this.thisScene +=
									this.letterStartIdxList[this.randomNumber] -
									this.letterStartIdxList[0] +
									1;
							} else {
								this.thisScene += 1;
							}
						} else if (
							this.letterEndIdxList?.length !== 0 &&
							this.thisScene === this.letterEndIdxList[this.randomNumber] - 1
						) {
							if (
								this.letterEndIdxList[this.randomNumber] !==
								this.letterEndIdxList[this.letterEndIdxList.length - 1]
							) {
								this.thisScene +=
									this.letterEndIdxList[this.letterEndIdxList.length - 1] -
									this.letterEndIdxList[this.randomNumber] +
									1;
							} else {
								this.thisScene += 1;
							}
						} else {
							this.thisScene += 1;
						}
					} else {
						this.thisScene += 1;
					}
				}
			}
		}

		const isSceneEnd =
			this.thisScene > PlayerStore.contentsScenarioList?.length - 1;

		if (num === undefined && this.thisScene < 0) {
			this.thisScene = 0;
			return false;
		} else if (isSceneEnd) {
			this.thisScene = PlayerStore.contentsScenarioList[0]?.length - 1;
			return true;
		} else if (
			PlayerStore.contentsScenarioList?.length > 1 &&
			this.thisScene === PlayerStore.contentsScenarioList?.length - 1
		) {
			if (PlayerUiStore.contents?.title.includes('참여 수업')) {
				PlayerUiStore.setPictureModal(true);
				this.setPictureState(true);
			}
			return false;
		}
		return this.thisScene;
	},

	setStart(data) {
		this.start = data;
	},

	setProgress(value) {
		this.progress = value;
	},

	setLoading(value) {
		this.loading = value;
	},

	setPictureState(pictureState) {
		this.pictureState = pictureState;
	},

	setTtsList(ttsList) {
		this.ttsList = ttsList;
	},

	contentsStart() {
		if (PlayerUiStore.contents) {
			// console.log("contentsStart", this.progress);
			let likuRole =
				PlayerUiStore.contents?.musicalType !== 'MUSICAL'
					? [PlayerRoleUiStore.likuRole[0]]
					: PlayerRoleUiStore.likuRole;

			if (PlayerUiStore.contents?.title.includes('(코딩)'))
				this.setAruco(false);

			if (!this.start) {
				this.setStart(true);
				this.setLoading(true);
				if (PlayerUiStore.isMetaverse() || PlayerUiStore.isBirthday()) {
					MetaverseContentsUiStore.setIsReady(false);
					PlayerBackgroundUiStore.setFaceImageLoad();
				}
				PlayerStore.contentsStart(PlayerUiStore.title, likuRole).then(() => {
					// console.log("contentsStart");
					PlayerBackgroundUiStore.initBackground();

					this.musicalSubscribe();
					(() => {
						return new Promise((resolve, reject) => {
							const checkReady = () => {
								if (PlayerStore.uuid) {
									resolve();
								} else {
									setTimeout(checkReady, 100);
								}
							};
							checkReady();
						});
					})().then(() => PlayerLikuUiStore.setMusicalReady());
				});
			} else if (!this.progress) {
				this.contentsIng();
			}
			this.setProgress(true);
		}
	},

	contentsSkip(num, index, url, data, video, percent = 50) {
		// console.log("contentsSkip Call!!!");
		if (this.start && !this.loading) {
			PlayerLikuUiStore.likuAllStop();
			this.setLoading(true);
			setTimeout(() => {
				console.log('skip value');
				this.setThisScene(num);
				// console.log("thisScene", this.thisScene);
				if (this.progress) {
					PlayerBackgroundUiStore.setBackgroundSkip('image_load', index);
					this.contentsIng();
				} else {
					PlayerBackgroundUiStore.setBackgroundSkip('image_load', index);
				}
				this.setLoading(false);
			}, 1000);
		}
	},
	// 영상중간으로 스킵할 시 영상중간으로 이동하는 것을 구현 해보려했으나 현재 안되는 것을 확인했습니다.
	videoToPercentage(videoRef, percent) {
		const video = videoRef.current;
		if (!video) return;

		video.addEventListener('loadedmetadata', function () {
			const duration = video.duration;
			const time = (percent / 100) * duration;
			video.currentTime = time;
		});
	},

	musicalSubscribe() {
		const topic = `liku/musical/${PlayerUiStore.uuid}`;
		const checkTopic = `liku/musical/${PlayerUiStore.uuid}/check`;
		MqttStore.subscribe(topic);
		MqttStore.subscribe(checkTopic);
	},

	setAruco(isStart) {
		const topic = `liku/${LikuStore.thisLiku?.uuid}/robot/aruco`;
		// const topic = `liku/${PlayerUiStore.uuid}/robot/aruco`;
		let message;

		// if (isStart) message = {aruco: {... PlayerStore.aruco}};
		if (isStart) {
			message = { ...PlayerStore.aruco };
			console.log('@#@#message', message);
		} else {
			message = 'stop';
		}

		MqttStore.publish(topic, message);
	},

	contentsPause() {
		if (this.progress) {
			PlayerLikuUiStore.likuAllStop();
			this.setThisScene(this.thisScene - 1);
			PlayerBackgroundUiStore.setVideoState(false);
			PlayerBackgroundUiStore.setPlayMusic(false);
			// PlayerBackgroundUiStore.initBackground();
			this.setProgress(false);
		}
		if (PlayerUiStore.contents?.title.includes('(코딩)')) this.setAruco(true);
	},

	contentsMove(type) {
		if (this.start && !this.loading) {
			PlayerLikuUiStore.likuAllStop();
			this.setLoading(true);
			setTimeout(async () => {
				if (type === 'rewind') this.setThisScene(this.thisScene - 2);
				else if (type === 'reset') this.setThisScene(-1);
				else if (type === 'fastplay' && !this.progress)
					this.setThisScene(this.thisScene + 2);

				if (this.progress) this.contentsIng();
				else {
					await PlayerBackgroundUiStore.setBackground('image_load');
					await PlayerBackgroundUiStore.setBackground('music_load');
				}

				this.setLoading(false);
			}, 1000);
		}
	},

	contentsStop() {
		this.contentsPause();
		if (
			PlayerUiStore.contents?.title.includes('(코딩)') ||
			InteractiveStore.arucoColor !== ''
		)
			ContentsControlStore.setAruco(false);
		let topic = `liku/musical/${PlayerUiStore.uuid}`;
		MqttStore.unSubscribe(topic);
		let likuRole =
			PlayerUiStore.contents?.musicalType !== 'MUSICAL'
				? [PlayerRoleUiStore.likuRole[0]]
				: PlayerRoleUiStore.likuRole;
		// PlayerStore.contentsStop(this.title, likuRole).then((allData) => {
		PlayerStore.contentsStop(PlayerUiStore.title, likuRole).then(() => {
			console.log('stop');

			PlayerLikuUiStore.resetRole();
			this.setStart(false);
			this.setLoading(false);
			this.setThisScene(-1);
		});
	},

	musicalStop() {
		const topic = `liku/${LikuStore.thisLiku?.uuid}/musical/stop`;
		const message = 'stop';
		MqttStore.publish(topic, message);
	},

	async contentsIng() {
		// console.log("contentsIng Call!!!");
		this.setProgress(true);
		let scene = await this.setThisScene();
		console.log('scene', scene);
		let pubData;
		if (scene !== true) {
			// do {
			// if (PlayerUiStore.isInteractive()) {
			// 	pubData = [PlayerStore.contentsScenarioList[this.thisScene]];
			// } else {
			// 	pubData = PlayerStore.contentsScenarioList[this.thisScene];
			// }
			//
			// // console.log("pubData", pubData, "thisScene", this.thisScene);
			//
			// await PlayerBackgroundUiStore.setConnectionImageLoad();
			// await PlayerBackgroundUiStore.setBackground('image');
			// await PlayerBackgroundUiStore.setBackground('music');
			//
			// // } while (pubData.length === 0);
			// (() => {
			// 	return new Promise((resolve, reject) => {
			// 		if (!PlayerBackgroundUiStore.connectionImageLoad) {
			// 			resolve();
			// 		}
			// 	});
			// })().then(async () => {
			// 	// 역할 값을 통해 다음 값 초기화
			// 	PlayerLikuUiStore.initIsNexts(pubData);
			// 	for (let i in pubData) {
			// 		await this.contentsPublish(pubData[i]);
			// 	}
			// });

			const isSceneEnd =
				this.thisScene > PlayerStore.contentsScenarioList?.length - 1;

			console.log('222222', isSceneEnd);
			const isInteractive = PlayerUiStore.isInteractive();
			console.log('333333', isInteractive);
			console.log('444444', isInteractive && this.thisScene === 0);
			if (!isInteractive || (isInteractive && this.thisScene === 0)) {
				if (isInteractive) {
					pubData = [PlayerStore.contentsScenarioList[this.thisScene]];
				} else {
					pubData = PlayerStore.contentsScenarioList[this.thisScene];
				}

				// console.log("pubData", pubData, "thisScene", this.thisScene);

				await PlayerBackgroundUiStore.setConnectionImageLoad();
				await PlayerBackgroundUiStore.setBackground('image');
				await PlayerBackgroundUiStore.setBackground('music');

				// } while (pubData.length === 0);
				(() => {
					return new Promise((resolve, reject) => {
						if (!PlayerBackgroundUiStore.connectionImageLoad) {
							resolve();
						}
					});
				})().then(async () => {
					// 역할 값을 통해 다음 값 초기화
					PlayerLikuUiStore.initIsNexts(pubData);
					for (let i in pubData) {
						await this.contentsPublish(pubData[i]);
					}
				});
			} else {
				console.log('else');
			}
		} else {
			await this.contentsStop();
		}
	},

	async contentsIng2() {
		console.log('contentsIng2');
		const isSceneEnd =
			this.thisScene > PlayerStore.contentsScenarioList?.length - 1;

		if (!isSceneEnd && PlayerUiStore.isInteractive()) {
			let pubData = [PlayerStore.contentsScenarioList[this.thisScene]];
			await PlayerBackgroundUiStore.setConnectionImageLoad();
			await PlayerBackgroundUiStore.setBackground('image');
			await PlayerBackgroundUiStore.setBackground('music');

			// } while (pubData.length === 0);
			(() => {
				return new Promise((resolve, reject) => {
					if (!PlayerBackgroundUiStore.connectionImageLoad) {
						resolve();
					}
				});
			})().then(async () => {
				// 역할 값을 통해 다음 값 초기화
				PlayerLikuUiStore.initIsNexts(pubData);
				for (let i in pubData) {
					await this.contentsPublish(pubData[i]);
				}
			});
		} else {
			await this.contentsStop();
		}
	},

	async contentsPublish(data) {
		console.log('data', JSON.stringify(data));
		let sendData = await this.ServerDataToSendData(data);
		let topic;
		if (PlayerUiStore.contents?.musicalType !== 'MUSICAL') {
			let roleTopic = KorToEn(PlayerRoleUiStore.likuRole[0][1]);
			topic = `liku/musical/${PlayerUiStore.uuid}/${roleTopic}`;
		} else {
			topic = `liku/musical/${PlayerUiStore.uuid}/${sendData.role}`;
		}
		// await this.contentsPublish(topic, sendData);

		await MqttStore.publish(topic, sendData.pubData);
	},

	async ServerDataToSendData(pubData) {
		let copyPubData = JSON.parse(JSON.stringify(pubData));
		let sendData = {};
		sendData.role = KorToEn(copyPubData.role);
		let data = copyPubData.motion.display.display_name.split('_');
		if (data.length > 1) {
			let index_ = 0;
			let display_color = '';
			let display_name_temp = '';
			for (let splitted_data of data) {
				if (index_ === 0) {
					display_color = splitted_data;
				} else {
					if (index_ === 1) {
						display_name_temp = data[index_];
					} else {
						let splitted_temp = [display_name_temp, data[index_]];
						display_name_temp = splitted_temp.join('_');
					}
				}
				index_++;
			}
			// copyPubData.motion.display.display_name = `${data[0]}_${data[1]}`;
			if (display_color !== undefined) {
				copyPubData.motion.display.display_name = `${display_color}_${display_name_temp}`;
			}
		} else if (PlayerRoleUiStore.roleColor[copyPubData.role] !== undefined && PlayerRoleUiStore.roleColor[copyPubData.role] !== 'yellow') {
			copyPubData.motion.display.display_name = `${
				PlayerRoleUiStore.roleColor[copyPubData.role]
			}_${copyPubData.motion.display.display_name}`;
		}

		// if (copyPubData.motion.display.display_name.includes('undefined') && !copyPubData.role) {
		// 	// copyPubData.motion.display.display_name = display_name_temp;
		// }

		let tempTTS = copyPubData.motion.speech.TTS;
		if (tempTTS.includes('[month]')) {
			copyPubData.motion.speech.TTS = this.ttsList[this.thisScene];
			// const newString = tempTTS.replace(
			//     "[month]",
			//     KidUiStore.dateMonth + 1
			// );
			// copyPubData.motion.speech.TTS = newString;
		} else if (tempTTS.includes('[names]')) {
			copyPubData.motion.speech.TTS = this.ttsList[this.thisScene];
			// const newString = tempTTS.replace(
			//     "[names]",
			//     KidUiStore.kidsListString
			// );
			// copyPubData.motion.speech.TTS = newString;
		} else if (tempTTS.includes('!-^-!')) {
			this.backgroundListCount += 1;
			const extracted = tempTTS.split(' ');
			const removeLeftParentheses = extracted[1].replace('(', '');
			const removeRightParentheses = removeLeftParentheses.replace(')', '');
			const NumberList = removeRightParentheses.split('|');
			this.backgroundStartIdxList = [];
			this.backgroundEndIdxList = [];
			// console.log("NumberList", NumberList);
			for (let i = 0; i < NumberList?.length; i++) {
				let tempList = NumberList[i].split(',');
				this.backgroundStartIdxList.push(parseInt(tempList[0]));
				this.backgroundEndIdxList.push(parseInt(tempList[1]));
			}
			// console.log("backgroundStartIdxList", this.backgroundStartIdxList);
			// console.log("backgroundEndIdxList", this.backgroundEndIdxList);
		} else if (tempTTS.includes('!^~^!')) {
			const extracted = tempTTS.split(' ');
			// console.log("extracted", extracted);
			const removeLeftParenthesesTwo = extracted[1].replace('(', '');
			const removeRightParenthesesTwo = removeLeftParenthesesTwo.replace(
				')',
				''
			);
			const NumberListTwo = removeRightParenthesesTwo?.split('|');
			for (let i = 0; i < NumberListTwo?.length; i++) {
				let tempListTwo = NumberListTwo[i].split(',');
				this.letterStartIdxList.push(parseInt(tempListTwo[0]));
				this.letterEndIdxList.push(parseInt(tempListTwo[1]));
			}
			// 랜덤하게 숫자 추출하기
			const maxVal = NumberListTwo.length - 1;
			const MinVal = 0;
			this.randomNumber = Math.floor(
				Math.random() * (maxVal - MinVal + 1) + MinVal
			);
			console.log(
				'randomNumber  $$$$!!!!!%%^^^^^^^^',
				ContentsControlStore.randomNumber
			);
		}
		sendData.pubData = copyPubData.motion;
		// console.log(sendData.pubData);
		return sendData;
	},
});

export { ContentsControlStore };
