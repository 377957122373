import { observable, runInAction, toJS } from 'mobx';
import { QuickMenuControlStore } from '../../domain/quickmenu/QuickMenuControlStore';
import { QuickMenuControlMyExpressionStore } from '../../domain/quickmenu/QuickMenuControlMyExpressionStore';
import { LikuStore } from '../LikuStore';
import SleepJson from '../../../resource/json/control/expression/Sleep.json';
import WakeUpJson from '../../../resource/json/control/expression/WakeUp.json';
import { PlayAndEduMusicUiStore } from '../playandedu/PlayAndEduMusicUiStore';
import { QuickMenuControlMotionStore } from '../../domain/quickmenu/QuickMenuControlMotionStore';
import { QuickMenuControlScenarioStore } from '../../domain/quickmenu/QuickMenuControlScenarioStore';

const QuickMenuControlUiStore = observable({
	defaultMotionData: null,

	defaultFunctionList: null,
	entertainmentList: null,
	walkingList: null,

	isQuickMenuVisible: false,

	status: false,
	executionList: null,
	thisExecution: -1,
	endExecution: 0,

	isSpeaking: false,

	isSleepModalVisible: false,
	isMusicModalVisible: false,
	isScenarioSaveModalVisible: false,

	clear() {
		if (
			!(PlayAndEduMusicUiStore.status || PlayAndEduMusicUiStore.isMusicPlay)
		) {
			if (this.thisExecution !== -1) {
				// this.thisExecution !== -1 조작중
				if (
					window.confirm(
						'리쿠 표현이 실행되고 있습니다. 종료하고 이동하시겠습니까?'
					)
				) {
					this.executionStop();
					this.initExecutionList();
					return true;
				} else {
					return false;
				}
			} else {
				this.initExecutionList();
				return true;
			}
		} else {
			this.initExecutionList();
			return true;
		}
		// this.setIsQuickMenuVisible(false);
	},

	// setIsQuickMenuVisible(value) {
	//   this.isQuickMenuVisible = value;
	// },

	initExecutionList() {
		runInAction(() => {
			this.executionList = [];
			for (let i = 0; i < 10; i++) {
				this.executionList.push(null);
			}
		});
	},

	addExecutionList(execution) {
		console.log('execution', execution);
		console.log('this.executionList', this.executionList);
		const index = this.executionList.findIndex((value) => {
			return value === null || (value !== null && value?.json?.listen);
			// return value === null || value?.id === 75
		});

		if (execution?.json?.listen && this.executionList[index]?.json?.listen) {
			// if (execution?.id === 75 && this.executionList?.[index]?.id === 75) {
			return;
		}

		if (execution !== undefined) {
			const tempExecutionList = [...this.executionList];
			if (index !== -1) {
				console.log('if', tempExecutionList[index]);
				if (
					tempExecutionList[index] !== null &&
					tempExecutionList[index]?.json?.listen
				) {
					// if (tempExecutionList[index]?.id === 75) {
					tempExecutionList.splice(index, 0, execution);
				} else {
					// console.log('exectution', execution);
					// if (execution?.id == 75) {
					//   execution.json.listen = true;
					// }
					tempExecutionList[index] = execution;
				}
			}

			// else {
			//   console.log('else');
			//   return;
			//   tempExecutionList.push(execution);
			// }
			this.executionList = [...tempExecutionList];
		}
	},

	deleteExecutionList(index) {
		this.executionList.splice(index, 1);
		this.executionList.push(null);
	},

	scenarioExecution(scenario) {
		// this.initExecutionList();
		this.addExecutionList(scenario);
		// scenario?.json?.forEach((execution) => {
		//   this.addExecutionList(execution);
		// });
	},

	setStatus(value) {
		this.status = value;
	},

	setIsSpeaking(value) {
		this.isSpeaking = value;
	},

	startSpeaking(speaking) {
		console.log('!LikuStore.stateIng', !LikuStore.stateIng);
		if (LikuStore.state !== 'active') {
			this.setIsSleepModalVisible();
		} else if (!LikuStore.stateIng) {
			if (PlayAndEduMusicUiStore.status || PlayAndEduMusicUiStore.isMusicPlay)
				this.setIsMusicModalVisible();
			else {
				this.setIsSpeaking(true);
				this.setStatus(true);
				let sendJson = JSON.parse(
					JSON.stringify(QuickMenuControlStore.defaultMotionData)
				);
				// delete sendJson.action;
				sendJson.speech.TTS = speaking;

				const randomAction = this.defaultActionRandom();
				sendJson.action = randomAction;

				QuickMenuControlStore.reactionPub(sendJson);
			}
		}
	},

	myExpression(execution) {
		let sendJson = JSON.parse(JSON.stringify(execution));
		// sendJson.display.display_name = execution.json.display;
		// sendJson.speech.TTS = execution.json.tts;

		if (execution.action?.includes('Array')) {
			if (
				QuickMenuControlMotionStore.actions[sendJson.json.action.action_name]
					.action?.length > 0
			) {
				const randomAction = this.defaultActionRandom();
				sendJson.json.action.action_name = randomAction;
			} else {
				sendJson.action.action_name = execution.action;
			}
		}
		QuickMenuControlStore.reactionPub(sendJson.json);
	},

	setThisExecution(num) {
		if (num === undefined) this.thisExecution++;
		// else this.thisExecution = -1;
		else this.thisExecution = num;
	},

	setEndExecution(index) {
		if (index === undefined) this.endExecution = 0;
		else this.endExecution = index;
	},

	async executionStart() {
		await this.scenarioMappingToExecution();
		LikuStore.setLikuState('active');
		if (LikuStore.state !== 'active') {
			this.setIsSleepModalVisible();
		} else if (!LikuStore.stateIng) {
			if (PlayAndEduMusicUiStore.status || PlayAndEduMusicUiStore.isMusicPlay)
				this.setIsMusicModalVisible();
			else {
				this.setStatus(true);
				const index = this.executionList.findIndex((value) => value === null);
				this.setEndExecution(index);
				this.setThisExecution();
				this.executionIng();
			}
		}
	},

	async scenarioMappingToExecution() {
		const tempList = [];
		for (const item of this.executionList) {
			if (item?.order) {
				const fetchData = async () => {
					const data = await QuickMenuControlScenarioStore.getScenarioById(
						item.id
					);
					console.log(data);
					const motionData = data.motions?.map((item) => {
						return QuickMenuControlMotionStore.motions?.filter(
							(motionItem) => item.id === motionItem.id
						);
					});
					motionData.map((motionItem, index) => {
						tempList.push(toJS(motionItem[0]));
					});
				};
				await fetchData();
			} else {
				tempList.push(item);
			}
		}
		this.executionList = [...tempList];
	},

	executionIng() {
		if (!this.status) this.setStatus(true);
		if (this.thisExecution !== -1 && this.endExecution !== this.thisExecution) {
			const execution = this.executionList[this.thisExecution];
			if (execution !== null && execution !== undefined) {
				if (execution?.type === 'reaction') {
					QuickMenuControlStore.reactionPub(execution?.json);
				} else if (execution?.type === 'expression') {
					QuickMenuControlStore.expressionPub(execution?.json);
				} else {
					if (execution.json.length > 0) {
						QuickMenuControlStore.reactionListPub(execution.json);
					} else {
						switch (execution.json.action.action_name) {
							case 'SelfStandUp':
								QuickMenuControlStore.expressionPub({
									exp: [['SelfStandUp']],
									listen: false,
								});
								break;
							case 'SelfSitDown':
								QuickMenuControlStore.expressionPub({
									exp: [['SelfSitDown']],
									listen: false,
								});
								break;
							case 'dance_likusong':
								QuickMenuControlStore.expressionPub({
									exp: [['Dance']],
									listen: false,
								});
								break;
							case 'ApproachApp':
								QuickMenuControlStore.expressionPub({
									exp: [['ApproachApp']],
									listen: false,
								});
								break;
							case 'RecedeApp':
								QuickMenuControlStore.expressionPub({
									exp: [['RecedeApp']],
									listen: false,
								});
								break;
							default:
								this.myExpression(execution);
								break;
						}
					}
				}
			} else {
				this.executionStop();
			}
		} else {
			this.executionStop();
		}
	},

	executionDone() {
		// this.deleteExecutionList();
		// if (this.thisExecution !== -1) {
		this.setStatus(false);
		this.setThisExecution();
		this.executionIng();
		// }
	},

	executionStop() {
		console.log('stop');
		// QuickMenuControlStore.stopPub();
		this.setStatus(false);
		this.setThisExecution(-1);
		this.setEndExecution();
		if (!this.isSpeaking) this.initExecutionList();
		this.setIsSpeaking(false);
	},

	defaultActionRandom() {
		const range =
			QuickMenuControlMotionStore.actions.defaultArray.action.length;
		const randomNum = Math.floor(Math.random() * range);

		return QuickMenuControlMotionStore.actions.defaultArray.action[randomNum];
	},

	setLikuState(type) {
		let message = null;
		if (PlayAndEduMusicUiStore.status || PlayAndEduMusicUiStore.isMusicPlay)
			this.setIsMusicModalVisible();
		else if (type !== LikuStore.state) {
			LikuStore.setStateIng(true);
			if (type === 'active') message = WakeUpJson;
			else message = SleepJson;
			LikuStore.setStateTopic(message);
		} else {
			this.setIsSleepModalVisible();
		}
	},

	setLikuVolume(volume) {
		LikuStore.setVolumeTopic(volume);
	},

	setIsSleepModalVisible() {
		this.isSleepModalVisible = true;
		setTimeout(() => {
			runInAction(() => {
				this.isSleepModalVisible = false;
			});
		}, 1500);
	},

	setIsMusicModalVisible() {
		this.isMusicModalVisible = true;
		setTimeout(() => {
			runInAction(() => {
				this.isMusicModalVisible = false;
			});
		}, 1500);
	},

	setIsScenarioModalVisible(_boolean) {
		this.isScenarioSaveModalVisible = _boolean;
	},
});

export { QuickMenuControlUiStore };
