import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { defaultTheme } from "./Theme";

const GlobalStyled = createGlobalStyle`
  ${reset};

  html {
    width: 100%;
    height: 100%;
    font-size: 62.5%;

  }
  
  header {
    background-color: #ffffff;
  }
  
  body {
    font-family: Helvetica-Regular;
    font-weight: ${defaultTheme.fonts.weight.normal};
    font-size: ${defaultTheme.fonts.size.base};
    background-color: ${defaultTheme.color.background};
  }
`;

export default GlobalStyled;
