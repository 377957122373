import { observable, runInAction } from "mobx";
import { InteractiveStore } from "../../domain/InteractiveStore";

const InteractiveUiStore = observable({
  button: null,
  components: [],

  clear() {
    runInAction(() => {
      this.button = null;
      this.components = [];
    });
  },

  setButton(_button) {
    runInAction(() => {
      console.log("@#@#buttons", _button);
      this.button = _button;
    });
  },

  setComponents(_components) {
    runInAction(() => {
      console.log("@#@#component", _components);
      this.components = _components;
    });
  },

  getCurrentNodeButtons() {
    const currentNode = InteractiveStore.getCurrentNode();
  },
});

export { InteractiveUiStore };
