import { observable, reaction, runInAction } from 'mobx';
import { ContentsControlStore } from './ContentsControlStore';
import { InteractiveUiStore } from '../ui/interactive/InteractiveUiStore';
import { ServerStore } from './ServerStore';

const InteractiveStore = observable({
	nodes: [],
	edges: [],
	currentId: -1,
	condition: { type: '', value: '' },
	listenText: '',
	arucoColor: '',
	nextNode: null,

	getCurrentNode() {
		const currentNode = this.nodes.find((node) => {
			return node.id === this.currentId.toString();
		});

		if (currentNode) {
			return currentNode;
		} else if (this.nodes.length) {
			runInAction(() => {
				this.currentId = this.nodes[0].id;
			});
			return this.nodes[0];
		}
	},

	setCurrentId(_id) {
		runInAction(() => {
			this.currentId = _id;
		});
	},

	getNextNodes() {
		const currentNode = this.getCurrentNode();
		let nextPool = [];
		this.edges.map((edge) => {
			if (edge.source === currentNode.id) {
				nextPool.push({ next: edge.target, condition: { ...edge.data } });
			}
		});
		return nextPool;
	},

	setNodes(_nodes) {
		runInAction(() => {
			this.nodes = _nodes;
		});
	},

	setEdges(_edges) {
		runInAction(() => {
			this.edges = _edges;
		});
	},

	setCondition({ type, value }) {
		runInAction(() => {
			this.condition = { type, value };
		});
	},

	areSetAndArrayEquivalent(set1, arr2) {
		if (set1.size !== arr2.length) return false;

		for (let item of set1) {
			if (!arr2.includes(item)) return false;
		}
		return true;
	},

	hasSound(nextPool) {
		return nextPool.find((next) => {
			console.log(next);
			return next.condition.type === 'Sound';
		});
	},

	hasArucoSetting(nextPool) {
		return nextPool.find((next) => {
			console.log(next);
			return next.condition.type?.includes('aruco_setting');
		});
	},

	hasAruco(nextPool) {
		return nextPool.find((next) => {
			console.log(next);
			return next.condition.type?.includes('aruco_color');
		});
	},

	hasButton(nextPool) {
		return nextPool.find((next) => {
			console.log(next);
			return next.condition.type?.includes('Button');
		});
	},

	hasConditional(nextPool) {
		return nextPool.find((next) => {
			console.log(next);
			return next.condition.type?.includes('Conditional');
		});
	},

	hasRandom(nextPool) {
		return nextPool.find((next) => {
			console.log(next);
			return (
				next.condition.type?.includes('Conditional') &&
				next.condition.value?.includes('random')
			);
		});
	},

	setNextNode(node) {
		this.nextNode = node;
	},

	conditionStart() {
		const nextPool = this.getNextNodes();
		console.log('nextPool', nextPool);
		if (!nextPool.length) return;
		if (nextPool.length === 1 && !nextPool[0].condition?.value) {
			this.setNextNode(nextPool[0]);
			// return nextPool[0];
		}
		if (this.hasSound(nextPool)) {
			// @#@#Listen 리쿠 듣기 시작
			console.log('@#@#listen 듣기 시작');
			console.log('@#@#listen 듣기 시작11111');
			// let count = 0;
			// const MAXCOUNT = 10;
			// this.setCondition({ type: '', value: '' });
			// const intervalId = setInterval(() => {
			// 	if (this.nextNode) {
			// 		clearInterval(intervalId);
			// 	}
			// 	if (count >= MAXCOUNT && nextPool.length) {
			// 		if (
			// 			this.condition.type === 'Sound' &&
			// 			this.condition.value.includes('?')
			// 		) {
			// 			console.log('@#@#listen 잘못들었어');
			// 		}
			// 		this.setNextNode(nextPool[0]);
			// 		clearInterval(intervalId); // BASE_URL이 설정되면 interval을 중단
			// 	}
			// 	count++;
			// }, 1000);
		}
		console.log('@#@#arucoSetting', this.hasArucoSetting(nextPool));
		if (this.hasArucoSetting(nextPool)) {
			console.log('@#@#aruco 모드 시작');
			ContentsControlStore.setAruco(true);
			this.setNextNode(nextPool[0]);
		}

		if (this.hasRandom(nextPool)) {
			console.log('Is Random!!!!');
			const randomNodeList = nextPool.filter((node) => {
				return (
					node.condition.type?.includes('Conditional') &&
					node.condition.value?.includes('random')
				);
			});
			const randomNode = Math.floor(Math.random() * randomNodeList.length);
			this.setNextNode(randomNodeList[randomNode]);
		}
	},

	checkConditionSound(nextPool) {
		let andPool = new Set();

		// if (this.hasSound(nextPool)) {
		if (this.condition.type !== '') {
			nextPool.map((next, index) => {
				console.log('@#@#listen', next.condition.value, this.condition?.value);
				const isAndCondition = next.condition.value.includes('&');
				const isOrCondition = next.condition.value.includes('|');
				const orPool = next.condition.value.split('|');

				if (isAndCondition && andPool.includes(this.condition.value)) {
					andPool = [...andPool, this.condition.value];
					console.log('@#@#andPool', andPool);
					if (
						this.areSetAndArrayEquivalent(
							andPool,
							next.condition.value.split('&')
						)
					) {
						// nextNode = next;
						this.setNextNode(next);
					}
				}
				console.log(this.condition.value);
				if (isOrCondition && orPool.includes(this.condition.value)) {
					// nextNode = next;
					this.setNextNode(next);
				}

				// 현재 내가 고른 condition (this.condition)과 다음으로 갈 분기의 condition을 비교해 만족하는 condition이 있다면 해당 conditoin을 NextNode로 설정
				if (
					next.condition.type === this.condition.type &&
					next.condition.value === this.condition.value
				) {
					// nextNode = next;
					this.setNextNode(next);
				} else if (this.condition.value == '?') {
					let message = {
						motion: {
							action: {
								action_name: 'None',
								action_type: 'overlap',
								pitch: 0,
								yaw: 0,
							},
							display: {
								display_name: 'None',
								delay: 0,
								playtime: 1,
								playspeed: 1,
							},
							speech: {
								// speech_name: "emptyp01.mp3",
								speech_name: 'TTS_output',
								TTS: '다시 말해줄래?',
								delay: 0,
								repeat: 1,
							},
							listen: 'transcript',
						},
					};
					ContentsControlStore.contentsPublish(message);
					this.setCondition({ type: '', value: '' });
				}
			});
			if (!this.nextNode && this.condition.value != '') {
				const failNext = nextPool.find(
					(next) => next.condition.value === '오답'
				);
				this.setNextNode(failNext);
			}
		}
		// }
	},

	checkListsEquality(list1, list2) {
		if (list1.length !== list2.length) {
			return false; // 두 리스트의 길이가 다르면 false를 반환합니다.
		}

		let mismatchCount = 0;

		for (let i = 0; i < list1.length; i++) {
			if (list1[i] !== list2[i]) {
				mismatchCount++;

				// 값이 다른 요소가 2개 이상인 경우 false를 반환합니다.
				if (mismatchCount > 1) {
					return false;
				}
			}
		}

		// 값이 다른 요소가 1개인 경우 true를 반환합니다.
		return true;
	},

	checkConditionAruco(nextPool) {
		// nextPool.map((next, index) => {
		for (let next of nextPool) {
			console.log('@#@#aruco', next.condition.value, this.condition?.value);
			const nextConditionValue = next.condition.value.replaceAll(' ', '');
			const notNextConditionValue1 = nextConditionValue.replaceAll('!', '');
			const notNextConditionValue2 = notNextConditionValue1.replaceAll('(', '');
			const notNextConditionValue3 = notNextConditionValue2.replaceAll(')', '');
			const conditionValue = notNextConditionValue3.split(',');

			// const likuConditionValue = Object.values(this.condition.value).join(',');

			const likuConditionValue = Object.values(this.condition.value);
			console.log('conditionValue', conditionValue);
			console.log('likuConditionValue', likuConditionValue);

			console.log('next', next);
			const result = this.checkListsEquality(
				conditionValue,
				likuConditionValue
			);

			console.log('result', result);
			const pattern = /^\!\([^()]+\)$/; // ^!\\([^()]+\\)$
			if (next.condition.type === this.condition.type && result) {
				// nextNode = next;
				this.setNextNode(next);
				this.setArucoColor('');
				break;
			} else if (
				next.condition.type === this.condition.type &&
				pattern.test(nextConditionValue) &&
				!result
			) {
				// nextNode = next;
				this.setNextNode(next);
				this.setArucoColor('');
				break;
			}
		}
	},

	checkConditionButton(nextPool) {
		for (let next of nextPool) {
			if (next.condition.type === this.condition.type) {
				// nextNode = next;
				this.setNextNode(next);
				break;
			}
		}
	},

	checkConditionRandom(nextPool) {
		for (let next of nextPool) {
			if (next.condition.type === this.condition.type) {
				// nextNode = next;
				this.setNextNode(next);
				break;
			}
		}
	},

	goNextByNodeId(_id) {
		runInAction(() => {
			this.currentId = _id;
		});
	},

	getNodeIndexById(_id) {
		let currentIndex = 0;
		this.nodes.map((node, index) => {
			if (node.id === _id) {
				currentIndex = index;
			}
		});
		return currentIndex;
	},

	getListenText() {
		return this.listenText;
	},

	setListenText(_text) {
		runInAction(() => {
			this.listenText = _text;
			console.log('@#@#listen', _text);
			this.setCondition({ type: 'Sound', value: _text });
		});
	},

	getArucoColor() {
		return this.arucoColor;
	},

	setArucoColor(color) {
		this.arucoColor = Object.values(color).toString();
		this.setCondition({ type: 'aruco_color', value: color });
	},

	clear() {
		runInAction(() => {
			this.currentId = -1;
			this.condition = { type: '', value: '' };
			this.listenText = '';
			this.arucoColor = '';
			this.nextNode = null;
		});
	},
});

reaction(
	() => InteractiveStore.currentId,
	(currentId) => {
		const currentNode = InteractiveStore.getCurrentNode();
		const curNodeBackground = currentNode?.data?.background;
		console.log(curNodeBackground);
		const curNodeBackgroundButton = curNodeBackground?.button;
		const curNodeBackgroundComponent = curNodeBackground?.component;
		if (curNodeBackgroundButton?.apply) {
			InteractiveUiStore.setButton(curNodeBackgroundButton);
		} else {
			InteractiveUiStore.clear();
		}

		InteractiveUiStore.setComponents(curNodeBackgroundComponent);
	}
);

reaction(
	() => InteractiveStore.condition,
	() => {
		const nextPool = InteractiveStore.getNextNodes();

		if (InteractiveStore.hasSound(nextPool)) {
			InteractiveStore.checkConditionSound(nextPool);
		} else if (InteractiveStore.hasAruco(nextPool)) {
			InteractiveStore.checkConditionAruco(nextPool);
		} else if (InteractiveStore.hasButton(nextPool)) {
			InteractiveStore.checkConditionButton(nextPool);
		} else if (InteractiveStore.hasConditional(nextPool)) {
			InteractiveStore.checkConditionButton(nextPool);
		}
	}
);

reaction(
	() => InteractiveStore.nextNode,
	(nextNode) => {
		if (nextNode) {
			ContentsControlStore.setCheckCondition(nextNode);
		}
	}
);

export { InteractiveStore };
