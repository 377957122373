import {observable} from "mobx";
import {MetaverseContentsStore} from "../../domain/MetaverseContentsStore";
import {MetaverseStore} from "../../domain/MetaverseStore";
import {MetaverseContentsUiStore} from "./MetaverseContentsUiStore";


// 처리과정이 단순할 때는 ui store의 여기 MetaverseUiStore에서 처리
const MetaverseUiStore = observable({
  faceImages: null,

  async init() {
    if(MetaverseContentsStore.faceImageList === null) {
      await MetaverseContentsUiStore.init();
    }
    await this.getFaceImages();
  },

  async getFaceImages() {
    // let faceImages = MetaverseContentsUiStore.getFaceImages();
    await MetaverseStore.getFaceImages();
  },

  setFaceImages(faceImages) {
    this.faceImages = faceImages;
  },

});

export {MetaverseUiStore};
