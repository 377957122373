const fonts = {
    // family: {
    //     menu: `@font-face { font-family: 'CookieRun Bold'; src: url(./fonts/CookieRunBold.ttf) format('truetype'); }`,
    //     base: `'Noto Sans KR', sans-serif`,
    //     title: `'Merriweather', serif`,
    // },
    size: {
        minimini: "1.3rem",
        mini: "1.4rem",
        sm: "1.5rem",
        base: "1.6rem",
        lg: "1.8rem",
        xl: "2rem",
        xxl: "2.5rem",
        title: "6rem",
    },
    weight: {
        light: 400,
        normal: 500,
        bold: 600,
    },
};

const size = {
    // smallestDesktop: "250px",
    mobile: "410px",
    largeMobile: "530px",
    smallTablet: "640px",
    tablet: "768px",
    mediumTablet: "890px",
    // largeTablet: "920px",
    largeTablet: "1024px",
    smallDesktop: "1280px",
    mediumDesktop: "1450px",
    desktop: "1620px",
    mediumLargeDesktop: "1835px",
    largeDesktop: "2048px",
    largeBigDesktop: "2200px",
    bigDesktop: "2568px",
    biggerDesktop: "2880px",
    evenBiggerDesktop: "3200px",
};

const color = {
    background: '#f7f7f5',
    mainColor: '#72acff',
    subTitle: '#707070',
    placeholder: '#e0e0e0',
    border: '#e2e2e2',
    darkGray: '#929292',
    activation: '#5e9bf5',
}

const device = {
    // smallestDesktop: `@media only screen and (min-width: ${size.smallestDesktop})`,
    mobile: `@media only screen and (min-width: ${size.mobile})`,
    largeMobile: `@media only screen and (min-width: ${size.largeMobile})`,
    smallTablet: `@media only screen and (min-width: ${size.smallTablet})`,
    tablet: `@media only screen and (min-width: ${size.tablet})`,
    mediumTablet: `@media only screen and (min-width: ${size.mediumTablet})`,
    largeTablet: `@media only screen and (min-width: ${size.largeTablet})`,
    smallDesktop: `@media only screen and (min-width: ${size.smallDesktop})`,
    mediumDesktop: `@media only screen and (min-width: ${size.mediumDesktop})`,
    desktop: `@media only screen and (min-width: ${size.desktop})`,
    mediumLargeDesktop: `@media only screen and (min-width: ${size.mediumLargeDesktop})`,
    largeDesktop: `@media only screen and (min-width: ${size.largeDesktop})`,
    largeBigDesktop: `@media only screen and (min-width: ${size.largeBigDesktop})`,
    bigDesktop: `@media only screen and (min-width: ${size.bigDesktop})`,
    biggerDesktop: `@media only screen and (min-width: ${size.biggerDesktop})`,
    evenBiggerDesktop: `@media only screen and (min-width: ${size.evenBiggerDesktop})`,
};


const colourOptions = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
    { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
  ];
export const defaultTheme = {
    fonts,
    device,
    colourOptions,
    color,
};
