import React, { Suspense, lazy, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import RootConfig from "./config/RootConfig";

import HeaderContainer from "./view/container/header/HeaderContainer";
import PublicRouter from "./router/PublicRouter";
import PrivateRouter from "./router/PrivateRouter";

import { defaultTheme } from "./view/styled/Theme";
import GlobalStyled from "./view/styled/GlobalStyled";
import "antd/dist/antd.css";
import "./view/styled/GlobalFonts.css";
import { observer } from "mobx-react";
import { render } from "react-dom";
import EduRouter from './router/EduRouter';
// import GuidePage from './view/page/GuidePage';

const MainPage = lazy(() => import("./view/page/MainPage"));

const LoginPage = lazy(() => import("./view/page/LoginPage"));
const PasswordPage = lazy(() => import("./view/page/PasswordPage"));
const SignupPage = lazy(() => import("./view/page/SignupPage"));
const PasswordEmailPage = lazy(() => import("./view/page/PasswordEmailPage"));
const PasswordLinkPage = lazy(() => import("./view/page/PasswordLinkPage"));
const FirstLoginPage = lazy(() => import("./view/page/FirstLoginPage"));

const ProfilePage = lazy(() => import("./view/page/ProfilePage"));
const ManagePage = lazy(() => import("./view/page/ManagePage"));

// const PlayOldPage = lazy(() => import("./view/page/PlayPage_old"));
// const PlayPage = lazy(() => import("./view/page/PlayPage"));
const PlayRouter = lazy(() => import("./router/PlayRouter"));
const PlayerPage = lazy(() => import("./view/page/PlayerPage"));
const GuidePage = lazy(() => import("./view/page/GuidePage"));
const PlayGuidePage = lazy(() => import("./view/page/PlayGuidePage"));
const ManageGuidePage = lazy(() => import("./view/page/ManageGuidePage"));

const EduPage = lazy(() => import("./view/page/EduPage"));

// RootConfig();
const App = observer(() => {
    useEffect(() => {
        RootConfig();
    }, []);

    return (
        <ThemeProvider theme={defaultTheme}>
            <GlobalStyled />
            <Router>
                <Suspense fallback={<div>Loading...</div>}>
                    <HeaderContainer />
                    <Routes>
                        <Route element={<PrivateRouter />}>
                            {" "}
                            {/* <PublicRouter restricted={true} component={PlayerPage}/>} */}
                            <Route path="/" element={<MainPage />} />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route path="/manage" element={<ManagePage />} />
                            <Route path="/play" element={<PlayRouter />} />
                            {/*<Route path='/play_old' element={<PlayOldPage/>}/>*/}
                            <Route
                                path="/play/watch"
                                element={<PlayerPage />}
                            />
                            <Route path="/edu" element={<EduRouter />} />
                            <Route path="/guide" element={<GuidePage />} />
                            <Route
                                path="/play/guide"
                                element={<PlayGuidePage />}
                            />
                            <Route
                                path="/manage/guide"
                                element={<ManageGuidePage />}
                            />
                        </Route>

                        <Route element={<PublicRouter restricted={true} />}>
                            {" "}
                            {/* <PublicRouter restricted={true} component={PlayerPage}/>} */}
                            <Route path="/login" element={<LoginPage />} />
                            <Route
                                path="/login/first"
                                element={<FirstLoginPage />}
                            />
                            <Route
                                path="/login/recover"
                                element={<PasswordPage />}
                            />
                            <Route
                                path="/passwordEmail"
                                element={<PasswordEmailPage />}
                            />
                            <Route
                                path="/passwordLink"
                                element={<PasswordLinkPage />}
                            />
                            <Route path="/signup" element={<SignupPage />} />
                        </Route>
                        {/*<Route element={<PublicRouter restricted={false} />}>*/}
                        {/*</Route>*/}
                        {/*<Route component={NotFound}/>*/}
                    </Routes>
                </Suspense>
            </Router>
        </ThemeProvider>
    );
});

export default App;
render(<App />, document.getElementById("root"));
