import {ServerStore} from "../../store/domain/ServerStore";
import {
    getTokenFetchJson,
    postFetchText,
    postTokenFetchText, putTokenFetchJsonText
} from "../../api/ServerApi";

const SignRepository = {
    async signup(data) {
        let url = `${ServerStore.BASE_URL}/torooc/join/member`;
        // let url = `http://192.168.1.87:8080/contents/musical`;
        return await postFetchText(url, data);
    },

    async setPassword(data) {
        let url = `${ServerStore.BASE_URL}/torooc/join/member`;

        return await putTokenFetchJsonText(url, data);
    },

    async contentsStart(data) {
        let url = `${ServerStore.BASE_URL}/musical/start`;
        return await postTokenFetchText(url, data);
    },

    async contentsStop(data) {
        let url = `${ServerStore.BASE_URL}/musical/stop`;
        return await postTokenFetchText(url, data);
    },

    async getBackgroundFile(contentsUuid, uuid) {
        let url = `${ServerStore.BASE_URL}/contents/background/${contentsUuid}/${uuid}`;

        console.log(url);

        // return await getTokenFetchJson(url).then(async r => {
        //     return await getFetchJson(r);
        // });
        return await getTokenFetchJson(url);
    },
};

export default SignRepository;
