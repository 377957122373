import { observable } from 'mobx';
import { ContentsStore } from '../domain/ContentsStore';

const ContentsUiStore = observable({
	defaultContentsList: null,
	contentsList: null,

	libraries: null,

	recommendedCategoriesType: null,
	recommendedCategories: null,

	filterTarget: null,
	filterAge: null,
	filterType: null,

	async init(target, age, type, all) {
		if (ContentsStore.contentsList === null || all !== null) {
			await ContentsStore.getContentsList();
		}

		this.setFilter('filterTarget', target);
		this.setFilter('filterAge', age);
		this.setFilter('filterType', type);

		this.setRecommendedCategories();
		let list = await ContentsStore.doFilter();

		if (all) {
			list = await this.getRecommendedCategoryPlayList(all);
		}
		this.setContentsList(list);
	},

	setContentsList(list) {
		this.contentsList = list;
	},

	setRecommendedCategories() {
		const _isFilter = this.isFilter();
		if (_isFilter) {
			this.recommendedCategories = 'library';
		} else {
			this.recommendedCategories = 'default';
		}
	},

	getRecommendedCategoryPlayList(type) {
		let list = ContentsStore.initFilter();
		const target = type.split('_')[0];
		list = ContentsStore.doTargetFilter(list, target);

		if (type?.includes('top')) {
			list = list?.slice(0, 10);
		} else if (type?.includes('new')) {
			list?.sort((a, b) => {
				const [aYear, aMonth, aDate, aHours, aMinutes, aSeconds, aMSeconds] =
					a?.createdDate || [];
				const [bYear, bMonth, bDate, bHours, bMinutes, bSeconds, bMSeconds] =
					b?.createdDate || [];
				const aDateObject = new Date(
					aYear,
					aMonth - 1,
					aDate,
					aHours,
					aMinutes,
					aSeconds
				);
				const bDateObject = new Date(
					bYear,
					bMonth - 1,
					bDate,
					bHours,
					bMinutes,
					bSeconds
				);
				return bDateObject - aDateObject;
			});

			list = list?.slice(0, 30);
			// list = list?.filter((content, index) => {
			//     const [contentYear, contentMonth, contentDate, contentHours, contentMinutes, contentSeconds, contentMSeconds] = content?.createdDate || [];
			//     const contentDateObject = new Date(contentYear, contentMonth - 1, contentDate, contentHours, contentMinutes, contentSeconds);
			//
			//     const betweenTime = date.getTime() - contentDateObject.getTime();
			//     const betweenDay = Math.abs(betweenTime / (1000 * 60 * 60 * 24));
			//
			//     if (index === 1) return content;
			//     if (betweenDay < 31) return content;
			// });
		}

		return list;
	},

	getCategoryList(category) {
		let list = ContentsStore.initFilter();
		list = ContentsStore.doTypeFilter(list, category);

		return list;
	},

	categoryFilter(type) {
		let list = JSON.parse(JSON.stringify(this.contentsList)) || [];

		// list = list?.filter((content, index) => {
		//     if (content?.accessYn !== 'N' && content.musicalType === type) {
		//         return content;
		//     }
		// });
		list = list?.filter(
			(content) => content?.accessYn !== 'N' && content?.musicalType === type
		);

		return list;
	},

	isFilter() {
		return this.filterTarget || this.filterAge || this.filterType;
	},

	setFilter(type, value) {
		switch (type) {
			case 'filterTarget':
				this.filterTarget = value;
				break;
			case 'filterAge':
				this.filterAge = value;
				break;
			case 'filterType':
				this.filterType = value;
				break;
			default:
				return;
		}
	},

	filterContentsList() {
		const list = JSON.parse(JSON.stringify(this.defaultContentsList));

		this.contentsList?.forEach((content) => {
			list?.liku?.data?.[content.musicalType]?.push(content);
		});

		return list;
	},
});

export { ContentsUiStore };
