export const MusicalType = {
	// MUSICAL : '뮤지컬',
	// SINGLE : '구연동화',
	// SAFETY_EDU : '7대 안전교육',
	// EDUCATION : '교육',
	// ENGLISH: '영어 동화',
	// METAVERSE: '메타버스',
	// TEST : 'TEST',

	MUSICAL: '뮤지컬',
	SCIENCE_EDU: '융합 과학', // 과학교육
	ESSAY_SINGLE: '생각톡톡', // 논술동화
	SINGLE: '재미톡톡', // 구연동화
	SAFETY_EDU: '안전교육',
	STORY: '이야기나누기', // new
	SPECIAL_DAY: '특별한날', // new
	MUSIC: '동요',
	KIDS_POEM: '동시', // new
	ART: '달콤 아트',
	KOREAN: '한글아,놀자', // new
	ENGLISH_SINGLE: '영어동화',
	ENGLISH: '영어야,놀자',
	KOREAN_HISTORY: '한국사',
	CLIMATE_ENVIRONMENT: '기후와 환경',
	DIGITAL_EDU: '디지털 학습',
	ORIGAMI: '색종이접기',
	PIANO: '딩동댕피아노',

	// METAVERSE: '직업 체험', // 메타버스(직업 체험)
	CHINESE: '한자야,놀자',
	NEW_SPECIAL_DAY: '스페셜 데이',
	// EMOTION: '감성발달',
	TEST: '테스트',
	SAMSUNG: '연구용',
};
