import { observable, runInAction } from 'mobx';
import ContentsRepository from '../../../data/repository/ContentsRepository';

import { ContentsControlStore } from '../../domain/ContentsControlStore';
import { MetaverseContentsUiStore } from '../metaverse/MetaverseContentsUiStore';

import { PlayerUiStore } from './PlayerUiStore';
import { PlayerStore } from '../../domain/PlayerStore';
import { KidUiStore } from '../KidUiStore';

const PlayerBackgroundUiStore = observable({
	imageList: [],
	musicList: [],
	imageUrlList: [],
	musicUrlList: [],
	imageload: false,

	playMusic: null,
	videoState: null,

	defaultBackground: null,

	thisBackground: null,

	connectionImage: null,
	connectionImageLoad: false,

	connectionImageFromVideo: false,

	videoRef: false,
	contentsvideoRef: false,
	isPng: false,

	clear() {
		this.initBackground();
		this.setPlayMusic(false);
		this.setVideoState(false);
		this.setConnectionImage(null);
		this.imageList = [];
		this.musicList = [];
	},

	init() {
		this.initBackground();
		this.setPlayMusic(false);
		this.setVideoState(false);
		this.setConnectionImage(null);
	},

	setPlayMusic(value) {
		this.playMusic = value;
	},

	setVideoState(value) {
		this.videoState = value;
	},
	/**
	 * 각 유형에 따른 배경 리스트 초기화 후 오름차순 정렬
	 * @param {string} type - 배경화면용 이미지인지 배경음악인지 표시
	 * @param {object} data - 각 유형별 정보 포함 객체 데이터
	 */
	initBackgroundList(type, data) {
		let thisList = type !== 'music' ? this.imageList : this.musicList;
		thisList.splice(0, thisList.length);
		if (data !== undefined && Array.isArray(data) && data.length !== 0) {
			for (let value of data) thisList.push(value);
			thisList.sort((a, b) => a.startNum - b.startNum);
		}
	},

	initBackground() {
		this.thisBackground = JSON.parse(JSON.stringify(this.defaultBackground));
	},

	// async initBackgroundUrlList(type, thisList) {
	//     console.log("initBackgroundUrlList Call v$%$%");
	//     console.log("imageList", this.imageList);
	//     let tempData, recordState;
	//     if (type === "image" && this.imageUrlList.length === 0) {
	//         await thisList.map(async (value, index) => {
	//             // recordState = false;
	//             // for (let i = 0; i < this.imageUrlList.length; i++) {
	//             //     if (this.imageUrlList[i].uuid === value.uuid) {
	//             //         recordState = true;
	//             //         break;
	//             //     }
	//             // }
	//             // console.log("recordState", recordState);
	//             // if (recordState === false) {
	//             console.log(
	//                 "imageUrlList length before input",
	//                 this.imageUrlList.length
	//             );
	//             tempData = null;
	//             console.log("imageList length", this.imageList.length);
	//             if (this.imageUrlList.length < this.imageList.length) {
	//                 tempData = await ContentsRepository.getBackgroundFile(
	//                     value.contentsUuid,
	//                     value.uuid
	//                 );
	//                 tempData = await Object.assign({}, tempData, {
	//                     startNum: value.startNum,
	//                 });
	//                 // console.log("tempData type", typeof tempData);
	//                 console.log("tempData at imageList", tempData);
	//                 await this.imageUrlList.push(tempData);
	//             }
	//             console.log(
	//                 "imageUrlList length after input",
	//                 this.imageUrlList.length
	//             );
	//             console.log("imageList length", this.imageList.length);
	//             // }
	//         });
	//     }
	//     console.log("imageUrlList before sort", this.imageUrlList);
	//     const tempUrlList = [...this.imageUrlList];
	//     console.log("imageUrlList before sort", tempUrlList);
	//     this.imageUrlList = tempUrlList;
	//     console.log("imageUrlList after sort", tempUrlList);
	//     console.log("musicList", this.musicList);
	//     if (type === "music" && this.musicUrlList.length === 0) {
	//         await thisList.map(async (value, index) => {
	//             recordState = false;
	//             tempData = await ContentsRepository.getBackgroundFile(
	//                 value.contentsUuid,
	//                 value.uuid
	//             );
	//             tempData.startNum = value.startNum;
	//             this.musicUrlList.push(tempData);
	//         });
	//     }

	//     console.log("this imageUrlList", this.imageUrlList);
	//     console.log("this musicUrlList", this.musicUrlList);
	// },

	setBackgroundUrl(url) {
		this.thisBackground = {
			...this.thisBackground,
			image: { ...this.thisBackground.image, url },
		};
		this.setVideoState(true);
	},

	async setBackgroundSkip(type, backIndex) {
		// console.log("setBackground Call!!!");
		try {
			let load = type.includes('load') ? true : false;
			let thisList = !type.includes('music') ? this.imageList : this.musicList;
			if (load) {
				type = type.split('_');
				type = type[0];
			}

			let backData, backDataIndex;
			// console.log(
			//     "type",
			//     type.toString(),
			//     "backIndex parameter",
			//     backIndex
			// );
			backDataIndex = backIndex;
			// console.log("backDataIndex", backDataIndex);
			if (type === 'image') {
				// if (ContentsControlStore.progress) {
				//     MetaverseContentsUiStore.setNumFrame(null);
				//     MetaverseContentsUiStore.resetFacePhotoFrameList();
				//     MetaverseContentsUiStore.setIsReady(false);
				// }
				MetaverseContentsUiStore.tempFrame = backDataIndex;
			}

			backData = thisList[backDataIndex];

			// console.log("backData before convert url", backData);
			runInAction(() => {
				this.thisBackground[type].data = backData;
			});
			/** 백엔드에 배경화면 s3 image url 요청
			 * @param {string} uuid
			 * @param {string} contentsUuid
			 */
			backData = await ContentsRepository.getBackgroundFile(
				backData.contentsUuid,
				backData.uuid
			);
			// console.log("backData after convert url", backData);
			if (this.thisBackground?.image?.data?.type.toUpperCase() === 'PNG') {
				/* 배경화면 이미지 소스 로드를 위해 이미지 객체 생성 및 속성 설정 */
				let background = new Image();
				background.crossOrigin = 'Anonymous';
				background.src = backData.url;
				new Promise((resolve, reject) => {
					const image = new Image();
					background.onload = () => resolve();
					image.onerror = (error) => reject(error);
					/* 배경화면 이미지 소스를 이미지 태그에 넣은 후 얼굴 이미지를 띄우기 위해 numFrame값을 배경화면 리스트 중 해당 배경화면 인덱스로 세팅 */
					runInAction(() => {
						this.thisBackground[type].url = background;
						MetaverseContentsUiStore.setNumFrame(
							MetaverseContentsUiStore.tempFrame
						);
					});
				});
			} else {
				(async () => {
					const video = document.createElement('video');
					return new Promise((resolve, reject) => {
						video.crossOrigin = 'Anonymous';
						video.src = backData.url;
						video.load();
						video.oncanplaythrough = () => resolve(video);
						video.onerror = () => {
							reject();
						};
					});
				})().then((result) => {
					// console.log("result v$%$%", result);
					/* 비디오 소스를 이미지 태그에 넣은 후 얼굴 이미지를 띄우기 위해 numFrame값을 배경화면 리스트 중 해당 배경화면 인덱스로 세팅 */
					runInAction(() => {
						this.thisBackground[type].url = result;
						MetaverseContentsUiStore.setNumFrame(
							MetaverseContentsUiStore.tempFrame
						);
					});
				});
			}
			if (!load) {
				if (type === 'music' && this.playMusic) this.setPlayMusic(false);
				if (type === 'music') this.setPlayMusic(true);

				if (type === 'image' && this.videoState) this.setVideoState(false);
				if (type === 'image') this.setVideoState(true);
			} else {
				if (type === 'music') this.setPlayMusic(false);
				if (type === 'image') this.setVideoState(false);
			}
		} catch (error) {
			console.log('PlayerBackgroundUiStore - setBackground error', error);
		}
	},

	async setBackground(type, backIndex) {
		try {
			// console.log("setBackground Call!!!");
			let load = type.includes('load') ? true : false;
			let thisList = !type.includes('music') ? this.imageList : this.musicList;
			// console.log("thisScene", ContentsControlStore.thisScene);
			// console.log("thisList", thisList, "thisList type", typeof thisList);
			if (load) {
				type = type.split('_');
				type = type[0];
			}
			/* +1한 이유: 코드상으로는 0부터 시작하지만
            콘텐츠 빌더 툴은 1부터 시작하기에 시작점을 맞춰주기 위해서 1을 더한다. */
			let scenarioIndex = ContentsControlStore.thisScene + 1;
			if (PlayerUiStore.isInteractive()) {
				scenarioIndex = ContentsControlStore.thisScene;
			}
			let backData, backDataIndex;
			backDataIndex = thisList.findIndex((value) => {
				return (
					value.startNum === scenarioIndex ||
					value.startNum === scenarioIndex.toString()
				);
			});

			// console.log(
			//     "scenarioIndex",
			//     scenarioIndex,
			//     "backDataIndex",
			//     backDataIndex,
			//     "videoState",
			//     this.videoState,
			//     "type",
			//     type,
			//     "musicState",
			//     this.playMusic,
			//     "thisScene",
			//     ContentsControlStore.thisScene,
			//     "connectionImageLoad",
			//     this.connectionImageLoad,
			//     "numFrame",
			//     MetaverseContentsUiStore.numFrame,
			//     "start",
			//     ContentsControlStore.start,
			//     "progress",
			//     ContentsControlStore.progress,
			//     "loading",
			//     ContentsControlStore.loading
			// );

			// 이게 무슨 역할인지 설명 듣기 필요
			/* 초기화나 backDataIndex값이 0이 아닐 때 사용 */
			if (backDataIndex === -1 && backIndex) {
				backDataIndex = backIndex;
			}

			if (backDataIndex !== -1) {
				if (type === 'image') {
					// if (ContentsControlStore.progress) {
					//     // MetaverseContentsUiStore.setNumFrame(null);
					//     // MetaverseContentsUiStore.resetFacePhotoFrameList();
					//     // MetaverseContentsUiStore.setIsReady(false);
					// }
					MetaverseContentsUiStore.tempFrame = backDataIndex;
				}

				backData = thisList[backDataIndex];

				// console.log(
				//     "backData before convert url at setBackground",
				//     backData
				// );

				runInAction(() => {
					this.thisBackground[type].data = backData;
				});
				backData = await ContentsRepository.getBackgroundFile(
					backData.contentsUuid,
					backData.uuid
				);
				// console.log("backDataIndex", backDataIndex);

				// console.log(
				//     "backData after convert url at setBackground",
				//     backData
				// );
				await (() => {
					return new Promise((resolve, reject) => {
						const pngStr =
							this.thisBackground?.image?.data?.type?.toUpperCase();
						const isPng = pngStr === 'PNG';
						// console.log(
						//     "pngStr",
						//     pngStr,
						//     "pngStr type",
						//     typeof pngStr,
						//     "type",
						//     type
						// );

						if (isPng && type !== 'music') {
							const background = new Image();
							background.crossOrigin = 'Anonymous';
							background.src = backData.url;
							(() => {
								return new Promise((resolve, reject) => {
									background.onload = () => {
										resolve();
									};
									background.onerror = (error) => reject(error);
								});
							})()
								.then(() => {
									/* 배경화면 이미지 태그에 띄운 후 얼굴 띄우기 위해(이미지, 위치) numFrame값을 배경화면 리스트 중 해당 배경화면 인덱스로 세팅 */
									runInAction(() => {
										this.thisBackground[type].url = background;
										MetaverseContentsUiStore.setNumFrame(
											MetaverseContentsUiStore.tempFrame
										);
										// console.log(
										//     "image background set complete"
										// );
									});
								})
								.finally(() => resolve());
						} else if (type === 'music') {
							(() => {
								const video = document.createElement('video');
								video.crossOrigin = 'Anonymous';
								video.src = backData.url;
								video.load();
								return new Promise((resolve, reject) => {
									video.oncanplaythrough = () => {
										resolve(video);
									};
									video.onerror = () => reject();
								});
							})()
								.then((video) => {
									runInAction(() => {
										this.thisBackground[type].url = video.src;
									});
								})
								.finally(() => resolve());
						} else {
							(async () => {
								const video = document.createElement('video');
								video.crossOrigin = 'Anonymous';
								video.src = backData?.url;
								video.load();
								return await new Promise((resolve, reject) => {
									video.oncanplaythrough = () => {
										resolve(video);
									};
									video.onerror = () => reject();
								});
							})()
								.then((video) => {
									// console.log("video parameter v$%$%", video);
									runInAction(() => {
										this.thisBackground[type].url = video;
										// console.log(
										//     "video background set complete"
										// );
									});
								})
								.finally(() => resolve());
						}
					});
				})().then(() => {
					// console.log("#1 condition not load or load");
					if (!load) {
						// 로딩중이 아니고 타입이 음악이고 플레이중일 때 => 음악 플레이 중단
						if (type === 'music' && this.playMusic) this.setPlayMusic(false);
						// 로딩중이 아니고 타입이 음악이고 플레이가 멈춰있을 때 => 음악 플레이 실행
						if (type === 'music') this.setPlayMusic(true);
						// 로딩중이 아니고 타입이 이미지이고 비디오가 플레이중일 때 => 비디오 플레이 중단
						if (type === 'image' && this.videoState) this.setVideoState(false);
						// 로딩중이 아니고 타입이 이미지이고 비디오가 멈춰있을 때 => 비디오 플레이 실행
						if (type === 'image') this.setVideoState(true);
					} else {
						// 로딩중일 때 타입이 음악이면 음악 플레이 중단, 타입이 이미지이면 비디오 플레이 중단
						if (type === 'music') this.setPlayMusic(false);
						if (type === 'image') this.setVideoState(false);
					}
					runInAction(() => {
						this.connectionImageLoad = false;
					});
				});
			} else if (
				// 로딩중이 아니고 시나리오인덱스가 배경화면의 startNum과 endNum범위 안에 있을 때
				!load &&
				!(
					this.thisBackground[type].data !== null &&
					this.thisBackground[type].data.startNum <= scenarioIndex &&
					this.thisBackground[type].data.endNum >= scenarioIndex
				)
			) {
				// console.log(
				//     "#2 condition not load && scenarioIndex between startNum and endNum"
				// );
				if (type === 'music') this.setPlayMusic(false);
				if (type === 'image') this.setVideoState(false);
				runInAction(() => {
					this.thisBackground[type].data = null;
					this.thisBackground[type].url = null;
					this.connectionImageLoad = false;
				});
			} else if (!load) {
				// 로딩중이 아닐 때
				// console.log("#3 condition not load, just progress");
				if (type === 'music') this.setPlayMusic(true);
				if (type === 'image') this.setVideoState(true);
				runInAction(() => {
					this.connectionImageLoad = false;
				});
			}
		} catch (error) {
			console.log('PlayerBackgroundUiStore - setBackground error');
			this.connectionImageLoad = false;
		}
	},

	setVideoRef(ref) {
		this.videoRef = ref;
	},

	setContentsvideoRef(ref) {
		this.contentsvideoRef = ref;
	},

	/**
	 * 이미지 클래스 오브젝트를 통해 메타버스나 생일파티 콘텐츠 페이지에서
	 * 선택한 얼굴 이미지 소스를 로드
	 */
	setFaceImageLoad() {
		if (PlayerUiStore.isBirthday()) {
			KidUiStore.birthdayList.map((value, index) => {
				const FaceImage = new Image();
				FaceImage.crossOrigin = 'Anonymous';
				FaceImage.src = value.image;
				return new Promise((resolve, reject) => {
					FaceImage.onload = () => {
						MetaverseContentsUiStore.frameimageList.push(FaceImage);
						resolve();
					};
				});
			});
		} else if (PlayerUiStore.isMetaverse()) {
			Object.values(MetaverseContentsUiStore.faceImageList)?.map(
				(value, index) => {
					// console.log("value", value, "index", index);
					// console.log("value path", value.path);
					const FaceMetaverseImage = new Image();
					// FaceMetaverseImage.crossOrigin = "Anonymous";
					FaceMetaverseImage.src = value.path;
					return new Promise((resolve, reject) => {
						FaceMetaverseImage.onload = () => {
							MetaverseContentsUiStore.frameimageList.push(FaceMetaverseImage);
							resolve();
						};
					});
					// console.log("FaceMetaverseImage", FaceMetaverseImage);
				}
			);
		}
		// console.log("frameImageList", MetaverseContentsUiStore.frameimageList);
		// console.log(
		//     "frameImageList length",
		//     MetaverseContentsUiStore.frameimageList?.length
		// );
	},

	setConnectionImageLoad() {
		// console.log("connectionImageLoad call!!!");
		let scenarioIndex = ContentsControlStore.thisScene + 1;
		let backDataIndex = this.imageList.findIndex((value) => {
			return (
				value.startNum === scenarioIndex ||
				value.startNum === scenarioIndex.toString()
			);
		});
		if (backDataIndex !== -1) {
			if (
				PlayerBackgroundUiStore.thisBackground?.image?.data?.type?.toUpperCase() ===
				'PNG'
			) {
				const connectImage = new Image();
				connectImage.crossOrigin = 'Anonymous';
				connectImage.src =
					PlayerBackgroundUiStore.thisBackground?.image?.url?.src;
				return new Promise((resolve, reject) => {
					connectImage.onload = () => {
						this.setConnectionImage(connectImage);
						runInAction(() => {
							// console.log("connection image set(image)");
							this.connectionImageLoad = true;
						});
						resolve();
					};
				});
			} else {
				this.videoRef.current.crossOrigin = 'Anonymous';
				const canvas = document.createElement('canvas');
				const context = canvas.getContext('2d');

				canvas.width = this.videoRef.current.offsetWidth;
				canvas.height = this.videoRef.current.offsetHeight;

				try {
					context.drawImage(
						this.videoRef.current,
						0,
						0,
						canvas.width,
						canvas.height
					);
				} catch (e) {
					console.log('drawImage error', e);
				}

				const data = canvas.toDataURL('image/png', 1.0);
				const connectImage = new Image();
				connectImage.crossOrigin = 'Anonymous';
				connectImage.src = data;
				return new Promise((resolve, reject) => {
					connectImage.onload = () => {
						this.setConnectionImage(connectImage);
						// console.log("connection image set(video)");
						runInAction(() => (this.connectionImageLoad = true));
						resolve();
					};
					connectImage.onerror = (error) => {
						// 이미지 로드 중 오류가 발생하면 reject
						console.log('erererer', error);
						reject(error);
					};
				});
			}
		}
	},

	setConnectionImage(image) {
		this.connectionImage = image;
		// console.log("connectionImage val", this.connectionImage);
	},
});

export { PlayerBackgroundUiStore };
