import {observable} from "mobx";
import {PlayerLikuUiStore} from "./PlayerLikuUiStore";

const PlayerRoleUiStore = observable({
    roleColor: {},
    likuRole: [],

    initRole(data) {
        let roles = [];
        this.roleColor = {};
        PlayerLikuUiStore.initRole();

        for (let i of data) {
            this.roleColor[i.name] = i.color;
            roles.push(['', i.name]);
        }
        this.initLikuRole(roles);
        // console.log('likuRole', roles);
        // console.log('roleColor', this.roleColor);
    },

    initLikuRole(data) {
      this.likuRole = data;
    },

    // setLikuRole(index, data) {
    //     if (PlayerUiStore.contents.musicalType !== 'MUSICAL') {
    //         // console.log('this.likuRole', this.likuRole);
    //         for (let i in this.likuRole) {
    //             // console.log('this.likuRole[i]', this.likuRole[i]);
    //             this.likuRole[i][0] = data;
    //         }
    //     } else {
    //         this.likuRole[index][0] = data;
    //     }
    //
    //     // console.log('likuRole', this.likuRole);
    // },

    setLikuRole(data) {
        try {
            this.likuRole[0][0] = data;
        } catch (error) {
            console.log('setLikuRole error');
        }
        // if (PlayerUiStore.contents.musicalType !== 'MUSICAL') {
        //     // console.log('this.likuRole', this.likuRole);
        //     for (let i in this.likuRole) {
        //         // console.log('this.likuRole[i]', this.likuRole[i]);
        //         this.likuRole[i][0] = data;
        //     }
        // } else {
        //     this.likuRole[index][0] = data;
        // }

        // console.log('likuRole', this.likuRole);
    },

    likuRoleCheck() {
        let check = this.likuRole.find((role) => {
            let serialCheck = false;
            let connectCheck = false;
            // console.log('role', role);
            serialCheck = (role[0] !== '');
            if (serialCheck && PlayerLikuUiStore.likuUuids.hasOwnProperty(role[0]))
                connectCheck = PlayerLikuUiStore.likuUuids[role[0]].connect;
            // console.log('serialCheck', serialCheck);
            // console.log('connectCheck', connectCheck);
            return serialCheck && connectCheck;
        });
        if (check === undefined) return false;
        else return true;
    },
});

export {PlayerRoleUiStore};
