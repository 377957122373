import { getCookie } from "../utils/cookie";
import {ServerStore} from "../store/domain/ServerStore";

export async function addCollectionLiku(name) {
  let URL = `${ServerStore.BASE_URL}/portal/collection/liku`;
  let collectionName = getCookie('collectionName')

  let data = JSON.stringify({
    serial: name,
    collection: {name: collectionName},
  });
  console.log(data)
  return await fetch(URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getCookie('token')}`,
    },
    body: data,
  })
    .then(text => text.json())
    .then(r =>{
        console.log('addCollectionLiku', r);
        return r;
      })
    .catch(error=>{
      console.log('addCollectionLiku', error);
      return error;
    })
};

export async function delCollectionLiku(name) {
  let URL = `${ServerStore.BASE_URL}/portal/collection/liku`;
  let collectionName = getCookie('collectionName')

  let data = JSON.stringify({
    serial: name,
    collection: {name: collectionName},
  });
  console.log(data)
  return await fetch(URL, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getCookie('token')}`,
    },
    body: data,
  })
    .then(text => text.text())
    .then(r =>{
        console.log('delCollectionLiku', r);
        return r;
      })
    .catch(error=>console.log('delCollectionLiku', error))
};
