import {observable} from "mobx";

const MetaverseStore = observable({
    faceImageList: null,
    faceImages: null,

    clear() {
        this.faceImages = null;
    },

    setFaceImageList(list) {
        this.faceImageList.liku.data = list;
    },
});

export {MetaverseStore};
