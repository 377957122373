export const MusicType = {
    KIDS_SONG : '동요',
    ENG_KIDS_SONG : '영어 동요',
}

export const SeniorMusicType = {
    TROT_SONG : '트로트',
    // CLASSIC: '클래식',
}

export const KidMusicType = {
    KIDS_SONG : '동요',
    ENG_KIDS_SONG : '영어 동요',
    KOR_TRAD_KIDS_SONG : '국악 동요',
    AIEN_SONG : '아이엔',
}
