import { observable } from 'mobx';
import { ContentsUiStore } from '../ui/ContentsUiStore';
import { TargetType } from '../../data/model/play/TargetType';
import { AgeType } from '../../data/model/play/AgeType';
import ContentsRepository from '../../data/repository/ContentsRepository';
import { ExceptMusicalType } from '../../data/model/play/ExceptMusicalType';
import { getCookie } from '../../utils/cookie';

const ContentsStore = observable({
	contentsList: null,

	async getContentsList() {
		await ContentsRepository.getContentsList().then((r) => {
			const role = getCookie('userRole');
			if (!role.includes('PREMIUM')) {
				r = r.map((value) => {
					try {
						value.title = value?.title.replaceAll('6세)', '');
					} catch (e) {
						const regexAllCase = new RegExp('6세)', 'g');
						value.title = value?.title.replace(regexAllCase, '');
					}

					try {
						if (value.title.split('-').length > 2) {
							const removeIndex = value.title.lastIndexOf('-');
							const titleArray = value.title.split('');
							titleArray.splice(removeIndex, 2);
							value.title = titleArray.join('');
						}
					} catch (e) {}
					return value;
				});
			}

			r.sort((a, b) => {
				return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
			});

			console.log('r', r);

			this.setContentsList(r);
		});
	},

	setContentsList(list) {
		this.contentsList = list;
	},

	getContents(idx) {
		// return this.contentsList?.filter((c) => {
		//     if (c.id === parseInt(idx)) return c;
		// });
		return this.contentsList?.filter((c) => c.id === parseInt(idx));
	},

	initFilter() {
		let list = JSON.parse(JSON.stringify(this.contentsList)) || [];
		list = this.doAccessFilter(list);

		return list;
	},

	doFilter() {
		let list = this.initFilter();
		list = this.doTargetFilter(list);
		list = this.doAgeFilter(list);
		list = this.doTypeFilter(list);
		// list = this.doParamFilter(list);

		return list;
	},

	doAccessFilter(list) {
		const deleteSet = new Set();
		list.forEach((content) => {
			if (
				content.accessYn === 'N' ||
				Object.keys(ExceptMusicalType).includes(content.musicalType)
			) {
				deleteSet.add(content.id);
			}
		});

		const deleteList = [...deleteSet];

		list = list.filter((content) => !deleteList.includes(content.id));

		return list;
	},

	doTargetFilter(list, target) {
		const targetKey =
			target !== undefined
				? target.toUpperCase()
				: ContentsUiStore.filterTarget;
		const deleteSet = new Set();

		// if (ContentsUiStore.filterTarget !== null && ContentsUiStore.filterTarget !== 'ALL') {
		if (targetKey !== null && targetKey !== 'ALL') {
			list.forEach((content) => {
				// if (!content?.ageGroup?.includes(TargetType[targetKey])) {
				const targetType = TargetType[targetKey]?.includes('/')
					? TargetType[targetKey]?.split(' / ')[0]
					: TargetType[targetKey];
				if (!content?.ageGroup?.includes(targetType)) {
					deleteSet.add(content.id);
				}
			});
		}

		const deleteList = [...deleteSet];
		list = list.filter((content) => !deleteList.includes(content.id));

		return list;
	},

	doAgeFilter(list) {
		const deleteSet = new Set();

		if (ContentsUiStore.filterAge !== null) {
			list.forEach((content) => {
				// for (const [index, content] of list.entries()) {
				if (
					!content?.ageGroup?.includes(
						AgeType[ContentsUiStore.filterTarget]?.[ContentsUiStore.filterAge]
					)
				) {
					// list.splice(index, 1);
					deleteSet.add(content.id);
				}
			});
		}

		const deleteList = [...deleteSet];
		list = list.filter((content) => !deleteList.includes(content.id));

		return list;
	},

	doTypeFilter(list, type) {
		const deleteSet = new Set();
		const typeKey = type !== undefined ? type : ContentsUiStore.filterType;

		if (typeKey !== null) {
			list.forEach((content) => {
				// if(!content?.musicalType?.includes(typeKey)) {
				if (content?.musicalType !== typeKey) {
					deleteSet.add(content.id);
				}
			});
		}
		const deleteList = [...deleteSet];
		list = list.filter((content) => !deleteList.includes(content.id));

		switch (typeKey) {
			case 'SCIENCE_EDU': {
				list = list.sort((a, b) => {
					const numberingA = a.title?.split(')')[1]?.split(' ')[0];
					const numberingB = b.title?.split(')')[1]?.split(' ')[0];
					const [aParts, bParts] = [
						numberingA?.split('-'),
						numberingB?.split('-'),
					];
					for (let i = 0; i < Math.max(aParts?.length, bParts?.length); i++) {
						const aPart = parseInt(aParts[i]) || 0;
						const bPart = parseInt(bParts[i]) || 0;
						if (i !== 0) {
							return 1;
						}

						if (aPart !== bPart) {
							return bPart - aPart;
						}
					}
					return 0;
				});
				break;
			}
			case 'STORY': {
				list = list.sort((a, b) => {
					const aSplit = a.title.split('_');
					const bSplit = b.title.split('_');
					const issueA = parseInt(aSplit[0]);
					const issueB = parseInt(bSplit[0]);
					const weekA = parseInt(aSplit[1]?.slice(0, 1));
					const weekB = parseInt(bSplit[1]?.slice(0, 1));
					const stepA = parseInt(aSplit[aSplit.length - 1]);
					const stepB = parseInt(bSplit[bSplit.length - 1]);
					if (issueA !== issueB) {
						return issueB - issueA;
					}
					if (weekA !== weekB) {
						return weekA - weekB;
					}
					return stepA - stepB;
				});
			}
			case 'ORIGAMI' : {
				const order = ["기초", "초급", "중급"];

				list = list.sort((a, b) => {
					const aIndex = order.findIndex((o) => a.title.includes(o));
					const bIndex = order.findIndex((o) => b.title.includes(o));
					return aIndex - bIndex;
				});
			}
			default: {
				break;
			}
		}
		console.log('@#@#access', type, list);
		return list;
	},

	filterContentsList() {
		let list = JSON.parse(JSON.stringify(this.contentsList)) || [];
		let deleteContent = [];

		if (
			ContentsUiStore.filterTarget !== null &&
			ContentsUiStore.filterTarget !== 'ALL'
		) {
			for (const content of list) {
				if (
					!content?.ageGroup?.includes(TargetType[ContentsUiStore.filterTarget])
				)
					deleteContent.push(content.id);
				// delete list.liku.data[category];
			}
		}

		if (ContentsUiStore.filterAge !== null) {
			for (const content of list) {
				if (
					!content?.ageGroup?.includes(
						AgeType[ContentsUiStore.filterTarget]?.[ContentsUiStore.filterAge]
					)
				)
					deleteContent.push(content.id);
				// delete list.liku.data[category];
			}
		}

		if (ContentsUiStore.filterType !== null) {
			for (const content of list) {
				/*
                if (!content?.musicalType?.includes(ContentsUiStore.filterType))
                    deleteContent.push(content.id);
                */

				// console.log('filterType', ContentsUiStore.filterType, content?.musicalType);

				if (content?.musicalType?.localeCompare(ContentsUiStore.filterType))
					deleteContent.push(content.id);
			}
		}

		deleteContent = [...new Set(deleteContent)];

		list = list.filter((content) => !deleteContent.includes(content.id));

		return list;
	},
});

// reaction(
//     () => ContentsStore.contentsList,
//     value => {
//         ContentsUiStore.initContentsList(value);
//     }
// );

export { ContentsStore };
