import { getFaceTokenFetchJson } from "../../api/ServerApi";
import { ServerStore } from "../../store/domain/ServerStore";
import { KidUiStore } from "../../store/ui/KidUiStore";
import { getCookie } from "../../utils/cookie";

const MetaverseRepository = {
    async getFaceImageList(length) {
        let serial = getCookie("likuSerial");
        // console.log('serial--:',serial);
        let url;
        // if (length === 0) {
        //   url = `${ServerStore.BASE_URL}/contents/metaverse/liku/${serial}/photos`;
        // } else {
        //   url = `${ServerStore.BASE_URL}/contents/metaverse/liku/${serial}/photos?size=${length}`;
        // }
        url = `${ServerStore.BASE_URL}/contents/metaverse/liku/${serial}/photos`;
        // let url = `${ServerStore.BASE_URL}/contents/metaverse/liku/2109L990/photos`;
        // console.log('URL--:',url);
        return await getFaceTokenFetchJson(url);
    },

    // async getFaceImages() {
    //     let serial = getCookie('likuSerial');
    //     console.log('serial--:',serial);
    //     let url = `${ServerStore.BASE_URL}/contents/metaverse/liku/${serial}/photos`;

    // }
};

export default MetaverseRepository;
