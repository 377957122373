import { observable } from "mobx";
import {QuickMenuControlMyExpressionStore} from "../../domain/quickmenu/QuickMenuControlMyExpressionStore";

const QuickMenuControlMyExpressionUiStore = observable({
    expressions: null,

    actions: null,
    displays: null,

    expressionIdx: null,
    tts: '',
    action: null,
    display: null,

    modalVisible: false,

    setExpressions(list) {
        this.expressions = list;
        console.log('setExpressions', this.expressions);
    },

    addExpression(expression) {
        QuickMenuControlMyExpressionStore.addExpression(expression);
    },

    deleteExpression(index) {
        QuickMenuControlMyExpressionStore.deleteExpression(index);
    },

    setExpression(index) {
        this.setExpressionIdx(index);
        if (index !== undefined) {
            this.setTTS(this.expressions[index].tts);
            this.setAction(this.expressions[index].action);
            this.setDisplay(this.expressions[index].display);
            this.setModalVisible(true);
        } else {
            this.setTTS('');
            this.setAction(Object.keys(QuickMenuControlMyExpressionStore.actions)[0]);
            this.setDisplay(Object.keys(QuickMenuControlMyExpressionStore.displays)[0]);
        }
    },

    saveExpression() {
        console.log('expressionIdx', this.expressionIdx);
        QuickMenuControlMyExpressionStore.setExpressions(
            this.expressionIdx,
            this.tts,
            this.action,
            this.display
        );
        this.setModalVisible(false);
        this.setExpression();
    },

    setExpressionIdx(index) {
        this.expressionIdx = index;
    },

    setTTS(tts) {
        this.tts = tts;
    },

    setAction(action) {
        this.action = action;
        const orderList = this.setOrderExpressionList(action, QuickMenuControlMyExpressionStore.actions);

        this.setActions(orderList);
    },

    setDisplay(display) {
        this.display = display;
        const orderList = this.setOrderExpressionList(display, QuickMenuControlMyExpressionStore.displays);
        this.setDisplays(orderList);
    },

    setModalVisible(value) {
        this.modalVisible = value;
    },

    setActions(list) {
        this.actions = list;
    },

    setDisplays(list) {
        this.displays = list;
    },

    setOrderExpressionList(expression, list) {
        let orderList = {};
        orderList[expression] = list[expression];

        for (const key of Object.keys(list)) {
            if (expression !== key) orderList[key] = list[key];
        }

        Object.assign(orderList, list);

        console.log('orderList', orderList);

        return orderList;
    },
});

export {QuickMenuControlMyExpressionUiStore};
