import { ServerStore } from "../../store/domain/ServerStore";
import {
  deleteAxios,
  deleteServerFetchText,
  getFetchJson,
  getTokenFetchJson,
  postTokenAxios,
  postTokenFetchText,
  postTokenFormDataFetch,
  putTokenFetchText,
} from "../../api/ServerApi";
import { getCookie } from "../../utils/cookie";

const ScenarioRepository = {
  async getAllScenario() {
    const url = `${ServerStore.BASE_URL}/contents/scenario`;
    return await getTokenFetchJson(url).then((r) => {
      return r;
    });
  },

  async getScenario(id) {
    const url = `${ServerStore.BASE_URL}/contents/scenario/${id}`;
    return await getTokenFetchJson(url).then(async (r) => {
      console.log(r);
      return await getFetchJson(r.url);
    });
  },

  async getScenarioById(id) {
    const url = `${ServerStore.BASE_URL}/contents/scenario/${id}`;
    return await getTokenFetchJson(url).then(async (r) => {
      return r;
    });
  },

  async setScenario(data) {
    const url = `${ServerStore.BASE_URL}/contents/scenario`;

    return await postTokenFormDataFetch(url, data).then((r) => {
      return r;
    });
  },

  async uploadScenarioWithMotion(data) {
    const url = `${ServerStore.BASE_URL}/contents/scenario/motions`;
    return await postTokenAxios(url, data).then((r) => {
      return r;
    });
  },

  async connectScenarioMotion(scenarioId, motionId) {
    const url = `${ServerStore.BASE_URL}/contents/scenario/${scenarioId}/motion/${motionId}`;
    return await putTokenFetchText(url).then((r) => {
      return r;
    });
  },

  async deleteScenario(id) {
    const url = `${ServerStore.BASE_URL}/contents/scenario?id=${id}`;
    return await deleteServerFetchText(url).then((r) => {
      return JSON.parse(r);
    });
  },

  async removeMotionInScenario(scenarioId, motionId, motionOrder) {
    const url = `${ServerStore.BASE_URL}/contents/scenario/${scenarioId}/motion/${motionId}?motionOrder=${motionOrder}`;
    return await deleteAxios(url).then((r) => {
      return JSON.parse(r);
    });
  },
};

export default ScenarioRepository;
