import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {getCookie} from "../utils/cookie";

const PublicRouter = ({restricted}) => {
    const isLogin = getCookie('token');
    return isLogin !== undefined && restricted ? <Navigate to="/" /> : <Outlet />;
}

export default PublicRouter;
