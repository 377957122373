import { observable } from "mobx";
import { deleteFileDB, DelFaceUserDB, getAttendanceDB, getFaceUserDB, getKidUploadFileDB, kidAddDB, updateEndTimeDB, UpdateFaceUserDB, updateStartTimeDB, updateStateDB, uploadFileDB } from "../../api/KidMangeApi";
import { KidUiStore } from "./KidUiStore";
import { ManageUiStore } from "./ManageUiStore";

const KidAddUiStore = observable({
  kidSideHeaderTitle: '',

  setKidSideHeaderTitle(title) {
    this.kidSideHeaderTitle = title;
  },

  kids: [],

  kidIndex: '',

  kidName: "",
  kidId: "", //아동고유번호

  kidBirthYear: "",
  kidBirthMonth: "",
  kidBirthDay: "",
  // 출생연월일
  kidBirth: "",

  kidCollectionId: "",
  kidProfile: "",

  description: "",

  kidState: '',

  kidStartTime: '',
  kidEndTime: '',

  kidYear: '',
  kidMonth: '',
  kidDay: '',

  yesterdayYear: '',
  yesterdayMonth: '',
  yesterdayDay: '',

  dateStrings: '',

  attendanceState: '',

  getAttendanceDBDate: '',
  getAttendanceDBStartDate: '',

  uploadFileId: '',

  fileImg: '',

  imgFaceID: '',

  firstAddIndex: '',

  fileImgs: [],

  fileImg_01: null,
  fileImg_02: null,
  fileImg_03: null,
  fileImg_04: null,
  fileImg_05: null,

  fileImgId_01: null,
  fileImgId_02: null,
  fileImgId_03: null,
  fileImgId_04: null,
  fileImgId_05: null,



  attendanceStateKo: '',

  checkList: [],

  async setCheckList() {
    this.checkList = [];
    // if name is blank
    // 이름이 비어있을 때
    if (this.kidName === '') {
      this.checkList.push('kidName');
    }
    // if id is blank
    // id가 비어있을 때
    if (this.kidId === '') {
      this.checkList.push('kidId');
    }
    // if profile is blank
    // 프로파일이 비어있을 때
    if (this.kidProfile === '') {
      this.checkList.push('kidProfile');
    }
    // 출생년도 길이가 4보다 작거나 출생년도가 현재 날짜의 년도보다 클 때
    if (this.kidBirthYear.length < 4 || parseInt(this.kidBirthYear) > new Date().getFullYear()) {
      this.checkList.push('kidBirthYear');
    }

    if (this.kidBirthMonth.length < 2 || !(parseInt(this.kidBirthMonth) > 0) || !(parseInt(this.kidBirthMonth) < 13)) {
      this.checkList.push('kidBirthMonth');
    }
    let dayRangeDate = new Date(this.kidBirthYear, this.kidBirthMonth, 0);
    let dayRange = dayRangeDate.getDate();

    if (this.kidBirthDay.length < 2 || !(parseInt(this.kidBirthDay) > 0) || !(parseInt(this.kidBirthDay) < dayRange + 1)) {
      this.checkList.push('kidBirthDay');
    }

    return this.checkList.length === 0;
  },


  setAttendanceStateKo(state) {
    this.attendanceStateKo = state;
  },

  setFileImg_01(data) {
    this.fileImg_01 = data;
  },

  setFileImg_02(data) {
    this.fileImg_02 = data;
  },

  setFileImg_03(data) {
    this.fileImg_03 = data;
  },

  setFileImg_04(data) {
    this.fileImg_04 = data;
  },

  setFileImg_05(data) {
    this.fileImg_05 = data;
  },

  setFileImgId_01(data) {
    this.fileImgId_01 = data;
  },

  setFileImgId_02(data) {
    this.fileImgId_02 = data;
  },

  setFileImgId_03(data) {
    this.fileImgId_03 = data;
  },

  setFileImgId_04(data) {
    this.fileImgId_04 = data;
  },

  setFileImgId_05(data) {
    this.fileImgId_05 = data;
  },

  resetFile() {
    console.log('resetFile')
  },

  setFileImgs(data) {
    this.fileImgs.push(data);
  },

  setImgFaceID(data) {
    this.imgFaceID = data;
  },

  setUploadFileId(id) {
    this.uploadFileId = id
  },

  setGetAttendanceDBDate(date) {
    this.getAttendanceDBDate = date;
  },

  setGetAttendanceDBStartDate(date) {
    this.getAttendanceDBStartDate = date;
  },

  setAttendanceState(state) {
    this.attendanceState = state;
  },

  setDateString() {
    // console.log('setDateStringsetDateString')
    this.dateStrings = this.kidYear + '년 ' + this.kidMonth + '월 ' + this.kidDay + '일';
    // console.log(this.kidMonth);
    // console.log(this.kidDay);
    let setDate = `${this.kidYear},${this.kidMonth},${this.kidDay}`.trim()
    this.setGetAttendanceDBDate(setDate);
    // console.log("DateString: ", this.getAttendanceDBDate);
  },

  setKidYear(year) {
    this.kidYear = year;
    // console.log('year', this.kidYear);
  },

  setKidMonth(month) {
    this.kidMonth = month;
    // console.log('month', this.kidMonth);
  },

  setKidDay(day) {
    this.kidDay = day;
    // console.log('day', this.kidDay);
  },

  setYesterdayYear(year) {
    this.yesterdayYear = year;
  },

  setYesterdayMonth(month) {
    this.yesterdayMonth = month;
  },

  setYesterdayDay(day) {
    this.yesterdayDay = day;
  },

  setKidStartTime(time) {
    // console.log("&&&&&&&W", time);
    this.kidStartTime = time;
  },

  setKidEndTime(time) {
    this.kidEndTime = time;
    // console.log('kidEndTime', this.kidEndTime);
  },

  setKidState(state) {
    this.kidState = state;
  },

  setKidIndex(idx) {
    this.kidIndex = idx;
  },

  setKidName(name) {
    this.kidName = name;
  },

  setKidId(id) {
    this.kidId = id; //아동고유번호
  },

  setKidBirth(birth) {
    this.kidBirth = birth;
  },

  setKidBirthYear(year) {
    this.kidBirthYear = year;
  },

  setKidBirthMonth(month) {
    this.kidBirthMonth = month;
    // console.log('kidBirthMonth', this.kidBirthMonth)
  },

  setKidBirthDay(day) {
    this.kidBirthDay = day;
    // console.log('kidBirthDay', this.kidBirthDay)
  },

  setCollectionId(id) {
    this.kidCollectionId = id;
  },

  setKidProfile(data) {
    this.kidProfile = data;
  },

  setDescription(data) {
    this.description = data;
  },

  setKidBrith() {
    this.kidBirth = this.kidBirthYear + this.kidBirthMonth + this.kidBirthDay;
    console.log(this.kidBirth);
  },

  setFirstAddIndex(name) {
    this.firstAddIndex = name;
  },

  removeInput() {
    this.kidName = "";
    this.kidBirth = "";
    this.kidBirthYear = "";
    this.kidBirthMonth = "";
    this.kidBirthDay = "";
    this.kidId = ""; //아동고유번호
    this.kidProfile = "";
    this.description = "";
    this.checkList = [];
  },

  async setKidAdd() {
    await kidAddDB(
      this.kidName,
      this.kidId,
      this.kidProfile,
      this.kidBirth,
      this.description
    ).then((r) => {
      console.log(r);
      this.kidIndex = r.id;
      this.setFirstAddIndex(r.name);
      // for(let i=0; i<KidUiStore.filterTotalData.length;  i++) {
      //   console.log('KidUiStore,filterTotalData[i].name', KidUiStore.filterTotalData[i].name);
      //   console.log('KidUiStore.filterTotalData[i].name', r.name);
      //   console.log('KidUiStore.filterTotalData[i].name', KidUiStore.filterTotalData[i].name == r.name);
      //   if (KidUiStore.filterTotalData[i].name == r.name) {
      //     console.log('KidUiStore.filterTotalData[i].name', r.name);
      //     console.log('KidUiStore.filterTotalData[i].nameKidUiStore.filterTotalData[i].name',KidUiStore.filterTotalData[i].name);
      //     console.log('KidUiStore.filterTotalData[i].nameKidUiStore.filterTotalData[i].name',KidUiStore.filterTotalData[i].id);
      //   }
      //   if(KidUiStore.filterTotalData[i])
      // }
    });

    KidUiStore.setKidList();
    await KidUiStore.setKidReLoad();


    // this.removeInput();
  },

  setKidindex(idx) {
    // console.log('ddddddddddd', idx);
    // console.log('121213311233112133121', KidUiStore.filterTotalData);
    // console.log('지금 내가 필요한 index', KidUiStore.filterTotalData[idx -1].id);
    this.setKidIndex(KidUiStore.filterTotalData[idx -1].id);
    // console.log('kidIndex', this.kidIndex);
    this.setKidName(KidUiStore.filterTotalData[idx -1].name);
    this.setKidId(KidUiStore.filterTotalData[idx -1].kidId);
    this.setKidProfile(KidUiStore.filterTotalData[idx -1].profile);
    this.setDescription(KidUiStore.filterTotalData[idx -1].description);
    this.setKidBirth(KidUiStore.filterTotalData[idx -1].birth);
    let year = '';
    let month = '';
    let day = '';
    try {
      year = JSON.stringify(this.kidBirth).slice(0,4);
      month = JSON.stringify(this.kidBirth).slice(4,6);
      day = JSON.stringify(this.kidBirth).slice(-2);
    } catch (e) {
      console.log('errrrrr');
    }
    this.setKidBirthYear(year);
    this.setKidBirthMonth(month);
    this.setKidBirthDay(day);
    getKidUploadFileDB(KidUiStore.filterTotalData[idx-1].id).then(r=>{
      // console.log('getKidProfileImg', r);
      // for(let i=0; i<r.length; i++) {
      //   this.setFileImgs(r[i].fileName);
      //   console.log('r[i].fileName',r[i].fileName);
      // }
      // console.log('======', this.fileImgs);
      r[0] && this.setFileImg_01(r[0].fileName);
      r[1] && this.setFileImg_02(r[1].fileName);
      r[2] && this.setFileImg_03(r[2].fileName);
      r[3] && this.setFileImg_04(r[3].fileName);
      r[4] && this.setFileImg_05(r[4].fileName);
      r[0] && this.setFileImgId_01(r[0].faceId);
      r[1] && this.setFileImgId_02(r[1].faceId);
      r[2] && this.setFileImgId_03(r[2].faceId);
      r[3] && this.setFileImgId_04(r[3].faceId);
      r[4] && this.setFileImgId_05(r[4].faceId);
    });
  },

  deleteKid() {
    DelFaceUserDB(
      this.kidIndex,
      this.kidName,
      this.kidId,
      this.kidProfile,
      this.kidBirth,
      this.description
      ).then(async (r) => {
        console.log(r);
        this.fileImgId_01 !== null && await this.setDeleteImg(this.fileImgId_01);
        this.fileImgId_02 !== null && await this.setDeleteImg(this.fileImgId_02);
        this.fileImgId_03 !== null && await this.setDeleteImg(this.fileImgId_03);
        this.fileImgId_04 !== null && await this.setDeleteImg(this.fileImgId_04);
        this.fileImgId_05 !== null && await this.setDeleteImg(this.fileImgId_05);
      });
      KidUiStore.setKidList();
      this.removeInput();
      ManageUiStore.closeManage();
      KidUiStore.setKidReLoad();
    },


  updateKid() {
    // console.log('ddddd', this.kidIndex);
    UpdateFaceUserDB(
      this.kidIndex,
      this.kidName,
      this.kidId,
      this.kidProfile,
      this.kidBirth,
      this.description
      ).then((r) => {
        console.log(r);
      });
      // KidUiStore.setKidList();
      this.removeInput();
      ManageUiStore.closeManage();
      KidUiStore.setKidReLoad();
  },

  firstupdateKid(idx) {
    // console.log('idxidxidixidxiid', idx);
    // console.log('kidIasdfaldjfa',KidUiStore.totalPosts[idx -1].id);
    // console.log('dddd', this.kidName);
    UpdateFaceUserDB(
      KidUiStore.totalPosts[idx -1].id,
      this.kidName,
      this.kidId,
      this.kidProfile,
      this.kidBirth,
      this.description
      ).then((r) => {
        console.log(r);
      });
      KidUiStore.setKidList();
      this.removeInput();
      ManageUiStore.closeAdd();
      // ManageUiStore.closeManage();
  },

    setChangeStartTime(id) {
      // console.log('id', id);
      // let data = KidUiStore.totalPosts.find(el =>{
      //   return el.id === parseInt(id);
      // });
      let data = KidUiStore.totalPosts.find(el => el.id === parseInt(id));
      // console.log('changeValue', data);
      // console.log('original_id_data', data.id, 'id_data: ', id);
      console.log('startTime', this.kidStartTime);

      let time = Number(this.kidStartTime.slice(0,2));
      let minute = Number(this.kidStartTime.slice(3,6));
      // console.log('time: ', time);
      // console.log('minute: ', minute);
      // console.log('반영할출결상태정보: ', data.state);
      // console.log("반영되기전상태정보: ", this.attendanceState);
      if(data.state === '출석') {
        data.state = 'ATTENDANCE';
      } else if(data.state === '결석' || data.state === null || data.state === undefined) {
        data.state = 'ABSENCE';
      } else if(data.state === '병가') {
        data.state = 'SICK';
      } else if(data.state === '사고') {
        data.state = 'ACCIDENT';
      } else if(data.state === '입소') {
        data.state = 'ADMISSION';
      } else if(data.state === '퇴소') {
        data.state = 'EXIT';
      }
      // console.log('반영할출결상태정보: ', data.state);
      // console.log("반영되기전상태정보: ", this.attendanceState);

      // if(data.endTime === null || data.endTime === undefined) {
      //   let endTime =  null;
      //   data.endTime = endTime;
      // } else {
      //   data.endTime[5] = 0;
      //   // data.endTime = data.endTime.toString();
      // }
      // console.log("반영되기 전 시간: ", data.startTime);
      if(data.startTime === undefined || data.startTime === null) {
        // console.log("start_time: ", time);
        // console.log("start_minute: ", minute);
        let startTime = [];
        startTime[0] = this.kidYear;
        startTime[1] = this.kidMonth;
        startTime[2] = this.kidDay;
        startTime[3] = time;
        startTime[4] = minute;
        startTime[5] = 0;
        data.startTime = startTime;

        // console.log('startTime_time', data.startTime);
      } else {
        // data.startTime[0] = this.kidYear;
        // data.startTime[1] = this.kidMonth;
        // data.startTime[2] = this.kidDay;
        data.startTime[3] = time;
        data.startTime[4] = minute;
        data.startTime[5] = 0;
        // console.log('startTime_time', data.startTime);
      }
      // console.log('data.attendanceId', data.attendanceId);
      // console.log('data.id', data.id);
      // console.log('data.state', data.state);
      // console.log('data.state1:', this.attendanceState);
      // console.log('data.startTime', data.startTime);
      // console.log('data.endTime', data.endTime);
      // this.setAttendanceState('ATTENDANCE');
      // data.endTime
      updateStartTimeDB(data.attendanceId ,data.id, data.state, data.startTime, data.endTime).then(r => {
        // console.log("StateValueAtSetAttendance: ", r.state, " | ", r.startTime);
        if(r.state === 'ATTENDANCE') {
          this.setAttendanceStateKo('출석');
        } else if(r.state === 'ABSENCE' || r.state === undefined || r.state === null) {
          this.setAttendanceStateKo('결석');
        } else if(r.state === 'SICK') {
          this.setAttendanceStateKo('병가');
        } else if(r.state === 'ACCIDENT') {
          this.setAttendanceStateKo('사고');
        } else if(r.state === 'ADMISSION') {
          this.setAttendanceStateKo('입소');
        } else if(r.state === 'EXIT') {
          this.setAttendanceStateKo('퇴소');
        }
        data.state = this.attendanceStateKo;
      });
    },

    setChangeEndTime(id) {
      console.log('sesese');
      let data = KidUiStore.totalPosts.find(el => el.id === parseInt(id));

      let time = Number(this.kidEndTime.slice(0,2));
      let minute = Number(this.kidEndTime.slice(3,6));

      if(data.state === null || data.state === undefined) {
        let state = 'ABSENCE';
        data.state = state;
      } else if(data.state === '출석') {
        data.state = 'ATTENDANCE';
      } else if(data.state === '결석') {
        data.state = 'ABSENCE';
      } else if(data.state === '병가') {
        data.state = 'SICK';
      } else if(data.state === '사고') {
        data.state = 'ACCIDENT';
      } else if(data.state === '입소') {
        data.state = 'ADMISSION';
      } else if(data.state === '퇴소') {
        data.state = 'EXIT';
      }

      if(data.startTime === null || data.startTime === undefined) {
        let startTime =  null;
        data.startTime = startTime;
      } else {
        data.startTime[5] = 0;
        data.startTime = data.startTime.toString();
      }

      if(data.endTime === null || data.endTime === undefined) {
        let endTime = [];
        endTime[0] = this.kidYear;
        endTime[1] = this.kidMonth;
        endTime[2] = this.kidDay;
        endTime[3] = time;
        endTime[4] = minute;
        endTime[5] = 0;
        data.endTime = endTime;
        data.endTime = data.endTime.toString();
        console.log('endTime', data.endTime)
      } else {
        data.endTime[3] = time;
        data.endTime[4] = minute;
        data.endTime[5] = 0;
        data.endTime = data.endTime.toString();
      }
      // console.log('data.id', data.attendanceId);
      // console.log('data.id', data.id);
      // console.log('data.state', data.state);
      // console.log('data.startTime', data.startTime);
      // console.log('data.endTime', data.endTime);
      updateEndTimeDB(data.attendanceId ,data.id, data.state, data.startTime, data.endTime).then(r => console.log(r));
    },

    async saveDate(year, month, day) {
      await this.setKidYear(year);
      await this.setKidMonth(month);
      await this.setKidDay(day);
      this.setDateString();
    },

    async saveYesterDayDate(year, month, day) {
      // console.log('+++++++++++++++++')
      await KidAddUiStore.setYesterdayYear(year);
      await KidAddUiStore.setYesterdayMonth(month);
      await KidAddUiStore.setYesterdayDay(day);
      let setDate = `${this.yesterdayYear},${this.yesterdayMonth},${this.yesterdayDay}`.trim()
      this.setGetAttendanceDBStartDate(setDate);
      // console.log('this.getAttendanceDBStartDate', this.getAttendanceDBStartDate);
    },
    // value -> state | id -> id
    async updateAttendanceState(state, id) {
      // console.log('출결상태정보:', state, id);
      // console.log("현재출결상태정보: ", this.attendanceState);
      // console.log('data.startTime', data.startTime);
      // console.log('data.endTime', data.endTime);

      if(state === '출석') {
        this.setAttendanceState('ATTENDANCE');
      } else if(state === '결석') {
        this.setAttendanceState('ABSENCE');
      } else if(state === '병가') {
        this.setAttendanceState('SICK');
      } else if(state === '사고') {
        this.setAttendanceState('ACCIDENT');
      } else if(state === '입소') {
        this.setAttendanceState('ADMISSION');
      } else if(state === '퇴소') {
        this.setAttendanceState('EXIT');
      }
      let data = KidUiStore.totalPosts.find(el => el.id === id);
      let endTime;
      let startTime;
      // console.log('원아정보: ', data);
      // console.log('아이디: ', id);
      // console.log('받아온출결상태정보: ', state);
      // console.log("입력된출결상태정보: ", this.attendanceState);
      data.state = state;
      // data.state = this.attendanceState;

      if(data.startTime === null || data.startTime === undefined) {
        startTime =  this.getAttendanceDBDate + ',0,0,0';
        // console.log('startTime', startTime);
      } else {
        // console.log('ddd', typeof data.startTime);
        // console.log('data: ', data.startTime);
        data.startTime[5] = 0;
        startTime = data.startTime.toString();
        // startTime = data.startTime;
        console.log('startTimestartTime', startTime);
      }

      if(data.endTime === null || data.endTime === undefined) {
        endTime = this.getAttendanceDBDate + ',0,0,0';
      } else {
        data.endTime[5] = 0;
        endTime = data.endTime.toString();
      }

      {console.log("최종 DB 저장 전 정보: ", data.attendanceId, data.id, data.state, startTime, endTime, "| 출결상태: ", this.attendanceState)}
      // await updateStateDB(data.attendanceId, data.id, data.state, startTime, endTime).then(r => console.log('updateStateDBReult',r));
      await updateStateDB(data.attendanceId, data.id, this.attendanceState, startTime, endTime);
      await getAttendanceDB().then(r => {
        // console.log('getAttendanceDB', r);
        KidUiStore.setAattendanceData(r);
      });
      await getFaceUserDB().then(r => {
        // console.log('getFaceUserDB: ', r);
        KidUiStore.setPosts(r);
        KidUiStore.setTotalPosts();
        // console.log(KidUiStore.totalPosts);
      });
    },
// .then(data.state = state);
    async setUploadFile(data) {
      // console.log('datadatadatadata', data);
      let faceUserID = this.kidIndex;
      // console.log('kidIndex', this.kidIndex);

      await uploadFileDB(faceUserID, data.value).then(async r => {
        // console.log('setUploadFile', r);
        // console.log('setUploadFile', r.faceId);
        await getKidUploadFileDB(faceUserID).then(r=>{
          console.log('getKidUploadFileDB 갱신', r);
          r[0] && this.setFileImg_01(r[0].fileName);
          r[1] && this.setFileImg_02(r[1].fileName);
          r[2] && this.setFileImg_03(r[2].fileName);
          r[3] && this.setFileImg_04(r[3].fileName);
          r[4] && this.setFileImg_05(r[4].fileName);
          r[0] && this.setFileImgId_01(r[0].faceId);
          r[1] && this.setFileImgId_02(r[1].faceId);
          r[2] && this.setFileImgId_03(r[2].faceId);
          r[3] && this.setFileImgId_04(r[3].faceId);
          r[4] && this.setFileImgId_05(r[4].faceId);
          console.log('============this.setFileImg_01', this.fileImg_01);
          console.log('============this.setFileImg_02', this.fileImg_02);
          console.log('============this.setFileImg_03', this.fileImg_03);
          console.log('============this.setFileImg_04', this.fileImg_04);
          console.log('============this.setFileImg_05', this.fileImg_05);
        });
      });
    },

    async setUploadFiles(data) {
      // console.log('datadatadatadata', data);
      let faceUserID = this.uploadFileId
      // console.log('kidIndex', this.kidIndex);

      await uploadFileDB(faceUserID, data.value).then(async r => {
        // console.log('setUploadFile', r);
        // console.log('setUploadFile', r.faceId);
        await getKidUploadFileDB(faceUserID).then(r=>{
          // console.log('getKidUploadFileDB 갱신', r);
          r[0] && this.setFileImg_01(r[0].fileName);
          r[1] && this.setFileImg_02(r[1].fileName);
          r[2] && this.setFileImg_03(r[2].fileName);
          r[3] && this.setFileImg_04(r[3].fileName);
          r[4] && this.setFileImg_05(r[4].fileName);
          r[0] && this.setFileImgId_01(r[0].faceId);
          r[1] && this.setFileImgId_02(r[1].faceId);
          r[2] && this.setFileImgId_03(r[2].faceId);
          r[3] && this.setFileImgId_04(r[3].faceId);
          r[4] && this.setFileImgId_05(r[4].faceId);
          // console.log('============this.setFileImg_01', this.fileImg_01);
          // console.log('============this.setFileImg_02', this.fileImg_02);
          // console.log('============this.setFileImg_03', this.fileImg_03);
          // console.log('============this.setFileImg_04', this.fileImg_04);
          // console.log('============this.setFileImg_05', this.fileImg_05);
        });
      });
    },

    async setDeleteImg(data) {
      await deleteFileDB(data).then(async r=> {
        console.log('delteFileDB', r);
        await getKidUploadFileDB(this.kidIndex).then(r=> {
          console.log('getKidUploadFIleDB', r);
          this.setFileImg_01(null);
          this.setFileImg_02(null);
          this.setFileImg_03(null);
          this.setFileImg_04(null);
          this.setFileImg_05(null);
          r[0] && this.setFileImg_01(r[0].fileName);
          r[1] && this.setFileImg_02(r[1].fileName);
          r[2] && this.setFileImg_03(r[2].fileName);
          r[3] && this.setFileImg_04(r[3].fileName);
          r[4] && this.setFileImg_05(r[4].fileName);
          r[0] && this.setFileImgId_01(r[0].faceId);
          r[1] && this.setFileImgId_02(r[1].faceId);
          r[2] && this.setFileImgId_03(r[2].faceId);
          r[3] && this.setFileImgId_04(r[3].faceId);
          r[4] && this.setFileImgId_05(r[4].faceId);
        })
      });
      console.log('===', this.fileImgId_01);
    },

    getKidIndex() {

    },

    // async setUploadFaceFile(data) {
    //   let faceID = this.kidIndex;

    //   await uploadFaceFileDB

    // }





});

export { KidAddUiStore };
