import { observable } from 'mobx';
import { removeCookie, setCookie } from '../../utils/cookie';
import { postFetchJson } from '../../api/ServerApi';
import { ServerStore } from '../domain/ServerStore';
import { getCollectionLikuDB, getCollectionNameDB } from '../../api/SignApi';
import { LikuStore } from './LikuStore';
import { QuickMenuControlMotionUiStore } from './quickmenu/QuickMenuControlMotionUiStore';
import { QuickMenuControlScenarioUiStore } from './quickmenu/QuickMenuControlScenarioUiStore';
import { PlayAndEduMusicStore } from '../domain/PlayAndEduMusicStore';

const LoginUiStore = observable({
	account: null,
	URL: null,
	tokenJson: null,

	navigateValue: null,

	setNavigateValue(data) {
		this.navigateValue = data;
		// console.log('this.navigateValue', this.navigateValue);
	},

	isIdPassword(id, password) {
		if (id.replace(' ', '') !== '' && password.replace(' ', '') !== '')
			return true;
		else return false;
	},

	async login(id, password) {
		if (this.isIdPassword(id, password)) {
			await this.setAuthenticateData(id, password);
			await this.apiAuthenticate();
			console.log('tokenJson', this.tokenJson);
			if (this.tokenJson.token !== undefined && this.tokenJson.token !== null) {
				if (this.tokenJson.role.includes('WAIT')) {
					this.setNavigateValue(this.tokenJson.role);
				} else {
					setCookie('userName', this.tokenJson.name, {
						path: '/',
						maxAge: 43200,
					});
					setCookie('userUsage', this.tokenJson.usage, {
						path: '/',
						maxAge: 43200,
					});
					setCookie('userRole', this.tokenJson.role, {
						path: '/',
						maxAge: 43200,
					});
					setCookie('token', this.tokenJson.token, {
						path: '/',
						maxAge: 43200,
					});
					setCookie('managements', this.tokenJson.managements, {
						path: '/',
						maxAge: 43200,
					});
					PlayAndEduMusicStore.init(this.tokenJson.role);
					this.setNavigateValue(this.tokenJson.role);
					getCollectionNameDB().then((r) => {
						console.log('r', r);
						setCookie('collectionName', r.collection.name, {
							path: '/',
							maxAge: 43200,
							// domain: '.likuwithme.com',
						});
						getCollectionLikuDB().then((r) => {
							LikuStore.setLikus([]);
							for (let i = 0; i < r?.length; i++) {
								LikuStore.addLikus(r[i].liku);
								// LikuStore.likus.push(r[i].liku);
							}
							LikuStore.setThisLiku(r?.[0]?.liku);
							setCookie('likuSerial', r?.[0]?.liku?.serial, {
								path: '/',
								maxAge: 43200,
							});
							setCookie('likuUuid', r?.[0]?.liku?.uuid, {
								path: '/',
								maxAge: 43200,
								domain: '.likuwithme.com',
							});
							this.betaCookie();
							QuickMenuControlMotionUiStore.init();
							// QuickMenuControlScenarioUiStore.init();
						});
					});
				}
			} else {
				alert(
					`E-mail 또는 비밀번호를 잘못 입력했습니다.\n입력하신 내용을 다시 확인해주세요.`
				);
			}
		}
	},

	betaCookie() {
		console.log('betaCookie');
		setCookie('name', this.tokenJson.name, {
			path: '/',
			maxAge: 43200,
		});
		setCookie('usage', this.tokenJson.usage, {
			path: '/',
			maxAge: 43200,
		});
		setCookie('role', this.tokenJson.role, {
			path: '/',
			maxAge: 43200,
		});
		setCookie('token', this.tokenJson.token, {
			path: '/',
			maxAge: 43200,
		});
		setCookie('managements', this.tokenJson.managements, {
			path: '/',
			maxAge: 43200,
		});
	},

	setAuthenticateData(id, password) {
		this.account = { email: id, password: password };
		setCookie('useEmail', id, { path: '/', maxAge: 43200 });
	},
	//
	async apiAuthenticate() {
		await postFetchJson(
			`${ServerStore.BASE_URL}/authenticate`,
			this.account
		).then((r) => (this.tokenJson = r));
		// await authenticate(`http://192.168.1.87:8080/liku/authenticate`, this.account).then(r => this.tokenJson = r);
		// console.log('this.tokenJson.token', this.tokenJson.token);
	},

	async removeCookie() {
		await removeCookie('token', { path: '/' });
		setTimeout(() => console.log('removeCookie'), 43200);
	},
});

export { LoginUiStore };
