import { observable } from "mobx";

const ManageUiStore = observable({
  kindergardenName: '',
  kindergardenImg: '',
  kindergardenOption: [],

  kindergardenStateList: '91.5vh',
  kindergardenState: '-20vh',
  kindergardenSideState: true,
  kidcurAddState: false,
  kidcurManage: false,
  KidAddState: false,
  KidManage: false,
  stateTime: 'none',

  kindergardenSideTitle: '',

  setKindergardenSideTitle(title) {
    this.kindergardenSideTitle = title;
    // console.log(this.kindergardenSideTitle);
  },

  setCurrentState() {
    this.kidcurAddState = this.KidAddState;
    this.kidcurManage = this.KidManage;
  },

  setKidAddStateFalse() {
    this.KidAddState = false;
  },

  setKidAddStateTure() {
    this.KidAddState = true;
  },

  setKidManageTrue() {
    this.KidManage = true;
  },

  setKidManageFalse() {
    this.KidManage = false;
  },

  setkindergardenStateList(data) {
    this.kindergardenStateList = data;
  },

  setkindergardenState(data) {
    this.kindergardenState = data;
  },

  setkindergardenSideStateFalse() {
    this.kindergardenSideState = false;
  },
  setkindergardenSideStateTrue() {
    this.kindergardenSideState = true;
  },

  setStateTime(data) {
    this.stateTime = data;
  },

  setkindergardenName(name) {
    this.kindergardenName = name;
  },

  setkindergardenImg(img) {
    this.kindergardenImg = img;
  },

  setkindergardenOption(data) {
    this.kindergardenOption = data;
  },

  closeAdd() {
    this.setkindergardenSideStateTrue();
    this.setKidAddStateFalse();
    this.setKindergardenSideTitle('');
  },

  closeManage() {
    this.setkindergardenSideStateTrue();
    this.setKidManageFalse();
    this.setKindergardenSideTitle('');
  },

  kidManageContent() {
    this.setKidManageTrue();
    this.setkindergardenSideStateFalse();
  },



});

export {ManageUiStore};
