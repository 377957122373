import {observable} from 'mobx';
import {MqttStore} from '../MqttStore';
import {LikuStore} from "../../ui/LikuStore";

const QuickMenuControlStore = observable({
    defaultMotionData: null,

    reactionPub(json) {
        const topic = `liku/${LikuStore.thisLiku?.uuid}/json/list`;
        const message = {data: [json]};

        MqttStore.publish(topic, message);
    },

    reactionListPub(jsonList) {
        const topic = `liku/${LikuStore.thisLiku?.uuid}/json/list`;
        const message = {data: jsonList};

        MqttStore.publish(topic, message);
    },

    expressionPub(message) {
        const topic = `liku/${LikuStore.thisLiku?.uuid}/exp`;

        MqttStore.publish(topic, message);
    },

    stopPub() {
        const topic = `liku/${LikuStore.thisLiku?.uuid}/user/stop`;
        const message = 'stop';

        MqttStore.publish(topic, message);
    }
});

export {QuickMenuControlStore};
