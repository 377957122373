import ServerConfig from "./ServerConfig";
import PlayerConfig from "./PlayerConfig";
import ContentsConfig from "./ContentsConfig";
import MusicConfig from "./MusicConfig";
import ControlConfig from "./ControlConfig";
import ControlMotionConfig from "./ControlMotionConfig";

const RootConfig = () => {
  ServerConfig();
  ContentsConfig();
  PlayerConfig();
  MusicConfig();
  ControlConfig();
  ControlMotionConfig();
};

export default RootConfig;
