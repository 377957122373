import {observable, reaction} from "mobx";
import {QuickMenuControlMyExpressionUiStore} from "../../ui/quickmenu/QuickMenuControlMyExpressionUiStore";
import {setCookie} from "../../../utils/cookie";
// import Expression from "../../data/model/Expression";

const QuickMenuControlMyExpressionStore = observable({
    expressions: [],

    actions: null,
    displays: null,

    initExpressions(expressions) {
        if (expressions !== undefined && this.expressions.length === 0) {
            for (const expression of expressions) {
                this.addExpression(expression.tts, expression.action, expression.display);
            }
        }
    },

    addExpression(tts, action, display) {
        this.expressions.push(
            {
                tts: tts,
                action: action,
                display: display,
                type: 'my',
            },
        );
    },

    deleteExpression(index) {
        this.expressions.splice(index, 1);
    },

    setExpressions(index, tts, action, display) {
        if (index !== undefined) {
            this.expressions[index].tts = tts;
            this.expressions[index].action = action;
            this.expressions[index].display = display;

            setCookie('expressions', this.expressions, {path: '/', maxAge: 72000000});
            QuickMenuControlMyExpressionUiStore.setExpressions(this.expressions);
        } else {
            this.addExpression(tts, action, display);
        }
    },
});

reaction(
    () => QuickMenuControlMyExpressionStore.expressions.slice(),
    value => {
        setCookie('expressions', value, {path: '/', maxAge: 72000000});
        QuickMenuControlMyExpressionUiStore.setExpressions(value);
    }
);
reaction(
    () => QuickMenuControlMyExpressionStore.actions,
    value => {
        QuickMenuControlMyExpressionUiStore.setActions(value);
    }
);
reaction(
    () => QuickMenuControlMyExpressionStore.displays,
    value => {
        QuickMenuControlMyExpressionUiStore.setDisplays(value);
    }
);

export {QuickMenuControlMyExpressionStore};
