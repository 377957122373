import { observable, reaction, runInAction } from "mobx";
import mqtt from "mqtt";
import { PlayerLikuUiStore } from "../ui/player/PlayerLikuUiStore";
import { getCookie, setCookie } from "../../utils/cookie";
import { ContentsControlStore } from "./ContentsControlStore";
import { MetaverseContentsUiStore } from "../ui/metaverse/MetaverseContentsUiStore";
import { PlayAndEduMusicUiStore } from "../ui/playandedu/PlayAndEduMusicUiStore";
import { QuickMenuControlUiStore } from "../ui/quickmenu/QuickMenuControlUiStore";
import { LikuStore } from "../ui/LikuStore";
import { InteractiveStore } from "./InteractiveStore";
import { KidUiStore } from "../ui/KidUiStore";

const MqttStore = observable({
    client: null,
    isConnect: false,
    isReconnect: false,

    subscribes: new Set(),

    isHeartbeat: false,
    heartbeat: {},
    heartbeatInterval: null,
    publishInterval: null,
    publishTime: 45,

    MAX_PAYLOAD_COPY_SIZE: 15,
    payload_copy: {},
    payload_copy_time: {},
    payload_repub_tries: {},
    ack_uuid: 0,

    requestAck: [],

    reconnectCount: 0,

    setIsHeartbeat(isHeartbeat) {
        this.isHeartbeat = isHeartbeat;
    },

    async connect() {
        console.log("MqttStore connect");
        const url =
            "ws://ec2-54-180-207-182.ap-northeast-2.compute.amazonaws.com:15675";
        const options = {
            keepalive: 30,
            protocolId: "MQTT",
            protocolVersion: 4,
            clean: true,
            path: "/ws",
            reconnectPeriod: 1000,
            connectTimeout: 30 * 1000,
            will: {
                topic: "WillMsg",
                payload: "Connection Closed abnormally..!",
                qos: 0,
                retain: false,
            },
            rejectUnauthorized: false,
        };
        options.clientId = `mqttjs_${Math.random().toString(16).substr(2, 8)}`;
        options.username = "liku";
        options.password = "likuliku20";

        // const transformWsUrl = (url, options, client) => {
        //     console.log('signing a new url') // never seen
        //     return signUrl({
        //         regionName: process.env.AWS_REGION,
        //         endpoint: process.env.IOT_ENDPOINT,
        //         accessKey: process.env.AWS_KEY,
        //         secretKey: process.env.AWS_SECRET,
        //         expires: 3600
        //     });
        // };

        runInAction(() => {
            this.client = mqtt.connect(url, {
                ...options,
                transformWsUrl: (url, options, this.client),
            });
        });

        if (this.client) {
            this.client.on("error", (err) => {
                console.error("Connection error: ", err);
            });
            this.client.on("connect", () => {
                if (!this.isConnect) {
                    console.log("Client connected:", this.client);
                    runInAction(() => {
                        this.isConnect = true;
                    });

                    this.setPublishInterval();
                    LikuStore.likuStateSubscribe();
                    LikuStore.likuStatePublish();
                }
                // if (this.isReconnect) this.reSubscribe();
            });
            // this.client.on('end', (err) => {
            //     console.error('Client end: ', err);
            //     // this.reconnect();
            //     this.closeInit();
            // })
            this.client.on("disconnect", (err) => {
                console.error("Connection error: ", err);
                // this.reconnect();
                window.clearInterval(this.publishInterval);
                this.close();
            });
            this.client.on("close", () => {
                console.log("Client closed:");
                // this.reconnect();
                window.clearInterval(this.publishInterval);
                this.close();
            });
            // this.client.on('reconnect', () => {
            //     console.log('Client reconnect:');
            //     runInAction(() => {
            //         this.reconnectCount++;
            //     });
            //     // if (this,)
            //     this.reconnect();
            // });

            this.client.on("message", async (topic, message) => {
                const themMessage = message;
                let enc = new TextDecoder("utf-8");
                let arr = new Uint8Array(message);
                try {
                    message = JSON.parse(enc.decode(arr));
                } catch (error) {
                    // console.log('Data is not JSON');
                    message = enc.decode(arr);
                }
                // if (!topic.includes('heartbeat')) {
                //     console.log('topic', topic);
                //     console.log('message', message);
                // }

                console.log('mqtt topic', topic);
                console.log('mqtt message', message);
                // console.log('mqtt message data', message.data);

                if (
                    topic.includes("volume") ||
                    topic.includes("motion") ||
                    topic.includes("language")
                ) {
                    this.popRequestAck(topic);
                }

                if (topic.includes("heartbeat")) {
                    const topicUuid = topic?.split("/");
                    const uuid = topicUuid[1];
                    if (!(uuid in this.heartbeat)) {
                        await this.setHeartBeatInterval(uuid, topic);
                    } else {
                        await this.setHeartBeat(0, topic);
                    }
                    await this.check_Nack();
                } else if (topic.includes("ackCheck")) {
                    await this.subPayload(topic, message);
                } else if (topic.includes("language")) {
                    await PlayerLikuUiStore.likuStateCheck(topic, message);
                } else if (topic.includes("volume")) {
                    PlayerLikuUiStore.setVolume(topic, message);
                    LikuStore.setVolume(message);
                } else if (topic.includes("motion-status")) {
                    this.setIsHeartbeat(true);
                    LikuStore.setLikuState(message.status);
                } else if (topic.includes("check")) {
                    PlayerLikuUiStore.setIsCheckPub(true);
                } else if (message.data === "join") {
                    await PlayerLikuUiStore.likuJoinCheck(topic, message);
                } else if (!isNaN(message.data)) {
                    await PlayerLikuUiStore.setDownload(topic, message);
                } else if (message.data === "voice") {
                    await PlayerLikuUiStore.likuVoiceCheck(topic, message);
                } else if (message.data === "done") {
                    console.log("message done!!!!");
                    await PlayerLikuUiStore.setIsNext(topic, message);
                } else if (
                    topic.includes("/json/result") &&
                    message === "done"
                ) {
                    this.jsonResultDone(topic, message);
                } else if (topic.includes("/json/transcript")) {
                    console.log("@#@#transcript", topic, message);
                    InteractiveStore.setListenText(message);
                } else if (topic.includes("/aruco/result")) {
                    console.log("@#@#ArucoColor", message);
                    InteractiveStore.setArucoColor(message);
                } else if (
                    topic.includes("/json/result") &&
                    message === "musicDone"
                ) {
                    PlayAndEduMusicUiStore.musicDone();
                } else if (topic.includes("/vis/image/database")) {
                    await MetaverseContentsUiStore.reloadDB(topic, message);
                } else if (topic.includes("/vis/image/metaverse/whole")) {
                    await MetaverseContentsUiStore.setWholeImg(
                        topic,
                        themMessage
                    );
                } else if (topic.includes("/vision/image/user")) {
                    await KidUiStore.setRepresentImage(topic, themMessage);
                }
            });
        }
    },

    jsonResultDone(topic, message) {
        console.log(
            "QuickMenuControlUiStore.status",
            QuickMenuControlUiStore.status
        );
        // console.log('QuickMenuControlUiStore.isQuickMenuVisible', QuickMenuControlUiStore.isQuickMenuVisible);
        // if (PlayAndEduMusicUiStore.isMusicPlay && PlayAndEduMusicUiStore.status) {
        if (
            PlayAndEduMusicUiStore.isMusicPlay ||
            PlayAndEduMusicUiStore.status
        ) {
            PlayAndEduMusicUiStore.setIsMusicPlay(false);
            PlayAndEduMusicUiStore.setNextMusic();
        } else if (LikuStore.stateIng) {
            LikuStore.setStateIng(false);
        } else if (
            QuickMenuControlUiStore.status
            // ||
            // QuickMenuControlUiStore.isQuickMenuVisible
        ) {
            QuickMenuControlUiStore.executionDone();
        } else {
            PlayerLikuUiStore.setLikuConnect(topic, message);
        }
    },

    setPublishInterval() {
        this.publishInterval = window.setInterval(() => {
            if (this.publishTime > 0) this.publishTime -= 1;
            else {
                this.publishTime = 45;
                const email = getCookie("useEmail");
                this.publish(`${email}/heartbeat`, "heartbeat");
            }
        }, 1000);
    },

    async close() {
        // await PlayerLikuUiStore.likuAllStop();
        // await LikuStore.allUnSubscribe();

        console.log("Mqtt close Function:");
        this.client?.end(true, {}, () => {
            console.log("Client end:");
            this.closeInit();
            this.initHeartBeatInterval();
        });

        this.payload_copy = {};
        this.payload_copy_time = {};
        this.payload_repub_tries = {};
        this.ack_uuid = 0;
        //
        // this.isConnect = false;
        // this.client = null;
        //
        // console.log('mqtt close', this.client);

        // setTimeout(() => {
        //     this.reconnect();
        // }, 1000);
    },

    closeInit() {
        this.payload_copy = {};
        this.payload_copy_time = {};
        this.payload_repub_tries = {};
        this.ack_uuid = 0;

        this.subscribes = new Set();
        this.isReconnect = true;
        this.isConnect = false;
        // this.client = null;
        this.client?.reconnect();

        // console.log('mqtt close');

        // this.reconnect();
        // setTimeout(() => {
        //     // this.reSubscribe();
        //     this.connect();
        //     // LikuStore.connectCheckAndRetry();
        // }, 1000);
    },

    async reconnect() {
        // await LikuStore.allUnSubscribe();
        // this.isReconnect = true;
        // console.log('function reconnect');
        //
        // setTimeout(() => {
        //     LikuStore.connectCheckAndRetry(true);
        // }, 1000);
        // await this.client.reconnect();
        // await this.client.end();
        // await this.connect();
    },

    async reSubscribe() {
        // console.log('reSubscribe', LikuStore.state);
        // if (LikuStore.state === 'musical' || ContentsControlStore.start) await PlayerLikuUiStore.likuSubscribe(false);
        // else await LikuStore.connectCheckAndRetry();
        for (const [nack_uuid, nack_payload] of Object.entries(
            this.payload_copy
        )) {
            for (const [nack_topic, nack_data] of Object.entries(
                nack_payload
            )) {
                this.payload_repub_tries[nack_uuid] = 0;
                await this.rePublish(nack_topic, nack_data, nack_uuid);
            }
        }
    },

    // async reSubscribeEdu() {
    //     for (const [nack_uuid, nack_payload] of Object.entries(this.payload_copy)) {
    //         for (const [nack_topic, nack_data] of Object.entries(nack_payload)) {
    //             this.payload_repub_tries[nack_uuid] = 0;
    //             await this.rePublish(nack_topic,nack_data, nack_uuid);
    //         }
    //     }
    // },

    async setHeartBeatInterval(uuid, topic) {
        // console.log('setHeartBeatInterval isHeartBeat', this.isHeartbeat);
        // this.isHeartbeat = true;
        // console.log('setHeartBeatInterval uuid', uuid);
        // console.log('setHeartBeatInterval topic', topic);
        this.heartbeat[uuid] = 0;
        // if (Object.keys(this.heartbeat).length < 2) {
        if (!this.heartbeatInterval) {
            this.heartbeatInterval = window.setInterval(() => {
                const isConnectLiku = uuid === LikuStore.thisLiku?.uuid;
                const timeout = ContentsControlStore.progress ? 15 : 150;
                const closeHeartBeat = Object.keys(this.heartbeat).find(
                    (uuid) => this.heartbeat[uuid] === timeout
                );
                if (!isConnectLiku && closeHeartBeat && this.client) {
                    // if (closeHeartBeat) {
                    window.clearInterval(this.heartbeatInterval);
                }
                this.setHeartBeat();
                this.checkRequestAck();
            }, 1000);
        }

        // console.log('setting this.heartbeatInterval', this.heartbeatInterval);
    },

    async initHeartBeatInterval() {
        // console.log('initHeartBeatInterval');
        runInAction(() => this.setIsHeartbeat(false));
        try {
            // console.log('clearInterval');
            window.clearInterval(this.heartbeatInterval);
        } catch (e) {
            // console.log('clearInterval Error');
        }
        this.heartbeat = {};
        this.heartbeatInterval = null;

        // console.log('this.heartbeat', this.heartbeat);
        // console.log('this.heartbeatInterval', this.heartbeatInterval);
        // this.reconnect();
    },

    async setHeartBeat(num, topic) {
        if (!ContentsControlStore.loading) {
            const topicUuid = topic?.split("/");
            if (num !== undefined) {
                this.heartbeat[topicUuid[1]] = num;
                this.setIsHeartbeat(true);
            } else {
                for (let uuid of Object.keys(this.heartbeat)) {
                    this.heartbeat[uuid] += 1;
                }
            }
            // console.log('this.heartbeats', JSON.stringify(this.heartbeat));
            const timeout = ContentsControlStore.progress ? 15 : 150;

            const closeHeartBeat = Object.keys(this.heartbeat).find(
                (uuid) => this.heartbeat[uuid] === timeout
            );
            if (closeHeartBeat) {
                console.log("heartbeat 15!! ", closeHeartBeat);
                await this.initHeartBeatInterval();
            }
            // console.log(`this.heartbeat`, JSON.stringify(this.heartbeat));
        }
    },

    checkRequestAck() {
        const MAX_WAITING_TIME = 3; //ack response max waiting time (in seconds)

        const current_time = new Date();
        this.requestAck.forEach((obj) => {
            if (
                current_time.getTime() / 1000 - obj.date.getTime() / 1000 >=
                MAX_WAITING_TIME
            ) {
                this.setIsHeartbeat(false);
                this.requestAck = [];
            }
        });
    },

    addRequestAck(topic) {
        let type = null;
        if (topic.includes("volume")) type = "volume";
        else if (topic.includes("motion")) type = "motion";
        else if (topic.includes("language")) type = "language";

        this.requestAck.push({ type, date: new Date() });
    },

    popRequestAck(topic) {
        let type = null;
        if (topic.includes("volume")) type = "volume";
        else if (topic.includes("motion")) type = "motion";
        else if (topic.includes("language")) type = "language";

        const index = this.requestAck.findIndex((ack) => ack.type === type);
        if (index !== -1) this.requestAck.splice(index, 1);
        // console.log('this.requestAck', JSON.stringify(this.requestAck));
    },

    async publish(topic, payload) {
        console.log(topic, payload);

        const email = getCookie("useEmail");
        if (
            !(
                Object.keys(payload).includes("exp") ||
                topic.includes("volume") ||
                topic.includes(`${email}/heartbeat`) ||
                topic.includes("stop") ||
                topic.includes("motion") ||
                topic.includes("language") ||
                topic.includes("vis/image/metaverse") ||
                topic.includes("vis/image/wholeImg") ||
                topic.includes("check") ||
                topic.includes("aruco") ||
                topic.includes("ready")
            )
        ) {
            // && !topic.includes('check')
            payload = await this.addAckPayload(topic, payload);
            await this.setPayloadCopy(topic, payload);
        }

        // if (topic.includes('get') || topic.includes('set')) {
        if (
            topic.includes("volume") ||
            topic.includes("motion") ||
            topic.includes("language")
        ) {
            this.addRequestAck(topic);
        }

        if (typeof payload === "object") {
            try {
                payload = JSON.stringify(payload);
                console.log("payload Json stringify");
            } catch (error) {
                console.log("mqtt publish Json Stringify Error");
            }
        }

        if (this.client) {
            this.publishTime = 45;
            await this.client.publish(topic, payload, 0, (error) => {
                if (error) {
                    console.log("Publish error: ", error);
                }
            });
            console.log("publish topic", topic);
            console.log("publish payload", payload);
        }
    },

    async rePublish(topic, payload, uuid_) {
        this.payload_copy_time[uuid_] = new Date(); //update time
        //await this.setPayloadCopy(topic, payload);

        if (typeof payload === "object") {
            try {
                payload = JSON.stringify(payload);
            } catch (error) {
                // console.log('mqtt rePublish Json Stringify Error');
            }
        }
        if (this.client) {
            await this.client.publish(topic, payload, 0, (error) => {
                if (error) {
                    // console.log('rePublish error: ', error);
                }
            });
            console.log('rePublish topic', topic);
            console.log('rePublish payload', payload);
        }
    },

    async subscribe(topic) {
        if (this.client) {
            // console.log('subscribe', topic);
            if (!this.subscribes.has(topic)) {
                runInAction(() => {
                    this.subscribes.add(topic);
                });

                this.client.subscribe(topic, 0, (error) => {
                    if (error) {
                        // console.log('Subscribe to topics error', error)
                        return;
                    }
                });
            }
        }
    },

    async unSubscribe(topic) {
        if (this.client) {
            // console.log('unSubscribe : ', topic);
            if (this.subscribes.has(topic)) {
                this.subscribes.delete(topic);

                this.client.unsubscribe(topic, 0, (error) => {
                    if (error) {
                        // console.log('unSubscribe to topics error', error)
                        return;
                    }
                });
            }
        }
    },

    addAckPayload(topic, payload) {
        let ack_dict = {};
        const account = getCookie("useEmail");

        ack_dict[account + "/ackCheck"] = this.ack_uuid;
        const ackID = { ackID: ack_dict };

        // console.log('topic', topic);
        // console.log('payload', payload);
        // console.log('payloadType', typeof payload);

        if (topic.includes("list")) {
            payload.data.push(ackID);
        } else payload = Object.assign({}, payload, ackID);

        // console.log('ObjectClassPayload', payload);
        // console.log('ObjectClassPayloadType', typeof payload);

        return payload;
    },

    async setPayloadCopy(topic, payload) {
        if (
            Object.keys(this.payload_copy).length <= this.MAX_PAYLOAD_COPY_SIZE
        ) {
            let ack_payload_copy = {};
            ack_payload_copy[topic] = payload;
            this.payload_copy[this.ack_uuid] = ack_payload_copy; //copy payload and store
            this.payload_copy_time[this.ack_uuid] = new Date(); //record time at payload is copied

            this.ack_uuid = (this.ack_uuid + 1) % 10000;

            // console.log('setPayloadCopy', JSON.stringify(this.payload_copy));
            // console.log('setPayloadCopy payload_copy_time', JSON.stringify(this.payload_copy_time));
        }
    },

    async subPayload(topic, message) {
        // console.log('subPayload, ', message);
        const checked_uuid = parseInt(message);

        if (checked_uuid in this.payload_copy) {
            delete this.payload_copy[checked_uuid];
        }
        if (checked_uuid in this.payload_copy_time) {
            delete this.payload_copy_time[checked_uuid];
        }
        if (checked_uuid in this.payload_repub_tries) {
            delete this.payload_repub_tries[checked_uuid];
        }
        // console.log('subPayload', JSON.stringify(this.payload_copy));
        // console.log('subPayload payload_copy_time', JSON.stringify(this.payload_copy_time));
    },

    async check_Nack() {
        const MAX_WAITING_TIME = 3; //ack response max waiting time (in seconds)
        const MAX_NUM_RETRY = 1; //number of allowed re-pub tries
        const current_time = new Date();
        let exhausted_uuid_list = [];
        for (const [nack_uuid, nack_time] of Object.entries(
            this.payload_copy_time
        )) {
            try {
                // console.log('time', current_time.getTime()/1000 - nack_time.getTime()/1000);
                if (
                    current_time.getTime() / 1000 -
                        nack_time.getTime() / 1000 >=
                    MAX_WAITING_TIME
                ) {
                    const nack_payload = this.payload_copy[nack_uuid];
                    for (const [nack_topic, nack_data] of Object.entries(
                        nack_payload
                    )) {
                        if (nack_uuid in this.payload_repub_tries) {
                            console.log("nack_topic : ", nack_topic);
                            console.log(
                                "repub_tries : ",
                                this.payload_repub_tries[nack_uuid]
                            );
                            if (
                                ++this.payload_repub_tries[nack_uuid] >=
                                MAX_NUM_RETRY
                            ) {
                                exhausted_uuid_list.push(nack_uuid);
                                this.payload_repub_tries[nack_uuid] = 0;
                                this.close();
                            } else {
                                await this.rePublish(
                                    nack_topic,
                                    nack_data,
                                    nack_uuid
                                );
                            }
                        } else {
                            this.payload_repub_tries[nack_uuid] = 0;
                            await this.rePublish(
                                nack_topic,
                                nack_data,
                                nack_uuid
                            );
                        }
                    }
                }
            } catch (error) {
                console.log(error);
                continue;
            }
        }

        // if (exhausted_uuid_list.length > 0) {
        //     // await this.reconnect();
        //     for (let exhausted_uuid of exhausted_uuid_list) {
        //         if (exhausted_uuid in this.payload_copy){
        //             delete this.payload_copy[exhausted_uuid]
        //         }
        //         if (exhausted_uuid in this.payload_copy_time){
        //             delete this.payload_copy_time[exhausted_uuid]
        //         }
        //         if (exhausted_uuid in this.payload_repub_tries){
        //             delete this.payload_repub_tries[exhausted_uuid]
        //         }
        //     }
        // }
    },
});

reaction(
    () => MqttStore.client,
    (value) => {
        if (value === null) MqttStore.connect();
    }
);

export { MqttStore };
