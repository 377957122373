import { observable, reaction } from "mobx";
import { MetaverseContentsUiStore } from "../ui/metaverse/MetaverseContentsUiStore";

// 처리 과정이 복잡하거나 연산같은 처리가 많을때
// domain store 안의 여기 MetaverseStore에서 처리
const MetaverseContentsStore = observable({
  faceImageList: null,
  setFaceImageList(list) {
    this.faceImageList = list;
  },
});

reaction(
  () => MetaverseContentsStore.faceImageList,
  (value) => {
    MetaverseContentsUiStore.initFaceImageList(value);
  }
);

export { MetaverseContentsStore };
