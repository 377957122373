import RootStore from "../store";

import HeadTestJson from "../resource/json/control/reaction/HandTest.json";
import DanceJson from "../resource/json/control/expression/Dance.json";
import HighfiveJson from "../resource/json/control/reaction/Highfive.json";
import StupChairJson from "../resource/json/control/expression/StupChair.json";
import HandshakeJson from "../resource/json/control/reaction/Handshake.json";
import ClapJson from "../resource/json/control/reaction/Clap.json";
import FightingJson from "../resource/json/control/reaction/Fighting.json";
import LoveJson from "../resource/json/control/reaction/Love.json";
import PosePicture1Json from "../resource/json/control/reaction/PosePicture1.json";
import PosePicture2Json from "../resource/json/control/reaction/PosePicture2.json";
import HiJson from "../resource/json/control/reaction/Hi.json";
import StretchingJson from "../resource/json/control/reaction/stretching_arm.json";
import SitDownChairJson from "../resource/json/control/expression/SitDownChair.json";

import ApproachAppJson from "../resource/json/control/expression/ApproachApp.json";
import RecedeAppJson from "../resource/json/control/expression/RecedeApp.json";

const ControlConfig = async () => {
  const {
    QuickMenuControlStore,
    QuickMenuControlUiStore,
    QuickMenuControlMotionUiStore,
  } = RootStore();

  QuickMenuControlStore.defaultMotionData = {
    action: {
      action_name: "None",
      action_type: "overlap",
      yaw: 0,
      pitch: 0,
    },
    display: {
      display_name: "blink",
      delay: 0,
      playtime: 1,
      playspeed: 1,
    },
    speech: {
      speech_name: "TTS_output",
      TTS: "",
      delay: 0,
      repeat: 1,
      // speed: -1,
      // pitch: -1,
    },
  };

  //   QuickMenuControlUiStore.defaultFunctionList = [
  //     {
  //       name: "앉기",
  //       info: "의자에 앉습니다.",
  //       warning: "반드시 의자가 리쿠가 앉을 수 있는 위치에 있는지 확인해주세요.",
  //       // warning: '',
  //       json: SitDownChairJson,
  //       type: "expression",
  //     },
  //     {
  //       name: "일어나기",
  //       info: "의자에서 일어납니다.",
  //       warning: "",
  //       json: StupChairJson,
  //       type: "expression",
  //     },
  //     {
  //       name: "하이파이브",
  //       info: "하이파이브를 합니다.",
  //       warning: "",
  //       json: HighfiveJson,
  //       type: "reaction",
  //     },
  //     {
  //       name: "안녕!",
  //       info: "인사를 합니다.",
  //       warning: "",
  //       json: HiJson,
  //       type: "reaction",
  //     },
  //     {
  //       name: "악수",
  //       info: "악수를 청합니다.",
  //       warning: "",
  //       json: HandshakeJson,
  //       type: "reaction",
  //     },
  //     {
  //       name: "박수",
  //       info: "두 손을 모아 박수를 칩니다.",
  //       warning: "",
  //       json: ClapJson,
  //       type: "reaction",
  //     },
  //     {
  //       name: "화이팅!",
  //       info: "응원을 합니다.",
  //       warning: "",
  //       json: FightingJson,
  //       type: "reaction",
  //     },
  //     {
  //       name: "사랑해",
  //       info: "사랑해를 합니다.",
  //       warning: "",
  //       json: LoveJson,
  //       type: "reaction",
  //     },
  //     {
  //       name: "기지개",
  //       info: "기지개를 핍니다.",
  //       warning: "",
  //       json: StretchingJson,
  //       type: "reaction",
  //     },
  //     {
  //       name: "목 스트레칭",
  //       info: "목 스트레칭을 합니다.",
  //       warning: "",
  //       json: HeadTestJson,
  //       type: "reaction",
  //     },
  //   ];

  // QuickMenuControlUiStore.entertainmentList = [
  //     {
  //         name: '댄스1',
  //         info: '리쿠 송',
  //         warning: '',
  //         json: DanceJson,
  //         type: 'expression',
  //     },
  //     {
  //         name: '사진포즈1',
  //         info: '한쪽팔 얼굴에 대는 포즈',
  //         warning: '',
  //         json: PosePicture1Json,
  //         type: 'reaction',
  //     },
  //     {
  //         name: '사진포즈2',
  //         info: '두손 얼굴에 대고 김치~',
  //         warning: '',
  //         json: PosePicture2Json,
  //         type: 'reaction',
  //     },
  // ];

  // QuickMenuControlUiStore.walkingList = [
  //     {
  //         name: '걷기(앞)',
  //         info: '앞으로 걸어갑니다.',
  //         warning: '',
  //         json: ApproachAppJson,
  //         type: 'expression',
  //     },
  //     {
  //         name: '걷기(뒤)',
  //         info: '뒤로 걸어갑니다.',
  //         warning: '',
  //         json: RecedeAppJson,
  //         type: 'expression',
  //     },
  // ];

  QuickMenuControlUiStore.initExecutionList();
};

export default ControlConfig;
