import React, {lazy} from 'react';
import {getCookie} from "../utils/cookie";

const EduPage = lazy(() => import("../view/page/EduPage"));
const EduSeniorPage = lazy(() => import("../view/page/EduSeniorPage"));

const EduRouter = () => {
    const role = getCookie('userRole');
    // if (role.includes('ADMIN')) {
    //     return window.confirm('어떤 버전으로 사용하시겠습니까?',)
    // }
    return role.includes('SENIOR') ? <EduSeniorPage /> : <EduPage />;
}

export default EduRouter;
