import { observable } from "mobx";
import SignupRepository from "../../data/repository/SignRepository";
import {getCookie} from "../../utils/cookie";

const SignupUiStore = observable({
  usage: '사용처를 선택해주세요!',
  name: '',
  email: '',
  password: '',
  list : [{ title: '공공기관'}, { title: '교육기관'}, {title: '테마파크'}, {title: '개인'}],

  newPassword: '',
  passwordConfirmCurrent: '',

  isEmail: false,
  isPassword: false,
  isVisble: false,
  isNewPassword: false,
  isPasswordConfirm: false,

  navigateValue: false,
  navigateChangeValue: false,

  firstChangePassword: '',

  setSignup() {
    const signupData = {
      usage: this.usage,
      name: this.name,
      email: this.email,
      password: this.password,
    };

    SignupRepository.signup(signupData).then(r => {
      console.log('setSignup', r);
      if (r === 'success') {
        // this.setCollection();
        this.setNavigateValue(true);
      } else {
        console.log('중복되었습니다.');
        alert('인증되지 않은 사용자 입니다.');
      }
    });
  },

  // setCollection() {
  //   authenticate(this.email, this.password).then(r => {
  //     console.log('token', r);
  //     let token = r.token;
  //     let name = this.email.split('@');
  //     console.log('name', name[0]);
  //     collectionDB(name[0], token).then(r2 => {
  //       console.log('collectionDB result', r2);
  //       if (r2 === "200") {
  //         this.setProfile(token);
  //       }
  //     })
  //   });
  // },
  //
  // setProfile(token) {
  //   profileDB(this.email, token).then(r => {
  //     console.log('setProfile result', r);
  //     this.setAddCollectionMember(token);
  //   })
  // },
  //
  // setAddCollectionMember(token) {
  //   addCollectionMember(this.email, token).then(r => {
  //     console.log('collection 생성');
  //     this.setNavigateValue(true);
  //     this.resetSignupData();
  //   });
  // },

  setChangePassowrd() {
    const usage = getCookie('userUsage');
    const name = getCookie('userName');
    const email = getCookie('useEmail');

    const setPassword = JSON.stringify({
      usage: usage,
      name: name,
      email: email,
      password: this.firstChangePassword,
    });

    SignupRepository.setPassword(setPassword).then(r => {
      console.log('setPassword', r);
      if (r === 'success') {
        this.setNavigateChangeValue(true);
      } else {
        console.log('비밀번호 변경 error', r);
      }
    });
  },

  setEmail(email) {
    this.email = email;
  },

  setUsage(usage) {
    this.usage = usage;
  },

  setName(name) {
    this.name = name;
  },

  setPassword(password) {
    this.password = password;
  },

  setIsEmail(a) {
    this.isEmail = a;
  },

  setIsPassword(a) {
    this.isPassword = a;
  },

  setIsPasswordConfirm(a) {
    this.isPasswordConfirm = a;
  },

  setIsNewPassword(a) {
    this.isNewPassword = a;
  },

  setPasswordConfirmCurrent(password) {
    this.passwordConfirmCurrent = password;
  },

  setNewPassword(password) {
    this.newPassword = password;
  },

  setFirstChangePassword(password) {
    this.firstChangePassword = password;
  },

  setIsVisible(a) {
    this.isVisble = a;
  },

  setNavigateValue(c) {
    this.navigateValue = c;
  },

  setNavigateChangeValue(c) {
    this.navigateChangeValue = c;
  },

  onChangeVisible() {
    if (this.isEmail === true && this.isPassword === true) {
      this.setIsVisible(true);
    }
  },

  resetSignupData() {
    this.setUsage('사용처를 선택해주세요!');
    this.setEmail('');
    this.setName('');
    this.setPassword('');
  },

});

export {SignupUiStore};
