import { PlayerUiStore } from "./ui/player/PlayerUiStore";
import { PlayerStore } from "./domain/PlayerStore";
import { PlayerRoleUiStore } from "./ui/player/PlayerRoleUiStore";
import { PlayerLikuUiStore } from "./ui/player/PlayerLikuUiStore";
import { PlayerBackgroundUiStore } from "./ui/player/PlayerBackgroundUiStore";
import { ContentsControlStore } from "./domain/ContentsControlStore";
import { SignupUiStore } from "./ui/SignupUiStore";
import { LoginUiStore } from "./ui/LoginUiStore";
import { ManageUiStore } from "./ui/ManageUiStore";
import { ProfileUiStore } from "./ui/ProfileUiStore";
import { ContentsUiStore } from "./ui/ContentsUiStore";
import { FilterUiStore } from "./ui/FilterUiStore";
import { KidAddUiStore } from "./ui/KidAddUiStore";
import { KidUiStore } from "./ui/KidUiStore";
import { LikuStore } from "./ui/LikuStore";
import { ContentsStore } from "./domain/ContentsStore";
import { MqttStore } from "./domain/MqttStore";
import { PlayAndEduMusicUiStore } from "./ui/playandedu/PlayAndEduMusicUiStore";
import { PlayAndEduMusicStore } from "./domain/PlayAndEduMusicStore";
import { MetaverseStore } from "./domain/MetaverseStore";
import { MetaverseUiStore } from "./ui/metaverse/MetaverseUiStore";
import { MetaverseContentsStore } from "./domain/MetaverseContentsStore";
import { MetaverseContentsUiStore } from "./ui/metaverse/MetaverseContentsUiStore";
import { QuickMenuControlUiStore } from "./ui/quickmenu/QuickMenuControlUiStore";
import { QuickMenuControlStore } from "./domain/quickmenu/QuickMenuControlStore";
import { QuickMenuControlMyExpressionStore } from "./domain/quickmenu/QuickMenuControlMyExpressionStore";
import { QuickMenuControlMyExpressionUiStore } from "./ui/quickmenu/QuickMenuControlMyExpressionUiStore";
import { QuickMenuControlScenarioUiStore } from "./ui/quickmenu/QuickMenuControlScenarioUiStore";
import { QuickMenuControlScenarioStore } from "./domain/quickmenu/QuickMenuControlScenarioStore";
import { QuickMenuControlMotionUiStore } from "./ui/quickmenu/QuickMenuControlMotionUiStore";
import { QuickMenuControlMotionStore } from "./domain/quickmenu/QuickMenuControlMotionStore";

import { InteractiveStore } from "./domain/InteractiveStore";
import { InteractiveUiStore } from "./ui/interactive/InteractiveUiStore";
import { ModeUiStore } from './ui/ModeUiStore';

const RootStore = () => {
  return {
    ContentsControlStore,
    SignupUiStore,
    LoginUiStore,
    ProfileUiStore,
    ManageUiStore,
    ContentsStore,
    ContentsUiStore,
    PlayerStore,
    PlayerUiStore,
    PlayerRoleUiStore,
    PlayerBackgroundUiStore,
    PlayerLikuUiStore,
    FilterUiStore,
    KidAddUiStore,
    KidUiStore,
    MetaverseStore,
    MetaverseUiStore,
    MetaverseContentsStore,
    MetaverseContentsUiStore,
    LikuStore,
    MqttStore,
    PlayAndEduMusicStore,
    PlayAndEduMusicUiStore,

    QuickMenuControlStore,
    QuickMenuControlUiStore,

    QuickMenuControlMyExpressionStore,
    QuickMenuControlMyExpressionUiStore,
    QuickMenuControlScenarioUiStore,
    QuickMenuControlScenarioStore,

    QuickMenuControlMotionUiStore,
    QuickMenuControlMotionStore,

    InteractiveStore,
    InteractiveUiStore,

    ModeUiStore,
  };
};

export default RootStore;
