import { observable, runInAction } from "mobx";
import { PlayerRoleUiStore } from "./PlayerRoleUiStore";
import { getCookie } from "../../../utils/cookie";
import LikuRepository from "../../../data/repository/LikuRespository";
import { MetaverseContentsUiStore } from "../metaverse/MetaverseContentsUiStore";
import { MqttStore } from "../../domain/MqttStore";
import { ContentsControlStore } from "../../domain/ContentsControlStore";
import KorToEn from "../../../utils/KorToEn";
import { PlayerUiStore } from "./PlayerUiStore";
import { LikuStore } from "../LikuStore";
import { PlayAndEduMusicUiStore } from "../playandedu/PlayAndEduMusicUiStore";
import { KidUiStore } from "../KidUiStore";

const PlayerLikuUiStore = observable({
    likuUuids: {},
    isNexts: {},

    thisConnectLiku: null,

    connectTimeOutInterval: null,
    connectTimeOut: 15,

    isDownload: false,
    isMusicPlay: false,
    // isLoadComplete: false,
    totalDownload: 0,
    progressBarLoading1: false,
    progressBarLoading2: false,
    progressBarLoading3: false,

    downloadTimeOutInterval: null,
    downloadTimeOut: 10,
    downloadRetry: false,
    isDownloading: false,

    musicalReadyInterval: null,
    musicalReadyTimeOut: 2,
    musicalReadyRetry: false,

    isCheckPub: false,

    errorModal: false,

    clear() {
        if (
            !(
                PlayAndEduMusicUiStore.status ||
                PlayAndEduMusicUiStore.isMusicPlay
            )
        )
            this.likuAllStop();
        this.initConnectTimeOut();
        this.initRole();
        this.setConnectTimeOut(15);
        this.setIsDownload(false);
        this.totalDownload = 0;

        this.initDownloadTimeOut();
        this.setDownloadTimeOut(10);
        this.downloadRetry = false;
        this.setIsCheckPub(false);
        this.errorModal = false;
        this.isDownloading = false;
        this.progressBarLoading1 = false;
        this.progressBarLoading2 = false;
        this.progressBarLoading3 = false;
        MqttStore.unSubscribe(`liku/musical/${PlayerUiStore.uuid}/check`);
    },

    initRole() {
        this.likuUuids = {};
    },

    resetRole() {
        for (const serial of Object.keys(this.likuUuids)) {
            this.likuUuids[serial].join = false;
            this.likuUuids[serial].voice = false;
        }
    },

    setThisConnectLiku(role, serial, uuid) {
        if (role && serial && uuid) {
            this.thisConnectLiku = {
                serial: serial,
                role: role,
                uuid: uuid,
            };
        } else {
            this.thisConnectLiku = null;
        }
    },

    setConnectTimeOutInterval(connectTimeOutInterval) {
        this.connectTimeOutInterval = connectTimeOutInterval;
    },

    async getLikuUuid() {
        const liku = getCookie("likuSerial");
        const message = { serial: liku };
        const role = Object.keys(PlayerRoleUiStore.roleColor)[0];
        await LikuRepository.getLikuInfo(message).then(async (r) => {
            await this.setThisConnectLiku(role, liku, r.uuid);
            MetaverseContentsUiStore.setContentUUID(this.thisConnectLiku?.uuid);
            KidUiStore.setLikuUUID(this.thisConnectLiku?.uuid);
            this.likuSubscribe(true).then(() => {
                this.getLanguageTopic(this.thisConnectLiku?.uuid);
            });
        });
    },

    async likuStateCheck(language) {
        if (language.language === "en")
            await this.setLanguageTopic(this.thisConnectLiku.uuid, "ko");
        await this.likuTest(
            this.thisConnectLiku.uuid,
            this.thisConnectLiku.role
        );
    },

    async likuSubscribe(isFirst) {
        if (LikuStore.state === "musical") {
        }
        const ackCheckTopic = `liku/${getCookie("useEmail")}/ackCheck`;
        await MqttStore.subscribe(ackCheckTopic);
        if (!isFirst) {
            // 첫 연결이 아니면
            ContentsControlStore.musicalSubscribe();

            await this.likuStateSubscribe(this.thisLiku?.uuid);
        } else {
            // 첫 연결일 때
            const jsonResultTopic = `liku/${this.thisConnectLiku?.uuid}/json/result`;
            const languageTopic = `liku/${this.thisConnectLiku?.uuid}/language/response`;
            const volumeTopic = `liku/${this.thisConnectLiku?.uuid}/volume`;
            await MqttStore.subscribe(jsonResultTopic);
            await MqttStore.subscribe(languageTopic);
            await MqttStore.subscribe(volumeTopic);
            if (PlayerUiStore.isMetaverse() || PlayerUiStore.isBirthday()) {
                this.metaverseInit();
            }
        }
    },

    async metaverseInit() {
        const MetaverseDBUpdatedTopic = `liku/${this.thisConnectLiku?.uuid}/vis/image/database`;
        await MqttStore.subscribe(MetaverseDBUpdatedTopic);

        const MetaverseDBAddedTopic = `liku/${this.thisConnectLiku?.uuid}/vis/image/metaverse/whole`;
        await MqttStore.subscribe(MetaverseDBAddedTopic);

        // musical mode setting
        const topic = `liku/${this.thisConnectLiku?.uuid}/musical/test`;
        const message = { mode: "musical" };
        await MqttStore.publish(topic, message);
    },

    async likuStateSubscribe(uuid) {
        const heartbeatTopic = `liku/${uuid}/heartbeat`;
        const mqttTopic = `liku/${uuid}/volume`;
        await MqttStore.subscribe(heartbeatTopic);
        await MqttStore.subscribe(mqttTopic);
        await MqttStore.setHeartBeatInterval(uuid);
    },

    setLikuUuid() {
        const serial = this.thisConnectLiku?.serial;
        const role = this.thisConnectLiku?.role;
        const uuid = this.thisConnectLiku?.uuid;

        this.likuUuids[serial] = {
            role: role,
            uuid: uuid,
            connect: false,
            join: false,
            voice: false,
            volume: 0,
            download: 0,
        };
    },

    async findKey(data) {
        const serial_list = Object.keys(this.likuUuids);
        return serial_list.find((key) => {
            const name = KorToEn(this.likuUuids[key].role);
            return name === data.role;
        });
    },

    setLikuConnect(topic, data) {
        this.setLikuUuid();
        this.setThisConnectLiku();
        let uuid = topic.split("/");
        uuid = uuid[1];
        let key = this.getSerialByUuid(uuid);
        this.likuUuids[key].connect = true;
        this.initConnectTimeOut();
        PlayerRoleUiStore.setLikuRole(key);
        this.likuStateSubscribe(uuid).then(() => {
            MqttStore.unSubscribe(
                `liku/${this.likuUuids[key].uuid}/json/result`
            );
            MqttStore.unSubscribe(
                `liku/${this.likuUuids[key].uuid}/language/response`
            );
            this.getVolumeTopic(this.likuUuids[key].uuid);
        });
        if (PlayerUiStore.isMetaverse()) {
            MetaverseContentsUiStore.requestLikuMemory();
            MetaverseContentsUiStore.init();
            if (
                PlayAndEduMusicUiStore.status === false &&
                PlayAndEduMusicUiStore.index === 0
            ) {
                PlayerUiStore.setMetaverseModal(true);
            }
        }
        /////////////
        else if (PlayerUiStore.isBirthday()) {
            if (
                PlayAndEduMusicUiStore.status === false &&
                PlayAndEduMusicUiStore.index === 0
            ) {
                console.log("birthdayModal set True !!!!!");
                PlayerUiStore.setBirthdayModal(true);
            }
        }
    },

    setIsCheckPub(value) {
        this.isCheckPub = value;
    },

    setMusicalReady() {
        this.setMusicalReadyTopic();
        setTimeout(() => this.setMusicalReadyInterval());
    },

    setMusicalReadyInterval() {
        if (!this.musicalReadyInterval && !this.isDownload) {
            this.musicalReadyInterval = setInterval(() => {
                const isNotJoin = Object.keys(this.likuUuids).find(
                    (key) => this.likuUuids[key].join === false
                );
                if (isNotJoin === undefined || isNotJoin === "undefined") {
                    window.clearInterval(this.musicalReadyInterval);
                    this.musicalReadyInterval = null;
                    this.musicalReadyTimeOut = 2;
                } else if (
                    this.musicalReadyTimeOut <= 0 ||
                    !this.musicalReadyInterval
                ) {
                    window.clearInterval(this.musicalReadyInterval);
                    this.musicalReadyInterval = null;
                    this.musicalReadyTimeOut = 2;

                    if (!this.musicalReadyRetry) {
                        this.musicalReadyRetry = true;
                        this.setMusicalReady();
                    } else {
                        this.errorModal = true;
                        this.musicalReadyRetry = false;
                    }
                } else {
                    this.musicalReadyTimeOut -= 1;
                }
            }, 1000);
        }
    },

    setMusicalReadyTopic() {
        let topic = `liku/${LikuStore.thisLiku?.uuid}/musical/ready`;
        let message = "?";
        MqttStore.publish(topic, message);
    },

    async likuJoinCheck(topic, data) {
        if (data !== undefined) {
            const key = await this.findKey(data);
            try {
                this.likuUuids[key].join = true;
            } catch (e) {
                console.log("e");
            }
        }

        const isNotJoin = Object.keys(this.likuUuids).find(
            (key) => this.likuUuids[key].join === false
        );

        console.log("isNotJoin", isNotJoin);
        if (isNotJoin === undefined || isNotJoin === "undefined") {
            console.log("isNotJoin if");
            this.setIsDownload(true);
            const topic = `liku/musical/${PlayerUiStore.uuid}/check`;
            const payload = {
                contentsUuid: PlayerUiStore.contents?.uuid,
            };
            console.log("isNotJoin topic", topic);
            console.log("isNotJoin payload", payload);
            await MqttStore.publish(topic, payload);

            this.setDownloadTimeOutInterval();
        }
    },

    setDownloadTimeOutInterval() {
        console.log("setDownloadTimeOutInterval");
        if (this.downloadTimeOutInterval === null && this.isDownload) {
            this.downloadTimeOutInterval = setInterval(() => {
                if (
                    this.downloadTimeOut <= 0 ||
                    this.downloadTimeOutInterval === null ||
                    !this.isDownload
                ) {
                    // console.log('==========setInterval clearInterval==========');
                    window.clearInterval(this.downloadTimeOutInterval);
                    this.downloadTimeOutInterval = null;

                    if (!this.downloadRetry) {
                        console.log("setDownloadTimeOutInterval, retry");
                        this.setDownloadTimeOut(10);
                        this.downloadRetry = true;
                        this.likuJoinCheck();
                    } else {
                        this.errorModal = true;
                    }
                } else {
                    this.setDownloadTimeOut();
                    // setTimeout(() => {
                    console.log("isCheckPub", this.isCheckPub);
                    if (!this.isCheckPub) {
                        window.clearInterval(this.downloadTimeOutInterval);
                        this.downloadTimeOutInterval = null;
                        this.errorModal = true;
                        MqttStore.close();
                    }
                    // });
                }
            }, 1000);
        }
    },

    initDownloadTimeOut() {
        console.log("==========initConnectTimeOut==========");
        window.clearInterval(this.downloadTimeOutInterval);
        this.downloadTimeOutInterval = null;
        this.downloadRetry = false;
    },

    setDownloadTimeOut(time) {
        if (time !== undefined) this.downloadTimeOut = time;
        else this.downloadTimeOut -= 1;

        console.log(" this.downloadTimeOut", this.downloadTimeOut);

        // if (this.downloadTimeOut <= 0) this.initDownloadTimeOut();
    },

    setIsDownload(value) {
        this.isDownload = value;
    },

    async setDownload(topic, data) {
        if (!this.isDownloading) {
            console.log("this.isDownloading if");
            this.isDownloading = true;
            this.initDownloadTimeOut();
            this.setDownloadTimeOutInterval();
        } else {
            console.log("this.isDownloading else ");
            this.setDownloadTimeOut(10);
        }

        const key = await this.findKey(data);
        runInAction(() => {
            try {
                this.likuUuids[key].download = parseInt(data.data);
            } catch (e) {
                console.log("setDownload error");
            }
        });

        let sumDownload = 0;
        for (const key of Object.keys(this.likuUuids)) {
            try {
                sumDownload += this.likuUuids[key].download;
            } catch (e) {
                console.log("setDownload error 2");
            }
        }

        runInAction(() => {
            this.totalDownload = parseInt(
                (sumDownload / (Object.keys(this.likuUuids).length * 100)) * 100
            );
        });
    },

    async likuVoiceCheck(topic, data) {
        console.log("likuVoiceCheck");
        (() => {
            return new Promise((resolve, reject) => {
                const checkLoading = () => {
                    // 여기에 값이 바뀌는 조건을 확인하는 코드를 작성합니다.
                    console.log("progressBar checkLoding");
                    if (
                        (this.progressBarLoading1 &&
                            this.progressBarLoading2 &&
                            this.progressBarLoading3) ||
                        PlayerUiStore.isInteractive()
                    ) {
                        resolve();
                    } else {
                        setTimeout(checkLoading, 100); // 일정 시간 후에 다시 확인합니다.
                    }
                };
                checkLoading(); // 최초로 한 번은 바로 확인합니다.
            });
        })().then(async () => {
            let key = await this.findKey(data);
            try {
                this.likuUuids[key].voice = true;
                let isNotVoice = Object.keys(this.likuUuids).find(
                    (key) => this.likuUuids[key]?.voice === false
                );
                console.log("likuVoiceCheck key", this.likuUuids[key]);
                if (isNotVoice === undefined || isNotVoice === "undefined") {
                    this.initDownloadTimeOut();
                    this.isDownloading = false;

                    this.setIsDownload(false);
                    ContentsControlStore.setLoading(false);
                    // if(this.category === 'METAVERSE') { //metaverse!@#$
                    //     this.isLoadComplete = true;
                    // }
                    await ContentsControlStore.contentsIng();
                    this.setIsDownload(false);
                    await MqttStore.setHeartBeat(
                        0,
                        `liku/${this.likuUuids[key].uuid}/heartbeat`
                    );
                }
            } catch (e) {
                console.log("likuVoiceCheck error", e);
            }
        });
    },

    initIsNexts(data) {
        // console.log(data);
        this.isNexts = {};
        for (let i of data) {
            let role = KorToEn(i?.role || "나래이션");
            this.isNexts[role] = false;
        }
    },

    setIsNext(topic, data) {
        if (ContentsControlStore.progress) {
            this.isNexts[data.role] = true;

            let isNotNexts = Object.keys(this.likuUuids).find((key) => {
                let role = KorToEn(this.likuUuids[key].role);
                return this.isNexts[role] === false;
            });
            // console.log("isNotNexts", isNotNexts);
            if (isNotNexts === undefined) {
                if (ContentsControlStore.progress)
                    ContentsControlStore.contentsIng();
            }
        }
    },

    async likuTest(uuid, role) {
        let topic = `liku/${uuid}/json/list`;
        let display = "eyekiss";
        let displayColor = PlayerRoleUiStore.roleColor[role];
        if (displayColor !== "yellow" && displayColor)
            display = `${displayColor}_${display}`;
        let message = {
            data: [
                {
                    action: {
                        action_name: "greet_arm",
                        action_type: "overlap",
                        pitch: 0,
                        yaw: 0,
                    },
                    display: {
                        display_name: `${display}`,
                        delay: 0,
                        playtime: 1,
                        playspeed: 1,
                    },
                    speech: {
                        speech_name: "TTS_output",
                        // TTS: `나는 ${role}`,
                        TTS: `연결되었습니다`,
                        delay: 0,
                        repeat: 1,
                    },
                },
            ],
        };

        await MqttStore.publish(topic, message);
        runInAction(() => {
            this.connectTimeOutInterval = setInterval(() => {
                if (
                    this.connectTimeOut <= 0 ||
                    this.connectTimeOutInterval === null
                ) {
                    console.log(
                        "==========setInterval clearInterval=========="
                    );
                    clearInterval(this.connectTimeOutInterval);
                    this.setConnectTimeOutInterval(null);
                    // this.connectTimeOutInterval = null;
                } else {
                    this.setConnectTimeOut();
                }
            }, 1000);
        });
    },

    initConnectTimeOut() {
        console.log("==========initConnectTimeOut==========");
        clearInterval(this.connectTimeOutInterval);
        this.connectTimeOutInterval = null;
    },

    setConnectTimeOut(time) {
        if (time !== undefined) this.connectTimeOut = time;
        else this.connectTimeOut -= 1;

        console.log("timemout: ", this.connectTimeOut);

        if (this.connectTimeOut <= 0) this.initConnectTimeOut();
    },

    async likuAllStop() {
        for (let i of Object.keys(PlayerLikuUiStore.likuUuids)) {
            let topic = `liku/${PlayerLikuUiStore.likuUuids[i]?.uuid}/user/stop`;
            let message = "stop";
            await MqttStore.publish(topic, message);
        }

        if (this.thisConnectLiku) {
            let topic = `liku/${this.thisConnectLiku?.uuid}/user/stop`;
            let message = "stop";
            await MqttStore.publish(topic, message);
        }
    },

    checkLiku(serial) {
        if (serial in this.likuUuids) {
            return this.likuUuids[serial].connect;
        }
        return false;
    },

    getLanguageTopic(serial) {
        const topic = `liku/${serial}/language/get`;
        const message = { language: "data" };
        MqttStore.publish(topic, message);
    },

    setLanguageTopic(serial, value) {
        const topic = `liku/${serial}/language`;
        const message = { language: value };
        MqttStore.publish(topic, message);
    },

    getVolumeTopic(serial) {
        let topic = `liku/${serial}/volume/get`;
        let message = { volume: "get" };
        MqttStore.publish(topic, message);
    },

    async setVolumeTopic(serial, volume) {
        console.log("setVolumeTopic", serial);

        if (serial !== undefined) {
            let topic = `liku/${this.likuUuids[serial].uuid}/volume/set`;
            let message = { volume: volume };
            await MqttStore.publish(topic, message);
        }
    },

    getVolume(serial) {
        if (serial in this.likuUuids) {
            return this.likuUuids[serial].volume;
        }
        return 0;
    },

    setVolume(topic, volume) {
        let uuid = topic.split("/");
        let serial = this.getSerialByUuid(uuid[1]);
        if (Object.keys(this.likuUuids).length > 0) {
            this.likuUuids[serial].volume = volume?.volume;
        }
    },

    getSerialByUuid(value) {
        return Object.keys(this.likuUuids).find(
            (key) => this.likuUuids[key].uuid === value
        );
    },
});

export { PlayerLikuUiStore };
