import { ServerStore } from "../../store/domain/ServerStore";
import {
    getFetchJson,
    getTokenFetchJson,
    getTokenFetchText,
    postTokenFetchText,
} from "../../api/ServerApi";

const ContentsRepository = {
    async getContentsList() {
        let url = `${ServerStore.BASE_URL}/contents/musical`;
        // let url = `http://192.168.1.87:8080/contents/musical`;
        return await getTokenFetchJson(url);
    },

    async getContents(title) {
        return new Promise(async (resolve, reject) => {
            if (!ServerStore.BASE_URL) {
                const intervalId = setInterval(() => {
                    if (ServerStore.BASE_URL) {
                        clearInterval(intervalId); // BASE_URL이 설정되면 interval을 중단
                        resolve(ServerStore.BASE_URL); // Promise를 resolve하여 fetch 작업 진행
                    }
                }, 100); // 1초에 한 번씩 체크
            } else {
                resolve(ServerStore.BASE_URL);
            }
        })
            .then((BASE_URL) => {
                const url = `${BASE_URL}/contents/json?uuid=${title}`;
                return getTokenFetchText(url);
            })
            .then((response) => {
                // console.log("getContentsComp", response);
                return getFetchJson(response);
            });
    },

    async contentsStart(data) {
        let url = `${ServerStore.BASE_URL}/musical/start`;
        return await postTokenFetchText(url, data);
    },

    async contentsStop(data) {
        let url = `${ServerStore.BASE_URL}/musical/stop`;
        return await postTokenFetchText(url, data);
    },

    async getBackgroundFile(contentsUuid, uuid) {
        if (!uuid) return;
        let url = `${ServerStore.BASE_URL}/contents/background/${contentsUuid}/${uuid}`;

        // console.log(url);

        // return await getTokenFetchJson(url).then(async r => {
        //     return await getFetchJson(r);
        // });
        return await getTokenFetchJson(url);
    },
};

export default ContentsRepository;
